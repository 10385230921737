.theme-7 {
	.wb-hero {
		margin: 2rem;

		&:has(.has-api-integration) {
			height: auto;
			min-height: 600px;
		}

		.hero-container {
			position: relative;
			border-radius: 10px;
			// overflow: hidden;
      &::before {
        border-radius: 10px;
      }
		}
    .react-multi-carousel-list {
        border-radius: 10px;
				padding-bottom: 0; 
    }

		.hero-content-container {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 80%;
			height: 100%;
			z-index: 2;
			padding: 1rem;
			text-align: center;
      height: calc(100% - 120px);

			.hero-content {
				position: absolute;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.searchbar_container {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				bottom: 0;
				width: 100%;
				max-width: 900px;
				padding: 0.7rem;
				border-radius: 8px;
				background-color: #ffffff87;
				gap: 1rem;

				.form_select {
					position: relative;
					padding-inline-start: 45px;
					flex-grow: 1;
					background-color: #ffffff;
					border: 1px solid #ebebeb;
					border-radius: 8px;

					.icon {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						inset-inline-start: 8px;

						path {
							stroke: rgb(var(--web-page-primary-color));
						}
					}

					.control-field {
						border: 0;
					}
				}

				.cta_btn {
					position: relative;
					padding: 0.7rem 1.2rem;
					text-align: center;
					border: 1px solid rgb(var(--web-page-primary-color));
					box-shadow: none;
					outline: none;
					background-color: rgb(var(--web-page-primary-color));
					color: #ffffff;
					border-radius: 8px;

					.icon {
						position: relative;
						display: inline-block;
						margin-inline-end: 0.3rem;
						width: 22px;
						height: auto;
						z-index: 1;

						path {
							stroke: #ffffff;
						}
					}

					@include mixins.closeWindow;
				}
			}
		}

		.has-api-integration {
			align-items: center;
			justify-content: center;

			.hero-content-container {
				position: static;
				flex-direction: column;
				width: 100%;
				max-width: 800px;
				// margin-top: 100px;
				transform: unset;
				top: unset;
				left: unset;
				gap: 16px;
			}
			.hero-content {
				position: static;
				transform: unset;
			}

			.global-input {
				text-align: start !important;
			}
		}

		.react-multiple-carousel__arrow {
			top: 45%;
			right: auto;
			left: auto;
			background-color: transparent !important;
			border: 2px solid #ffffff;

			&::before {
				display: none;
			}

			&.react-multiple-carousel__arrow--right {
				inset-inline-end: 2rem;
			}

			&.react-multiple-carousel__arrow--left {
				inset-inline-start: 2rem;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.wb-hero {
      position: relative;
      margin: 20px 10px;
			.hero-content-container {
				flex-direction: column;
        width: 100%;
        // height: calc(100% - 120px);
        // background-color: #000;
        
				.hero-content {
					position: unset;
					transform: unset;
          padding-inline: 16px;
				}

				.searchbar_container {
					position: unset;
					flex-direction: column;
					transform: unset;
          margin-top: auto;


					.form_select {
						width: 100%;
					}

					.cta_btn {
						width: 100%;
					}
				}
			}
		}
	}
}
