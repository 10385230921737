.web-builder-page-contianer {
  .web-builder-hero {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000082;
      z-index: 0;
    }

    .hero-content {
      position: relative;
      z-index: 1;
    }

    h1 {
      font-size: 2rem;
      font-weight: 700;
      color: #fff;
    }

    .update-hero-layout {
      position: absolute;
      display: flex;
      background-color: white;
      width: max-content;
      border-radius: 4px;
      padding: 5px 8px;
      left: 15px;
      bottom: 15px;
      z-index: 2;

      label {
        margin: 0;
      }
    }
  }

  .web-builder-content {
    padding: 2em 0;

    .web-builder-content-about-us {
      padding: 0;
      border-radius: 6px;
      // background-color: #f5f8fc;
      // box-shadow: 0px 4px 12px 0px #00000014;
      overflow: hidden;

      .about-media-image {
        display: flex;
        justify-content: flex-end;
      }

      .about-us-title {
        font-size: 1.1rem;
        // line-height: 42px;
        // font-weight: 600;
        // color: rgba(var(--web-page-font-color));
      }

      .about-us-details {
        padding: 1.6rem 1rem;
      }

      .text-body {
        margin-top: 1rem;
        font-weight: 400 !important;
        line-height: 33.73px;
        white-space: pre-line
      }
    }

    // .all-web-builder-our-service-carousel {
    //   margin-top: 2rem;
    // }

    .section-title {
      text-transform: capitalize;
      font-size: 22px;
      line-height: 24px;
      color: rgba(var(--web-page-font-color));
    }

    .service-top-section {
      padding: 1rem;
    }
  }

  .web-builder-content-our-packages-with-image::before {
    content: none;
  }

  .hotels-grid-view {
    padding: 0 !important;
  }

  .all-web-builder-our-service-carousel {
    .service-slide {
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }

  .page-bread-crumb {
    text-align: center;
    color: #fff;

    a {
      color: #fff;
    }

    svg path {
      stroke: #fff;
    }
  }

  &.custom-page {
    --sections-block-padding: 16px;
  }
}