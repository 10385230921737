.theme-7 {
  .theme_footer_7 {
    padding: 2rem 3rem;
    position: relative;

    .footer_bg {
      z-index: 1;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }

    &::before {
      content: '';
      z-index: 2;
      position: absolute;
      top: 0;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      background-color: #000000a0;
    }

    .footer_content {
      position: relative;
      z-index: 3;
      color: #ffffff;

      .logo_container {
        text-align: center;

        img {
          width: 100%;
          max-height: 80px;
          max-width: 180px;
          object-fit: contain;
        }
      }

      .footer_text {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        max-width: 600px;
        margin-inline: auto;

        p {
          max-width: 600px;
          margin: 0 auto;
        }
      }

      .info_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 3rem;
        margin-block: 2rem;

        .social_media {
          display: flex;
          gap: 1rem;
          justify-content: flex-start;
          align-items: center;

          .text {
            font-size: 1.3rem;
            line-height: 1.5;
            font-weight: 600;
          }

          .links {
            display: flex;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: center;

            svg {
              position: relative;
              display: inline-block;
              transition: all 0.3s linear;

              // circle {
              //   fill: rgb(var(--web-page-primary-color));
              //   fill-opacity: 0.35;
              // }
              &:hover {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }
            }
          }
        }

        .contact_phone {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 0.75rem;

          .text,
          .phone {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.5;

            a {
              display: inline-block;
              color: #ffffff;
            }
          }
        }
      }

      .footer_links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 1.2rem;
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: 2rem;
        margin-bottom: 2rem;

        li {
          a {
            color: #ffffff;
            display: inline-block;
            transition: all 0.3 ease-in-out;

            &:hover {
              color: rgb(var(--web-page-primary-color), 0.6);
            }
          }
        }
      }

      .copyright_text {
        text-align: center;
        padding-top: 2rem;
        border-top: 1px solid #ffffff9e;
        max-width: 900px;
        margin-right: auto;
        margin-left: auto;

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: 991px) {
      padding: 2rem 1.5rem;

      .footer_content {
        .logo_container {
          img {
            max-width: 180px;
            max-height: 80px;
            object-fit: contain;
          }
        }

        .footer_text {
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

        .info_container {
          gap: 1.5rem;

          .social_media {
            flex-wrap: wrap;
            gap: 0.4rem;

            .text {
              font-size: 1rem;
            }

            .links {
              gap: 0.3rem;
            }
          }

          .contact_phone {
            gap: 0.5rem;
            flex-wrap: wrap;

            .text,
            .phone {
              font-size: 1rem;
            }
          }
        }

        .footer_links {
          gap: 0.9rem;
          margin-top: 1.5rem;
          margin-bottom: 1.5rem;
        }

        .copyright_text {
          padding-top: 1.5rem;
        }
      }
    }
  }

  .footer-container {
    background: transparent;
    padding: 0;
    display: block;
    color: #ffffff;
  }
}