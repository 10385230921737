.theme-10 {
  --ourservice-top-offset: -120px;
  &:has(.public-page-search) .wb-flights {
    padding: unset;
    background-color: transparent !important;
    background-image: unset !important;

    .flight-service-card {
      background-color: #fff;
      color: rgb(var(--web-page-primary-color));

      .header *,
      .content * {
        color: rgb(var(--web-page-primary-color));

        .port-country {
          color: rgb(var(--web-page-primary-color));
        }
      }
    }
  }

  // ****************************** header and hero ****************************** //
  .wb-hero {
    .hero-container {
      align-items: center;
      justify-content: center;
      &::before {
        background: rgba(var(--web-page-primary-color), 0.32);
        top: 0;
      }
    }
    .hero-bg  {
      position: absolute;
      top: 0;
      left: 0;
    }

    .search-form-web-builder {
      width: 100%;


    }

    .search-form-vertical {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
    }

    .hero-content-container {
      position: unset;
      padding: 24px !important;
      width: 100% !important;
      max-width: 700px;
      background: rgba(var(--web-page-primary-color), 0.24);
      backdrop-filter: blur(4px);
      border-radius: 4px;
      transform: unset;
    }
    &:has(.has-api-integration) {
      height: auto;
      min-height: 600px;
    }

    .has-api-integration {
      padding-top: 3.6rem;
      padding-bottom: calc(var(--ourservice-top-offset) * -1) ;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;
    gap: 42px;

    .main-nav {
      gap: 26px;
    }

    li {
      color: rgb(var(--web-page-primary-color)) !important;
    }
  }

  &.web-builder-preview .wb-header {
    padding: 16px 5em;
  }

  .web-builder-preview {
    .web-builder-hero {
      height: calc(65vh - 72px);
    }
  }


  .services-content {
    text-align: center;
    padding-bottom: 1.6rem;
  }

  // ****************************** our services section ****************************** //
  &.web-builder-preview .wb-our-service {
    top: var(--ourservice-top-offset);
    padding: 0 16px;
  }

  .wb-our-service {
    position: relative;
    background-color: transparent;
    z-index: 1;
    padding: 80px 16px;

    .service-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
      gap: 16px;
      padding: 24px 0;
    }

    .service-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      max-width: 200px;
      margin: 0 auto;
      text-align: center;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
      gap: 10px;

      .title {
        font-weight: 700;
        color: rgba(var(--web-page-primary-color));
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background-color: rgba(var(--web-page-primary-color), 0.08);
        border: 1px solid rgb(var(--web-page-primary-color));
        border-radius: 50%;

        svg {
          width: 50%;
          height: 50%;
        }

        svg path {
          stroke: rgb(var(--web-page-primary-color));
        }

        svg path {
          stroke: rgba(var(--web-page-primary-color));
        }
      }
    }
  }

  // ****************************** about us section ********************************** //
  #about-us-section {
    padding-top: 0;
    padding: 1rem;

    .web-builder-content-about-us {
      gap: 64px;

    }

    .about-media-container {
      position: relative;
      padding: 2rem;
      padding: 42px 42px 0 0;
      &:dir(ltr) {
        padding: 42px 0 0 42px;
        
      }



      &::before {
        content: "";
        background-image: url('../../../assets/images/webBuilder/theme10-dots-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        inset-inline-start: 0;
        width: 200px;
        height: 200px;
        z-index: 0;
        opacity: 0.32;
        top: 0;
      }
    }

    img {
      position: relative;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
      z-index: 1;
      left: 0;
    }
  }

  // ****************************** our pacakges section ****************************** //
  .wb-our-packages {
    background-color: rgba(var(--web-page-primary-color), 0.16);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 25px 0;
    background-blend-mode: luminosity;
    // height: 800px;

    .custom-container {
      display: grid;
      grid-template-columns: 1.5fr 3fr;
      align-items: center;
      justify-content: space-between;
      gap: 48px;
    }

    .services-content {
      margin: 0;
      max-width: 100%;
      text-align: start;
    }

    .service-top-section {
      padding: 0;
      flex-direction: column;

      .add-element-box {
        max-width: 100%;
      }

      .service-header,
      .service-body {
        color: rgb(var(--web-page-primary-color));
      }
    }

    .packages-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
      gap: 24px;
    }

    .package-card {
      position: relative;
      display: flex;
      flex-direction: column;
      height: auto !important;
      padding: 0;
      border-radius: 10px;
      border: 1px solid #E2E8F0;
      background-color: #ffffff;
      box-shadow: 0px 0px 14px 0px #0000000D;
      transition: border .2s ease-in-out;
      gap: 8px;
      overflow: hidden;

      .card-img {
        width: 100%;
        height: 170px;
        overflow: hidden;

        img {
          width: 100%;
          height: 250px;
          object-fit: cover;

        }
      }

      .card-content-top {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        // flex-wrap: wrap;
        padding: 0 20px;
        color: #707170;
        gap: 8px;
      }

      .card-details {
        display: flex;
        justify-content: space-between;
        color: #050001;
        gap: 16px;
      }

      .rate {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 30px;
        border: 1px solid #E2E8F0;
        background-color: #ffffff;
        line-height: 8px;
        z-index: 1;
        gap: 10px;

        svg path {
          stroke: #ff9f43 !important;
        }
      }

      .title {
        font-size: 22px;
        font-weight: 700;
        max-width: calc(100% - 100px);

        &:dir(rtl) {
          text-align: right;
        }
      }

      .location {
        color: #707170;
      }

      .hotel-name {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price {
        font-size: 26px;
        line-height: 22px;
        font-weight: 700;
        color: rgb(var(--web-page-primary-color));
        display: flex;
        align-items: baseline;

        .currency {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .card-content-bottom {
        margin: 0 20px 8px;
        padding-top: 8px;
        margin-top: auto;
        // border-top: 1px solid #000;
        color: #707170;
      }

      svg path {
        stroke: #707170;
      }
    }
  }

  // ****************************** hotels section ************************************ //
  .wb-hotels {
    padding-block: 80px;

    .hotels-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(252px, 1fr));
      gap: 20px;
    }

    .hotel-card {
      position: relative;
      width: 100%;
      height: auto !important;
      padding: 0;
      border-radius: 10px;
      background-color: #ffffff;
      border: 1px solid rgba(226, 232, 240, 1);
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;

      .card-img {
        width: 100%;
        height: 170px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transform: scale(1);
          transform-origin: center center;
          transition: all 0.5s linear;
          backface-visibility: visible;
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 16px;
        color: rgb(var(--web-page-primary-color));
        gap: 8px;
      }

      .location {
        padding-bottom: 10px;
        color: #707170;

        svg path {
          stroke: rgb(var(--web-page-primary-color));
        }
      }

      .price {
        display: flex;
        gap: 4px;
        align-items: baseline;
        padding-top: 10px;
        border-top: solid thin #e2e8f0;
        color: rgba(var(--web-page-primary-color));

        .amount {
          font-size: 24px;
          font-weight: 700;
        }

        .currency {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  // ****************************** tours section ************************************* //
  .wb-tours {
    .tours-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 16px), 1fr));
      gap: 16px;
    }

    .tour-card {
      padding: 24px;
      margin: 0;
      max-width: 100%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat;
      box-shadow: 0px 2px 16px 0px #00000014;
      border: none;
      border-radius: 8px;
      min-height: 350px;

      .card-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: #fbfbfa;
        z-index: 3;
        margin-top: auto;
        gap: 8px;

        .title {
          font-weight: 600;
          font-size: 28px;
        }

        .price {
          background-color: rgba(var(--web-page-primary-color));
          width: fit-content;
          padding: 7px 16px;
          border-radius: 32px;
          font-size: 16px;
          // line-height: 27px;
          display: flex;
          align-items: baseline;

          .amount {
            font-size: 26px;
            font-weight: 700;
            padding: 0px 6px;
          }

          .currency {
            font-size: 14px;
            font-weight: 500;
          }
        }

        svg path {
          stroke: #ffffff;
        }
      }
    }
  }

  // ****************************** flight section ************************************ //
  .wb-flights {
    padding-block: 100px;
    background-color: rgba(var(--web-page-primary-color), 0.24);
    background-image: url("../../../assets/images/webBuilder/theme10-flight-bg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    .service-top-section {
      padding-block: 0;
    }

    .services-content {

      .service-header,
      .service-body {
        color: #ffffff !important;
      }
    }

    .flights-cards-container {
      grid-template-columns: repeat(2, 1fr) !important;
      gap: 2rem;
    }

    .flight-service-card {
      display: flex;
      flex-direction: column;
      padding: 16px;
      text-align: center;
      color: #ffffff;
      border-radius: 8px;
      border: 1px solid #E5E5E5;
      backdrop-filter: blur(4px);
      background-color: rgba(var(--web-page-primary-color), 0.64);
      gap: 18px;

      .content {
        height: 100%;
        .destination-price {
          margin-top: auto;
        }
      }

      @media screen and (max-width: 767px) {
        .content div:nth-child(2) {
          flex-wrap: wrap;
        }

        .header {
          .destination-count-text {
            display: none;
          }
        }

      }

      .header {
        padding: 0;
        border: 0;
        padding-bottom: 16px;
        border-bottom: 1px solid #e5e5e5;
        background-color: transparent;
        color: #ffffff;
        flex-wrap: nowrap !important;
        gap: 16px;

        h3 {
          font-weight: 700;
          font-size: 20px;
          color: #ffffff;

          text-overflow: initial !important;
          white-space: initial !important;
          overflow: initial !important;
          text-align: left !important;

          @media screen and (max-width: 767px) {
            &:dir(rtl) {
              text-align: center !important;
            }
          }
        }
      }

      .cta-btn {
        padding: 8px 24px;
        color: #fff !important;
        background-color: rgba(var(--web-page-primary-color));

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .destination-count {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px 24px;
        color: rgba(var(--web-page-primary-color));
        background-color: #ffffff;
        border: 1px solid rgba(var(--web-page-primary-color), 0.16);
        gap: 6px;

        &:hover .addition-destinations-list {
          opacity: 1;
          visibility: visible;
        }
      }

      .destination-port {
        display: flex;
        justify-content: space-evenly;
        color: #ffffff;
        gap: 24px !important;

        .font-weight-bold {
          align-self: start;
        }

        .port-country {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          display: block;
          width: 60px;
          margin-inline: auto;
        }

        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          background-color: #E1EDF5;
          border-radius: 50%;

          svg {
            width: 75% !important;
            height: 75%;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      .addition-destinations-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 150%;
        top: 38px;
        inset-inline-end: 0;
        padding: 16px;
        border: 1px solid #dddcdc;
        border-radius: 8px;
        background-color: #ffffff;
        gap: 12px;
        opacity: 0;
        visibility: hidden;
        z-index: 3;
        width: fit-content;

        svg path {
          stroke: rgba(var(--web-page-primary-color));
        }

        .destination-port {
          gap: 16px !important;
          justify-content: space-between;
          align-items: center;
          color: rgba(var(--web-page-primary-color));
          
          .font-weight-bold {
            width: 90px;
            align-self: flex-start;
          }
          
          &:dir(rtl) {
            flex-direction: row-reverse;
          }

          .port-country {
            color: #000000;
          }
        }
      }

      .price {
        color: #ffffff;

        @media screen and (max-width: 767px) {
          padding: 0 !important;
          margin: 0 !important;
          width: 100%;
        }

        .amount {
          @media screen and (max-width: 767px) {
            margin-inline-start: auto !important;
            display: block;
          }
        }
      }
    }

  }

  .flight-cards-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media screen and (min-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  // ****************************** transfer section ************************************ //
  .home-page-transfer-section {
    padding-block: var(--sections-block-padding);

    &__title {
      color: rgba(10, 51, 82, 1);
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2;
      margin-bottom: 0.4rem;
    }

    &__description {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: rgba(112, 113, 112, 1);
      text-align: center;
    }

    &__cards-list {
     display: flex;
     flex-wrap: wrap;
     gap: 1rem;
    }

    .home-page-transfer-card {
      max-width: 300px;
      
      @media screen and (max-width: 768px) {
        width: 100%;        
      }

      &__image {
        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          width: 100%;
          aspect-ratio: 3/2;
          object-fit: cover;
          height: 200px;
        }
      }

      &__content {
        border: 1px solid rgba(226, 232, 240, 1);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 1rem;
      }

      &__details {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
      }

      &__tag {
        padding: 0.25rem 1.7rem;
        background-color: rgba(225, 237, 245, 1);
        color: rgba(10, 51, 82, 1);
        width: fit-content;
        border-radius: 90px;
        text-transform: capitalize;
      }

      &__title {
        font-size: 1.25rem;
        font-weight: 700;
        color: black;
      }

      &__body {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        p {
          font-weight: 500;
          color: rgba(112, 113, 112, 1);
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        svg {
          --size: 21px;
          width: var(--size);
          height: var(--size);

          path {
            stroke: rgba(112, 113, 112, 1);
          }
        }
      }

      &__price {
        display: flex;
        gap: 0.2rem;
        align-items: baseline;
      }

      &__price-label {
        color: black;
        font-size: 1rem;
      }
      
      &__price-currency {
        color: rgba(10, 51, 82, 1);
        font-size: 1.142rem;
        text-transform: uppercase;
      }
      
      &__price-value {
        color: rgba(10, 51, 82, 1);
        font-size: 1.5rem;
        font-weight: 700;
      }
    }

    @media screen and (max-width: 767px) {
      &__cards-list {
        & > a {
          width: 100%
        }
      }

      .home-page-transfer-card {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  // ****************************** other section *************************************** //
  .web-builder-content-other {
    .services-content {
      max-width: 50% !important;
      text-align: center !important;
      margin-inline: auto !important;
    }

    .otherservice-cards-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      > a {
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .home-page-other-card {
      max-width: 350px;
      width: 350px;

      @media screen and (max-width: 768px) {
        width: 100%;        
      }

      filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.05));
      background-color: white;
      display: flex;
      flex-direction: column;
      height: 100%;

      &__image {
        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          width: 100%;
          aspect-ratio: 4/3;
          object-fit: cover;
          max-height: 250px;
          height: 200px;
        }
      }

      &__content {
        border: 1px solid rgba(226, 232, 240, 1);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        display: flex;
        flex-direction: column;
        padding: 1rem;
        height: 100%;
      }

      &__divider {
        width: 100%;
        background-color: rgba(226, 232, 240, 1);
        margin-block: .5rem;
      }

      &__title {
        font-size: 1.125rem;
        color: black;
        font-weight: 700;
      }

      &__description {
        font-size: 1rem;
        color: rgba(112, 113, 112, 1);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__footer {
        margin-top: auto;
        display: flex;
      }

      &__cta {
        margin-top: 0.5rem;
        margin-inline-start: auto;
        button {
          padding: 0.7rem 1.2rem;
          font-weight: bold;
          height: 100%;
          background-color: rgba(0, 183, 172, 1) !important;
          color: white !important;
          font-size: 1rem;
          border: none;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          display: grid;
          place-content: center;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .home-page-other-card {
        max-width: 100%;
        width: 100%;
      }
      
    }
  }

  // ****************************** faq section *************************************** //
  .wb-faqs {
    padding-block: 80px;

    .faq-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      gap: 100px;
    }

    .faq-questions .h4,
    .faq-questions .body {
      color: rgb(var(--web-page-primary-color));
    }

    .faq-questions .body {
      font-weight: 500;
    }

    .faqs-question-wrapper {
      padding: 16px;
      margin-bottom: 20px;
      border-radius: 16px;
      border: 1px solid rgb(var(--web-page-primary-color));
      text-align: center;

      .faqs-container-question h5 {
        font-size: 20px;
        font-weight: 700;
        color: rgba(var(--web-page-primary-color));
        margin: 0 auto;
      }

      .faqs-container-body {
        margin-top: 16px;
        font-weight: 400;
        color: #363636B2;
      }
    }


    .faq-image {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 360px;
      border-radius: 6px;

      .abstract-bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: -40px;
        inset-inline-start: 0;
        width: 200px;
        height: 200px;
        z-index: 0;
        opacity: 0.32;

       
      }

      img {
        position: relative;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
        z-index: 1;
        --offset: 46px;
        width: calc(100% - var(--offset));
        inset-inline-start: var(--offset);

        @media screen and (min-width: 767px) {
          --offset: 46px;          
        }
      }
    }

    .faq-btn {
      border: none !important;
      background-color: transparent !important;
    }

  }

  // ****************************** contact us section ******************************** //
  .wb-contact-us {
    --image-width: 280px;
    --factor: 0.5;
    --form-image-gap: 70px;
    --form-padding: 24px;

    padding-bottom: 0;
    @media screen and (min-width: 767px) {
      --factor: 0.6;
      padding-bottom: 70px;
      padding-inline: 24px;
      .contact-container {
        display: flex;
        justify-content: center;
        padding-left: calc(var(--image-width) * var(--factor));
      }

      .contact-form-holder {
        max-width: 650px !important;
        padding: var(--form-padding) 0 !important;
        box-shadow: 0px 2px 12px 0px #00000014;
        margin-inline: 0;
        .contact-us-form {
          margin: 0 0 0 calc(var(--image-width) * (1 - var(--factor)) - var(--form-padding) + var(--form-image-gap));
        }

        .contact-us-image {
          position: absolute;
          left: 0%;
          top: 50%;
          transform: translate(-60%, -50%);
          height: calc(100% - 48px);
          max-width: var(--image-width);
        }
    }

    @media screen and (min-width: 1200px) {
      --factor: 0.5;
      .contact-form-holder {
        max-width: 800px;
      }
    }
  }

    .contact-form-holder {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0;
      width: 100%;
      max-width: 400px;
      background-color: #F9F9F9;
      margin-inline: auto;
      column-gap: 78px;
    }

    .t-title {
      font-weight: 700;
      color: rgb(var(--web-page-primary-color));
    }

    .t-body {
      color: #707170;
      margin-bottom: 1rem;
    }

    .contact-us-form {
      padding: var(--form-padding);
      border-radius: 24px;
      width: -webkit-fill-available;

      .input-group.control-field, .textarea-field {
        box-shadow: 0px 5px 14px 0px #0000000f;
        border-radius: 8px;
      }
    

      .textarea-field {
        background-color: #ffffff;
        border: 1px solid #ebebeb;
        font-size: 13px;
        width: 100%;
        padding: 10px;

        &::placeholder {
          color: #dddcdc !important;
          font-weight: 600;
        }

        svg path {
          stroke: rgba(var(--web-page-primary-color));
        }
      }

      .submit-btn {
        padding: 14px 0;
        font-weight: 700;
        font-size: 16px;
        width: 100%;
        background-color: rgba(var(--web-page-primary-color));
        color: #ffffff;
        border-radius: 8px;
      }
    }

    .contact-us-image {
      position: relative;
      bottom: 0;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      label {
        height: 100%;
        margin-bottom: 0;
      }

    }
  }

  // ****************************** footer section ************************************ //
  .footer-container {
    justify-content: flex-start;
    background: rgba(var(--web-page-primary-color));

    .footer-content {
      display: flex;
      align-items: flex-start;
      // justify-content: space-between;
      gap: 120px;
    }

    .footer-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 24px;
      font-family: Cairo;
      letter-spacing: 0.02em;
      line-height: 44.98px;
    }

    .footer-main-data {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      gap: 20px;
    }

    .logo-container img {
      // width: 200px;
      height: auto;
      max-height: 60px;
    }

    .social-media {
      display: flex;
      gap: 1rem;
      justify-content: flex-start;
      align-items: center;

      .text {
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 600;
      }

      .links {
        display: flex;
        gap: 0.5rem;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .links-list {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-inline-start: auto;
      gap: 20px;

      .text-secondary-color {
        color: #ffffff !important;
      }

      li {
        @media screen and (max-width: 440px) {
          font-size: 14px;
        }
      }
    }

    .footer-copy-right {
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid #E8E8E8;

      @media screen and (max-width: 440px) {
        font-size: 12px;
      }
    }

    .footer-contact-info {
      display: flex;
      flex-direction: column;
      margin-inline-start: auto;
      gap: 20px;

      svg {
        width: 26px;
        height: 26px;

        path {
          stroke: #ffffff;
        }
      }
    }
  }

  // generic  class in theme 10
  .package-hotel-card {
    position: relative;
    padding: 0;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(226, 232, 240, 1);
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
    height: auto !important;
    overflow: hidden;

    .card-img {
      width: 100%;
      height: 170px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transform-origin: center center;
        transition: all 0.5s linear;
        backface-visibility: visible;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      color: rgb(var(--web-page-primary-color));
      padding: 10px 16px;
      gap: 8px;
    }

    .location {
      padding-bottom: 10px;
      color: #707170;

      svg path {
        stroke: rgb(var(--web-page-primary-color));
      }
    }

    .price {
      display: flex;
      gap: 4px;
      align-items: baseline;
      padding-top: 10px;
      border-top: solid thin #e2e8f0;
      color: rgba(var(--web-page-primary-color));

      .amount {
        font-size: 24px;
        font-weight: 700;
      }

      .currency {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .title {
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .empty-service::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(112, 113, 112, 0.7) !important;
    z-index: 2;
  }

  .add-service-btn {
    top: 50%;
    z-index: 3;
  }

  .services-content {
    max-width: 100% !important;

    .service-header {
      font-size: 28px;
      font-weight: 700;
      color: rgb(var(--web-page-primary-color));
    }

    .service-body {
      font-size: 16px;
      font-weight: 500;
      color: #707170;
    }
  }

  @media screen and (max-width: 767px) {
    &.web-builder-preview .wb-header {
      padding: 8px;
    }

    .custom-page,
    .about-page {
      .web-builder-hero {
        height: 200px !important;
      }
    }

    .search-form-select {
      // width: 100% !important;
      min-width: auto;
    }

    .wb-our-service {
      padding: 40px 0 !important;

      .service-cards-container {
        display: flex;
        overflow-x: auto;
      }

      .service-card {
        min-width: 220px;
      }
    }

    .wb-our-packages .custom-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
    }

    .wb-flights .flights-cards-container {
      grid-template-columns: 1fr !important;
    }

    .wb-tours {
      .tours-cards-container {
        display: flex;
        flex-direction: column;
      }

      .tour-card {
        max-width: 100%;
      }
    }

    .wb-faqs {
      padding-block: 40px;

      .faq-container {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .footer-container {
      padding-inline: 0;

      .footer-content {
        flex-direction: column;
        gap: 2rem;
      }

      .links-list {
        flex-direction: row;
        gap: 12px;
        justify-content: center;
        flex-wrap: wrap;
        margin-inline-start: unset;
      }

      .footer-contact-info,
      .footer-title {
        display: none;
      }
    }
  }

  &.web-builder-page-contianer .services-content {
    display: none;
  }
  
  @media screen and (max-width: 767px) {
    .public-page-search  {
      .flight-service-card {
        .header {
          .destination-count-text {
            display: none;
          }
        }

        .destination-port {
          justify-content: space-between;
          
          .font-weight-bold {
            align-self: flex-start;
          }
        }

        .addition-destinations-list {
          width: fit-content !important;
          right: 0 !important;
          left: unset !important;
        }

        .port-country {
          width: 60px;
          display: block;
          margin-inline: auto;
        }

        h3 {
          max-width: 200px !important;
        }
      }
    }
  }

  .public-page-search  {
    .flight-service-card {
      .header {
        flex-wrap: nowrap !important;
      }

      .destination-port {
        .font-weight-bold {
          align-self: self-start;
        }
      }

      .addition-destinations-list{
        .destination-port {
          &:dir(rtl) {
            flex-direction: row-reverse;
  
            .arrow {
              transform: scale(1) ;
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        .amount {
          margin: 0 !important;
          margin-inline-start: auto !important;
        }
      }

      h3 {
        text-overflow: initial !important;
        white-space: initial !important;
        overflow: initial !important;
        text-align: left !important;
      }
    }
  }
}
