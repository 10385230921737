.umrah-operations-wrapper {
	.reset-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px 16px;
		margin-top: 20px;
		border-radius: 6px;
		border: 1px solid #eed9ba;
		background-color: #faf4eb;
		color: #6a4d1b;
		font-size: 18px;
		font-weight: 600;
		line-height: 16px;
	}
	.outline-btn {
		padding: 6px 12px;
		border-radius: 4px;
		border: 1px solid #d4a655;
		background-color: #fff;
		color: #d4a655;
		font-size: 16px;
		font-weight: 700;
		&:focus {
			box-shadow: 0 0 0 0.15rem rgba(212, 166, 85, 0.25);
		}
		svg {
			transform: scale(1, 1);
		}
	}

	.reset-btn {
		align-self: flex-start;
		margin-top: 27px;
		padding: 9px 16px;
		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(250, 244, 235, 0.8);
		}
	}
	.search-btn {
		align-self: flex-start;
		margin-top: 28px;
		padding: 8px 16px;
		&:focus {
			box-shadow: 0 0 0 0.15rem rgba(212, 166, 85, 0.25);
		}
	}
}
.umrah-operation-breadcrump {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
	color: #707170;
	padding: 24px 0;
	.link {
		color: #d4a655;
	}
}
.export-excel-btn {
	display: flex;
	align-items: center;
	padding: 6px 20px;
	align-items: center;
	border-radius: 4px;
	border: 1.5px solid #219653;
	background-color: #fff;
	color: #219653;
	font-size: 16px;
	font-weight: 700;
	&:hover {
		background-color: #219653;
		color: #fff;
		svg path {
			stroke: #fff;
		}
	}
}

.operations-agents {
	.table-container {
		border: 1px solid#DBDADE;
		border-radius: 6px;
		margin-top: 16px;
		overflow: hidden;

		.table thead {
			background-color: #f7f6f6;
		}

		.table th {
			border-top: 0;
			padding: 16px 14px;
			text-transform: uppercase;
			color: #707170;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 1px;
			text-transform: uppercase;
		}
		.agent-name {
			color: #050001;
			font-size: 16px;
			font-weight: 600;
		}
	}

	.agents-filter {
		.control-field-inner {
			height: 45px !important;
			align-items: center;
		}
	}
	.add-edit-agent {
		.add-edit-form {
			display: flex;
			flex-direction: column;
			gap: 24px;
			padding: 32px 26px;
			border-radius: 8px;
			border: 1px solid#F7F6F6;

			.control-field__label {
				color: #707170;
				font-size: 14px;
			}
			::placeholder {
				color: #dddcdc;
			}
		}

		.back-btn {
			display: flex;
			align-items: center;
			padding: 6px 12px;
			border-radius: 4px;
			background: rgba(203, 203, 203, 0.38);
			color: #707170;
			font-size: 16px;
			font-weight: 700;
		}
		.view-agent {
			color: #050001;
			.type {
				color: #707170;
			}
		}
	}
	.pagination .active .page-link {
		color: #fff;
	}
}

.operation-statement-container {
	.operation-statement-filter {
		display: flex;
		padding: 24px 26px;
		align-items: center;
		gap: 20px;
		border-radius: 8px;
		border: 1px solid#F7F6F6;
		background-color: #fafafa;
		gap: 12px;

		.row {
			row-gap: 16px;
		}
		.control-field__label {
			font-size: 14px;
			color: #707170;
		}

		.control-field__select [class$="-ValueContainer"] {
			max-height: initial !important;
		}
		.control-field__select div[class$="-control"] {
			max-height: initial !important;
		}

		.multi-select {
			.control-field {
				min-height: 42px;
				height: auto !important;
				padding: 3.5px 6px !important;
			}
		}
		.input-group {
			flex-wrap: nowrap;
		}
		.dropdown-container {
			border-color: #ebebeb;
			padding: 2px 3px !important;
			&:focus-within {
				box-shadow: none;
				border-color: #cb9a51;
			}
		}

		.reset-btn {
			align-self: flex-start;
			margin-top: 27px;
			padding: 9px 16px;
		}
		.search-btn {
			align-self: flex-start;
			margin-top: 28px;
			padding: 8px 16px;
		}
	}
	.operation-statement-list {
		.status-filter {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			li {
				display: flex;
				padding: 24px 16px;
				align-items: center;
				border-bottom: 1px solid#DDDCDC;
				font-size: 18px;
				font-weight: 600;
				color: #707170;
				border-bottom: 2px solid transparent;
				cursor: pointer;

				.badge {
					padding: 4px 8px;
					border-radius: 14px;
					background-color: #dddcdc;
					font-size: 14px;
					color: #707170;
					&.new {
						background-color: #ea5455;
						color: #fff;
					}
				}

				&.active {
					color: #d4a655;
					border-bottom: 2px solid#D4A655;
					cursor: default;
				}
			}
		}

		.table {
			display: flex;
			flex-direction: column;
			gap: 12px;
			margin-top: 12px;

			.table-row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				padding: 10px 12px;
				border: 1px solid #f7f6f6;
				border-radius: 8px;
				background-color: #fff;
				font-size: 14px;
				font-weight: 400;
				gap: 10px;
				transition: background-color 0.5s ease;

				&:hover {
					background-color: #fbf6ee;
					transition: background-color 0.5s ease;
				}

				.status {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #cbcbcb;
					margin-inline-end: 12px;
					&.approved {
						background-color: #219653;
					}
					&.rejected {
						background-color: #ea5455;
					}
				}
				.statement-info {
					display: flex;
					flex-direction: column;
					width: 120px;

					.elements {
						position: relative;
						display: flex;
						align-items: center;

						.ele-icon {
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 32px;
							height: 32px;
							// padding: 5px 1px;
							aspect-ratio: 1;
							border-radius: 50%;
							border: 1px solid #fff;
							background-color: #f2f2f5;
							svg {
								width: 16px;
								height: 16px;
							}
							margin-right: 3px;
						}

						.ele-icon.active {
							background-color: #d4a655 !important;
						}
						.count {
							background-color: #babdc1;
							z-index: 1;
						}
						.hidden-elements {
							position: absolute;
							padding: 5px;
							width: 180px;
							top: 34px;
							z-index: 5;
							background-color: #fff;
							border: 1px solid rgba(25, 59, 103, 0.05);
							border-radius: 4px;
							opacity: 0;
							visibility: hidden;
							transform: translateY(-10px);
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
							font-size: 16px;
							box-shadow: 0px 2px 6px -1px rgba(28, 55, 90, 0.12),
								0px 8px 24px -4px rgba(28, 50, 79, 0.19);
						}

						&:hover .hidden-elements {
							transform: translateY(0);
							opacity: 1;
							visibility: visible;
							transition: opacity 0.5s ease, visibility 0.5s ease,
								transform 0.5s ease;
						}
					}
				}
				.agent-info {
					display: flex;
					flex-direction: column;
					font-size: 12px;
					font-weight: 500;
					color: #778294;
					width: 120px;

					.name {
						font-size: 14px;
						color: #050001;
						font-weight: 600;
					}

					.country {
						color: #707170;
						font-size: 13px;
						font-weight: 400;
					}
				}
				.statement-category-card {
					width: 200px;
				}

				.actions-list {
					position: absolute;
					display: flex;
					flex-direction: column;
					width: 300px;
					padding: 12px 0px;
					top: 30px;
					inset-inline-end: 0;
					border-radius: 8px;
					border: 1px solid #e6e5e5;
					background: #fff;
					box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
					z-index: 3;
					display: none;
					max-height: 20rem;
					overflow-y: auto;
					// opacity: 0;
					// visibility: hidden;
					// transform: translateY(-10px);
					// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;

					&.show {
						display: flex;
						// transform: translateY(0);
						// opacity: 1;
						// visibility: visible;
						// transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
					}

					.item {
						padding: 4px 16px;
						font-size: 16px;
						font-weight: 400;
						color: #050001;
						&.stroke svg path {
							stroke: #292d32;
						}
					}

					h5 {
						padding: 8px 16px;
						font-size: 12px;
						color: #777;
						font-weight: 500;
						text-transform: uppercase;
					}
					hr {
						margin: 8px 0;
					}
				}

				.action-label {
					font-size: 12px;
					font-weight: 500;
					color: #778294;
					margin-bottom: 16px;
				}
				.actions-btn {
					width: 200px;
				}
				.edit-draft-btn {
					display: flex;
					align-items: center;
					padding: 6px 10px;
					border-radius: 6px;
					border: 1px solid #9c9fab;
					background-color: #fff;
					color: #9c9fab;
					font-weight: 600;
					font-size: 18px;
				}
			}
		}
	}
	.pagination .active .page-link {
		color: #fff;
	}
	.pagination .page-link {
		padding: 10px 14px;
	}
}

.operation-statement-numbers-model {
	.modal-header {
		padding-block: 20px !important;
	}
	.modal-title {
		width: 100%;
		text-align: center;
	}
	.modal-body {
		padding-inline: 0 !important;
	}
	.modal-content {
		padding: 20px;
	}
	.row {
		margin-inline: 0 !important;
	}
	.control-field__prepend {
		border-inline-end: none !important;
		span {
			background-color: transparent !important;
		}
		.form-control {
			padding: 0.6rem 0.5rem !important;
		}
	}
	.flex-prepend {
		padding: 12px 5px;
	}
	.control-field__label,
	label {
		color: #707170;
		font-size: 14px;
		font-weight: 400;
	}
	.or {
		width: 38%;
		text-align: center;
		border-bottom: 1px solid #a9a9a9;
		line-height: 0.1em;
		margin: 39px 0;
		vertical-align: middle;

		span {
			background: #fff;
			padding: 0 3px;
			vertical-align: middle;
			color: #707170;
			margin-bottom: 3px;
			display: inline-block;
		}
	}

	.company_phone_code {
		.control-field__label {
			width: 130px;
		}
	}
}
.send-company-model {
	.modal-content {
		padding: 0 20px !important ;
	}
	.modal-header {
		padding-top: 35px !important ;
		padding-bottom: 20px !important;
	}
	.modal-body {
		padding-bottom: 0 !important;
	}
}
.operation-statement-view {
	background-color: #fff;

	.operation-statement-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 16px 24px;
		gap: 18px;
		border: 1px solid #f4f4f7;

		// .category-item {
		// 	width: calc(20% - 18px);
		// }
	}
	.visa-item {
		padding: 16px;
		background-color: #f7f6f6;
		margin-top: 16px;
		.control-field {
			height: 49px;
			align-items: center;
		}
		.input-container {
			margin-top: 0;
		}
		.label-pax {
			display: block;
			margin-bottom: 4px;
		}
	}
	.control-field__label {
		color: #707170;
		font-size: 14px;
	}
	.control-field__label--danger {
		color: #ff5f59;
	}

	.item-row {
		display: flex;
		flex-direction: column;
		gap: 8px;
		border-radius: 8px;
		border: 1px solid#DDDCDC;

		.header {
			padding: 16px;
			border-radius: 8px 8px 0px 0px;
			background-color: #f7f6f6;
			font-size: 18px;
			font-weight: 400;
			color: #707170;
		}
		.label {
			width: 100%;
			padding: 8px 10px;
			text-align: center;
			border-radius: 4px;
			background-color: #dddcdc;
			color: #050001;
			font-size: 18px;
			font-weight: 600;
		}
	}
	.close-btn {
		padding: 10px 20px;
		border-radius: 4px;
		background-color: rgba(203, 203, 203, 0.38);
		color: #707170;
		font-weight: 700;
		font-size: 16px;
	}

	.save-btn {
		display: flex;
		padding: 10px 30px;
		justify-content: center;
		align-items: center;
		gap: 6px;
		border-radius: 8px;
		background-color: #d4a655;
		color: #fefeff;
		font-weight: 700;
		font-size: 1rem;
	}

	.reject-btn {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-radius: 8px;
		background-color: #ea5455;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		border: none;
		[dir="rtl"] svg {
			transform: scale(1, 1) !important;
		}
	}
	.accept-btn {
		display: flex;
		align-items: center;
		padding: 10px 16px;
		border-radius: 8px;
		background-color: #219653;
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		border: none;
		[dir="rtl"] svg {
			transform: scale(1, 1) !important;
		}
	}
}

.bg-gray-50 {
	background-color: #f7f6f6 !important;
}
.gap-24 {
	gap: 24px;
}
.gap-10 {
	gap: 10px;
}
.col-md-4:has(> .pax-input),
.col-md-2:has(> .pax-input) {
	min-width: 195px;
}
.pax-input {
	color: #707170;
	font-size: 14px;
	font-weight: 400;
	min-width: 185px;
	&.hasError {
		.input-container {
			border: 1px solid #ff5f59;
		}
		.label-pax {
			color: #ff5f59;
		}
	}
	.input-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		padding: 10px 6px;
		margin-top: 7px;
		gap: 6px;
		flex: 1 0 0;
		border-radius: 6px;
		border: 1px solid #dbdade;
		background-color: #fff;

		.pax-type {
			display: flex;
			align-items: center;
			color: #050001;
		}
		svg {
			width: 20px;
			height: 20px;
		}
		.btn {
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba(112, 113, 112, 0.15);
			}
			&:disabled {
				background: transparent !important;
				svg path {
					stroke: #dcdcdc;
				}
			}
		}

		.action-btns {
			display: flex;
			align-items: center;
		}

		.number-input {
			border: 0;
			width: 25px;
			text-align: center;
		}
	}
}

.statement-category-card {
	display: flex;
	align-items: center;
	gap: 10px;
	min-width: 200px;

	.category-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 36px;
		height: 36px;
		padding: 4px;
		border-radius: 100%;

		border: 1px solid;
	}
	.info-container {
		display: flex;
		flex-direction: column;

		.category-type {
			font-size: 12px;
			font-weight: 500;
			color: #778294;
		}

		.category-service-name {
			font-size: 14px;
			color: #050001;
			font-weight: 600;
			line-height: 22px;
		}
		.category-sub-data {
			font-size: 13px;
			font-weight: 400;
			line-height: 20px;
			color: #707170;
		}
	}
}

.operation-tabs {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 18px;
	color: #707170;
	// overflow-x: auto;
	// overflow-y: visible;
	border-bottom: 1px solid #dddcdc;
	padding: 0;

	.item {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px 0;
		font-size: 20px;
		font-weight: 500;
		line-height: 100%;
		white-space: pre;
		// border-bottom: 2px solid transparent;
		cursor: pointer;

		&:hover .sub-tabs {
			display: flex;
		}

		&.active {
			border-bottom: 2px solid #d2ab67;
			color: #d2ab67;
			cursor: default;

			svg path {
				stroke: #d2ab67;
			}
		}
	}
	.sub-tabs {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 8px;
		top: calc(100% + 2px);
		z-index: 2;
		min-width: 200px;
		left: 0;
		right: 0;
		border-radius: 8px;
		border: 1px solid #e6e5e5;
		background-color: #fff;
		box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
		display: none;

		&.opened {
			display: flex;
		}

		.sub-tab-item {
			padding: 8px 0;
			color: #050001;
			font-size: 16px;
			font-weight: 400;
			cursor: pointer;

			svg path {
				stroke: #292d32;
			}

			&.active {
				border-bottom: 0;
				color: #d2ab67;
				cursor: default;

				svg path {
					stroke: #d2ab67;
				}
			}
		}
	}
}

.operations-center-container {
	.wrapper {
		padding: 0 16px 16px;
		border-radius: 12px;
		border: 1px solid #f7f6f6;
		background-color: #fff;
		.page-link {
			padding: 0.5rem 0.8rem;
		}
		.active .page-link {
			color: #fff;
		}
	}
	.filter-container {
		display: flex;
		padding: 18px 24px;
		flex-direction: column;
		gap: 20px;
		border-radius: 8px;
		border: 1px solid #f7f6f6;
		background-color: #fafafa;

		.filter-collapse-btn {
			display: flex;
			align-items: center;
			width: fit-content;
			font-size: 18px;
			font-weight: 700;
			line-height: 24px;
			color: #050001;
			gap: 3px;
			&:focus {
				box-shadow: none;
			}
		}
		.control-field__label {
			font-size: 14px;
			font-weight: 400;
			color: #707170;
		}
		.inputs-container {
			row-gap: 16px;
		}
	}

	.types-fitler {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		li {
			padding: 5px 16px;
			border-radius: 40px;
			border: 1px solid #dddcdc;
			background-color: #fefeff;
			color: #707170;
			font-size: 18px;
			font-weight: 600;
			cursor: pointer;
		}
		li.active {
			border: 1px solid#D4A655;
			background-color: #faf4eb;
			color: #d4a655;
		}
	}

	.city-type-input {
		width: 200px;
		.control-field {
			display: flex;
			align-items: center;
			padding: 7px 14px !important;
			border-radius: 52px;
			background-color: #faf4eb;
			border-color: #dbdade;

			div[class$="placeholder"],
			div[class$="singleValue"] {
				color: #050001;
				font-size: 15px;
				font-weight: 600;
				line-height: 24px;
			}
		}
	}

	.operation-center-table {
		overflow-x: auto !important;
		border-radius: 10px;
		border: 1px solid #dbdade;
		height: 600px;

		.table {
			margin: 0;
			width: max-content;
			min-width: 100%;
			height: 100%;
		}

		.table thead {
			background-color: #f7f6f6;
			position: sticky;
			top: 0;
		}
		.table tbody tr:hover,
		.table tbody tr:nth-of-type(odd) {
			background-color: transparent !important;
		}

		.table thead th {
			padding: 16px 10px;
			color: #707170;
			font-size: 14px;
			font-weight: 700;
			line-height: 16px;
			letter-spacing: 1px;
			text-transform: uppercase;
			border: 0;
			vertical-align: middle;
			position: sticky;
			top: 0;
		}

		.table tbody td {
			vertical-align: middle;
			font-size: 14px;
			color: #241f20;
			background-color: #fff;

			.indecator {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				flex: 0 0 10px;
			}

			&.arrival {
				background-color: rgba(142, 198, 0, 0.1);
				.indecator {
					background-color: #8ec600;
				}
			}
			&.internal_movement {
				background-color: rgba(225, 64, 132, 0.1);
				.indecator {
					background-color: #e14084;
				}
			}
			&.attraction {
				background-color: rgba(179, 109, 60, 0.1);
				.indecator {
					background-color: #b36d3c;
				}
			}
			&.departure {
				background-color: rgba(41, 105, 255, 0.1);
				.indecator {
					background-color: #2969ff;
				}
			}
			.approved {
				background-color: #219653;
			}
			.draft {
				background-color: #9c9fab;
			}

			&.from-color {
				background-color: rgba(33, 150, 83, 0.1);
			}
			&.to-color {
				background-color: rgba(234, 84, 85, 0.1);
			}
			.add-item-btn {
				display: flex;
				justify-content: center;
				align-items: baseline;
				padding: 2px 8px;
				margin-top: 8px;
				border-radius: 6px;
				border: 1px solid #d4a655;
				color: #d4a655;
				font-weight: 600;
				font-size: 14px;
				gap: 5px;
			}
			.show-more-drivers-btn {
				width: fit-content;
				margin: 0 auto;
				text-align: center;
				padding: 2px 8px;
				color: #1e85ff;
				font-weight: 600;
				.shown svg {
					transform: rotate(-180deg);
				}
			}
			.vehicles svg path {
				stroke: #292d32;
			}
			.status {
				display: flex;
				padding: 3px 8px;
				align-items: center;
				justify-content: center;
				gap: 10px;
				border-radius: 4px;
				font-weight: 400;

				&.unexecuted {
					background-color: rgba(251, 136, 30, 0.16);
					color: #fb881e;
				}
				&.executed {
					background-color: rgba(1, 157, 76, 0.16);
					color: #019d4c;
				}
			}

			.make-execute-btn {
				display: flex;
				align-items: center;
				padding: 0;
				gap: 3px;
				color: #019d4c;
				font-weight: 600;
				font-size: 14px;

				&:focus {
					box-shadow: 0 0 0 0.15rem rgba(1, 157, 76, 0.25);
				}
				svg path {
					stroke: #219653;
				}
			}
			.direction-initial {
				direction: initial !important;
			}
			.no-drivers {
				color: #98a2b3;
				font-size: 16px;
				font-weight: 400;
			}
		}
		.font-light {
			font-size: 11px;
			font-weight: 400;
			color: #707170;
		}
	}
}
[dir="rtl"] .table .btn svg {
	transform: scale(1, 1);
}
.operation-center-modal {
	.modal-title {
		width: 100%;
		padding: 16px 0;
		text-align: center;
		color: #050001;
		font-size: 16px;
		font-weight: 600;
	}
	.close {
		color: #050001;
		opacity: 1;
	}
	.control-field__label {
		color: #707170;
		font-size: 14px;
		font-weight: 400;
	}
	.save-btn {
		display: flex;
		padding: 12px 0;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-top: 16px;
		gap: 8px;
		border-radius: 8px;
		background-color: #d4a655;
		color: #fefeff;
		font-weight: 700;
		font-size: 16px;
	}
	.control-field__prepend {
		border: 0;
		span {
			background-color: transparent;
			color: #050001;
			font-weight: 600;
		}
	}
	.fix_place_arrow {
		display: none;
	}
}
/*** Start Timing Settings ***/
.operations-settings {
	.item-row {
		border: 1px solid #eae9e9;
		border-radius: 8px;
		padding: 20px 26px 32px 26px;
		margin-block: 26px;
		.header-title {
			color: #050001;
			font-weight: 600;
			font-size: 18px;
			margin-bottom: 24px;
		}
		.item-content {
			border: 1px solid #dddcdc;
			border-radius: 8px;
			padding: 8px;
			.row {
				margin-inline: 0 !important;
			}
			.header-tabs {
				display: flex;
				justify-content: space-between;
				.tab {
					width: 32%;
					background-color: #dddcdc;
					padding: 12px 16px 12px 16px;
					border-radius: 8px;
					display: flex;
					align-items: center;
					justify-content: center;

					label,
					p {
						color: #050001;
						font-size: 18px;
						font-weight: 600;
						text-align: center;
						line-height: 1;
						margin-bottom: 0 !important;
					}
					span {
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
			.content-tabs {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.tab {
					width: 32%;
					margin-top: 8px;
				}
				.time-feild {
					width: 80%;
					display: inline-block;
					.control-field {
						margin-block: 0 !important;
					}
				}
				span {
					margin-inline: 23px;
					font-size: 16px;
					font-weight: 600;
					color: #000;
				}
				.label-data {
					background-color: #f7f6f6;
					// border: 1px solid #707170;
					border-radius: 4px;
					// padding:12px 16px 12px 16px;
					display: flex;
					align-items: center;
					// justify-content: center;
					height: 44px;
					padding-inline: 16px;
				}
			}
		}
	}
	.bus-row {
		.content-tabs-bus,
		.header-tabs-bus {
			display: flex;
			gap: 30px;
			flex-wrap: nowrap;
			justify-content: flex-start !important;
			.tab {
				width: 47% !important;
				span {
					margin-inline: 8px;
				}
			}
		}
	}
	.attraction-row {
		.tab {
			width: 23% !important;
			display: flex;
			align-items: center;
		}
		.tab-blank {
			background-color: #fff !important;
		}
	}
	.port-durations-row {
		.tab {
			width: 19% !important;
			display: flex;
			align-items: center;
		}
	}

	.movement-row {
		.header-tabs-move {
			.tab-65 {
				width: 60% !important;
			}
			.tab-30 {
				width: 35% !important;
			}
		}
		.content-tabs-move {
			.all-label-data {
				width: 60% !important;
				display: flex !important;
				gap: 10px;
				.label-data {
					width: 100% !important;
					display: flex;
					flex-direction: column;
					p {
						line-height: 1;
						padding-top: 5px;
					}
					h1 {
						padding-bottom: 3px;
					}
				}
			}
			.tab {
				width: 35% !important;
				display: flex;
				align-items: flex-start !important;
			}
		}
	}

	.settings-wrapper {
		display: flex;
		padding: 24px 26px;
		flex-direction: column;
		gap: 24px;
		border-radius: 8px;
		border: 1px solid #eae9e9;
		background-color: #fff;
		margin-bottom: 26px;

		.header-title {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 18px;
			font-weight: 600;
			line-height: 24px;
			color: #050001;

			svg {
				stroke: #292d32;
			}
		}
		.control-field__label {
			color: #707170;
			font-size: 14px;
			font-weight: 400;
			white-space: nowrap;
			i {
				color: #d2ab67;
			}
		}
	}

	.setting-serial-bank {
		display: flex;
		flex-wrap: wrap;
		gap: 26px;
	}
	.bank-info {
		flex-basis: calc(75% - 13px);
		flex-grow: 1;
	}
	.operating-serial {
		flex-basis: calc(25% - 13px);
		flex-grow: 1;
	}
	.department-details {
		.management-title {
			display: flex;
			justify-content: center;
			align-items: center;
			max-width: 190px;
			padding: 12px 0;
			border-radius: 6px;
			background-color: rgba(203, 203, 203, 0.38);
			color: #707170;
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		}
		.control-field__prepend {
			border: 0;
			span {
				background-color: transparent !important;
			}
		}
	}
	.setting-header,
	.setting-footer {
		flex-basis: calc(50% - 12px);
		flex-grow: 1;
	}

	.image-input-container {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 200px;
		margin-top: 8px;
		padding: 16px 14px;
		gap: 12px;
		border-radius: 4px;
		border: 2px dashed #eae9e9;
		overflow: hidden;

		&.image-banner {
			width: 100%;
			height: 100%;
			justify-content: center;
			.preview {
				flex-basis: 100%;
				max-width: 100% !important;
			}
		}
		&.image-left {
			justify-content: flex-start;
		}
		&.image-right {
			justify-content: flex-end;
		}
		[dir="rtl"] &.image-right {
			justify-content: flex-start;
		}
		[dir="rtl"] &.image-left {
			justify-content: flex-end;
		}

		.input {
			position: absolute;
			width: calc(100% - 14px);
			height: calc(100% - 16px);
			left: 7px;
			opacity: 0;
			overflow: hidden;
		}
		.preview {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 50%;
			height: 100%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.textarea {
		padding: 8px 16px;
		border-radius: 6px;
		border: 1px solid #dddcdc;
		background-color: #fefeff;
	}
	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		width: 90%;
	}
	.react-datepicker__close-icon::before,
	.react-datepicker__close-icon::after {
		background-color: red;
	}
	.react-datepicker__close-icon::after {
		height: 18px !important;
		width: 18px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		line-height: 0 !important;
		padding: 0;
	}
}

/*** End Timing Settings ***/

/*** start accounting tab ***/

.operation-accounting-edit {
	--boxes-height: 64px;

	.control-field__input {
		text-align: center;
	}
	.operating-btn-back:disabled,
	.operating-btn-next:disabled {
		background-color: #eae8e9 !important;
		cursor: no-drop !important;
	}

	.pricing-type {
		margin: 24px 0;
		padding: 16px 24px;
		background-color: rgba(212, 166, 85, 0.08);

		.header {
			margin-bottom: 8px;
			font-size: 18px;
			font-weight: 600;
			color: #050001;
		}

		.item {
			position: relative;
			display: flex;
			align-items: center;

			label {
				position: relative;
				margin-bottom: 0;
				padding: 0 24px;
				font-size: 16px;
				font-weight: 600;
				line-height: 168.4%;
				color: #050001;
				cursor: pointer;
				z-index: 2;
			}

			input {
				position: absolute;
				opacity: 0;
				height: 0;
				width: 0;
			}

			.checkmark {
				position: absolute;
				top: 4px;
				left: 0;
				[dir="rtl"] & {
					right: 0;
				}
				height: 18px;
				width: 18px;
				background-color: transparent;
				border: 1px solid #dddcdc;
				border-radius: 50%;
				cursor: pointer;
				overflow: hidden;
			}

			input:checked ~ .checkmark {
				border: 1px solid #d4a655;
			}

			input:checked ~ .checkmark:after {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 75%;
				height: 75%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				background-color: #d4a655;
			}
		}
	}

	.accounting-item {
		.header-title {
			color: #d4a655;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 1.25rem;
		}
		.content {
			display: flex;
			flex-wrap: wrap;
			gap: 20px;
			color: #050001;
			font-weight: 400;

			.label {
				color: #707170;
				font-size: 1rem;
				font-weight: 600;
				text-align: inherit;
				width: auto;
			}
		}
	}

	.accounting-item-wrapper {
		display: flex;
		flex-direction: column;
		padding: 20px;
		gap: 16px;
		border-radius: 6px;
		border: 1px solid #dddcdc;
		background-color: #fff;
	}
	.pricing-inputs-wrapper {
		width: 70%;
		margin: 0 auto;
		gap: 10px;
	}

	.total-pricing {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 16px 24px;
		border: 1px solid #f4f4f7;
		background-color: #fff;

		.header {
			font-size: 1.125rem;
			font-weight: 600;
			color: #050001;
		}
	}

	.pricing-inputs {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;

		.item {
			width: calc(25% - 15px);
			padding: 8px 16px;
			border-radius: 4px;
			font-size: 1.125rem;
			font-weight: 600;
			text-align: center;
			margin-bottom: 10px;
		}
		.pax {
			color: #707170;
			background-color: #dddcdc;
		}
		.buying {
			background-color: #1e85ff;
			color: #fbfbfa;
		}
		.selling {
			background-color: #219653;
			color: #fbfbfa;
		}
		.profit {
			background-color: #d4a655;
			color: #fbfbfa;
		}
		.pax-count {
			background-color: #f7f6f6;
			color: #050001;
		}
		.total-profit {
			border: 1px solid #f7f6f6;
			background-color: #fbf6ee;
			font-weight: 700;
			color: #d4a655;
		}
	}

	.text-black-600 {
		color: #050001;
		font-weight: 600;
	}

	.apply-discount input {
		accent-color: #d4a655;
	}

	.price-with-taxes {
		display: flex;
		flex-direction: column;
		font-size: 1.125rem;
		line-height: 160.9%;

		.price-amount {
			color: #d4a655;
			font-size: 2.125rem;
			font-weight: 700;
		}

		.price-currency {
			font-size: 1.25rem;
			font-weight: 400;
		}
	}

	.arrival-departure-cost {
		display: flex;
		flex-wrap: wrap;
		background-color: #fff;
		overflow: hidden;

		.cost-table {
			padding: 20px;
			border: 0.5px solid #dddcdc;
			flex-basis: 50%;
			overflow-x: auto;

			h5 {
				display: flex;
				align-items: center;
				gap: 8px;
				font-size: 0.95rem;
				font-weight: 600;
			}

			table {
				text-align: center;
				border-collapse: separate;
				border-spacing: 10px;
				margin: 0 -20px;

				th,
				td {
					text-align: center;
				}

				tbody tr td:first-child {
					font-size: 1rem;
					font-weight: 600;
					color: #050001;
				}
				thead tr th:not(:first-child) {
					padding: 7px 12px;
					border-radius: 4px;
					font-size: 1.125rem;
				}
				input {
					text-align: center;
				}

				.control-field:focus-within {
					// border: 1px solid #D4A655 !important;
				}

				.custom-border-color {
					// border-color: rgba(112, 113, 112, 0.1) !important;
				}
			}

			.pax-count-row td:not(:first-child) {
				padding: 8px 12px;
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background-color: #f7f6f6;
			}
			.total-amount {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 14px 16px;
			}
		}

		.buying {
			border-radius: 6px 0px 0px 0px;
			h5 {
				color: #1e85ff;
			}
			thead tr th:not(:first-child) {
				background-color: #1e85ff;
				color: #fbfbfa;
			}
			.total {
				color: #1e85ff !important;
				font-weight: 600;
			}
			.total-amount {
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background-color: rgba(30, 133, 255, 0.1);
			}
		}
		.selling {
			border-radius: 0px 6px 0px 0px;
			h5 {
				color: #219653;
			}
			thead tr th:not(:first-child) {
				background-color: #219653;
				color: #fbfbfa;
			}
			.total {
				color: #219653 !important;
				font-weight: 600;
			}
			.total-amount {
				border-radius: 4px;
				border: 1px solid rgba(112, 113, 112, 0.1);
				background: rgba(33, 150, 83, 0.1);
			}
		}
	}

	.arrival-departure-profit {
		display: flex;
		align-items: center;
		padding: 20px;
		gap: 16px;
		border-radius: 0px 0px 6px 6px;
		border: 0.5px solid #dddcdc;

		.box {
			padding: 14px 20px;
			border-radius: 4px;
			border: 1px solid rgba(112, 113, 112, 0.1);
			background: #d4a655;
			color: #fbfbfa;
			font-weight: 600;
		}

		.total-profit {
			width: 100%;
			padding: 14px 16px;
			text-align: center;
			border-radius: 4px;
			border: 1px solid rgba(112, 113, 112, 0.1);
			background-color: #fbf6ee;
		}
	}

	.attractions .pricing-type {
		padding: 16px 0;
		margin: 0;
		background-color: transparent;

		label {
			color: #707170;
		}
	}

	.attraction-item {
		display: flex;
		flex-direction: column;
		padding: 16px;
		gap: 10px;
		background-color: #f7f6f6;
	}

	.delete-pricing-btn {
		display: flex;
		align-items: center;
		width: fit-content;
		padding: 0;
		margin-top: 1rem;
		gap: 6px;
		font-size: 1rem;
		font-weight: 600;
		color: #ea5455;
		svg path {
			stroke: #f53d3d;
		}
	}

	.btn-plus-operation {
		display: flex;
		align-items: center;
		gap: 6px;

		span {
			margin: 0;
		}
	}

	.transportations-info {
		padding: 16px;
		background-color: #f7f6f6;

		.movements-table {
			margin-top: 24px;
			border: 1px solid #dbdade;
			overflow-x: auto;

			thead th {
				padding: 16px 20px;
				border-bottom: 1px solid #dbdade;
				color: #707170;
				font-weight: 700;
				font-size: 0.875rem;
				background-color: #f7f6f6;
			}
			tbody td {
				padding: 12px 20px;
				color: #050001;
				font-weight: 400;
				background-color: #fff;
				vertical-align: middle;
			}
		}
	}

	.hotel-pricing,
	.visa-pricing {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.pricig-row {
			display: flex;
			text-align: center;
			gap: 12px;

			.buying {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;

				.row-head,
				.row-sub-head {
					border: 1px solid rgba(112, 113, 112, 0.1);
					background-color: #1e85ff;
				}
			}
			.selling {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;

				.row-head,
				.row-sub-head {
					border: 1px solid rgba(112, 113, 112, 0.1);
					background-color: #219653;
				}
			}
			.buying-content,
			.selling-content {
				display: flex;
				flex-direction: column;
				gap: 8px;
				flex-grow: 1;
				color: #fff;
				.row-sub-head {
					border: none;
					background-color: transparent;
					padding: 0 !important;
					height: 44px;
					.input-group {
						margin: 0 !important;
						.control-field__input {
							text-align: center;
						}
					}
				}
			}
			.row-head,
			.row-sub-head {
				border-radius: 4px;
				padding: 8px 12px;
			}
			.row-sub-head {
				flex-basis: 50%;
			}
			.pax-count {
				background-color: #f7f6f6;
				color: #050001;
			}
			.row-data {
				display: flex;
				justify-content: center;
				gap: 10px;
				flex-grow: 1;
			}
			.col-text {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 8px 12px;
				border-radius: 4px;
				font-size: 1.125rem;
				font-weight: 600;
				line-height: 88.89%;
			}
		}
		.pricig-row.profit {
			.col-text {
				background-color: #d4a655;
				color: #fff;
			}
			.profit-amount {
				background: #fbf6ee;
				padding: 8px 12px;
				border: 1px solid #f7f6f6 !important;
				color: #d4a655;
				font-weight: 700;
			}
		}

		.pricing-label,
		.pax {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			font-size: 1.125rem;
			font-weight: 600;
			background-color: #dddcdc;
			color: #707170;
		}
		.pricing-label {
			background-color: #f7f6f6 !important;
			color: #050001 !important;
		}
		.Profit {
			.pax {
				background-color: #d4a655;
				color: #fff;
			}
			.profit-amount {
				background: #fbf6ee;
				padding: 8px 12px;
				border: 1px solid #f7f6f6 !important;
				color: #d4a655;
				font-weight: 700;
			}
		}

		.total {
			.col-text {
				background-color: #dddcdc;
				color: #707170;
			}
			.buying-amount,
			.selling-amount {
				padding: 8px 12px;
				font-size: 1.125rem;
				font-weight: 600;
				border-radius: 4px;
				background-color: #fff;
				flex-basis: 50%;
			}
			.buying-amount {
				border: 1px solid #1e85ff !important;
				color: #1e85ff;
			}
			.selling-amount {
				border: 1px solid #219653 !important;
				color: #219653;
				.control-field__input {
					text-align: center;
					background: #fbf6ee;
					color: #d4a655;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}

		.sales,
		.Profit {
			.col-33 {
				width: calc(33.33333333% + 10px) !important;
			}
			.col-50 {
				width: calc(50% + 24px) !important;
			}
		}
		.sales .buying-content {
			.input-group {
				border: 1px solid #1e85ff !important;
				background: #fff;
				.control-field__input {
					background: #fff;
					color: #1e85ff;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
		.sales .selling-content {
			.input-group {
				border: 1px solid #219653 !important;
				background: #fff;
				.control-field__input {
					background: #fff;
					color: #219653;
					font-size: 18px;
					font-weight: 600;
				}
			}
		}
		.input-group {
			.no-gutters {
				padding-block: 3px !important;
			}
		}
	}
}
/*** end accounting tab ***/

.change-pricing-Modal {
	.modal-content {
		width: 90%;
		border: none;
		border-radius: 12px;
		box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
			0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	}

	.modal-body {
		padding: 20px;
	}

	.header {
		color: #101828;
		font-size: 18px;
		font-weight: 600;
		line-height: 155.56%;
	}

	.icon {
		display: flex;
		width: 48px;
		height: 48px;
		padding: 4px;
		justify-content: center;
		align-items: center;
		border-radius: 28px;
		border: 8px solid #fef3f2;
		background-color: #fee4e2;
	}

	.info-text {
		color: #667085;
		font-size: 14px;
		font-weight: 400;
		line-height: 142.86%;
	}
	.reject-btn,
	.confirm-btn {
		display: flex;
		padding: 10px 18px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex-grow: 1;
		border-radius: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
	}
	.reject-btn {
		border: 1px solid #d0d5dd;
		background-color: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #344054;
	}
	.confirm-btn {
		border: 1px solid #d92d20;
		background: #ea5455;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #fff;
	}
}

@media screen and (max-width: 1100px) {
	.operations-settings {
		.operating-serial {
			flex-basis: calc(35% - 13px);
		}
		.bank-info {
			flex-basis: calc(65% - 13px);
		}
	}
	.operation-tabs .item {
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 767px) {
	.operations-agents .add-edit-agent .add-edit-form {
		padding: 16px 8px;
	}

	.operations-settings {
		.setting-header,
		.setting-footer,
		.operating-serial,
		.bank-info {
			flex-basis: calc(100% - 13px);
		}
	}

	.operation-accounting-edit .arrival-departure-cost .cost-table {
		flex-basis: 100%;
	}
}

@media screen and (max-width: 600px) {
	.m-mob {
		margin-bottom: 1rem !important;
	}

	.m-mob button {
		width: auto !important;
	}
}

.action-btn {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 26px;
	.btn-yellow {
		border-radius: 8px;
		background: #d4a655;
		padding: 0px 16px;
		height: 40px;
		color: #fff;
		font-size: 16px;
		font-weight: 700;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;
		margin-bottom: 26px;
	}
}

.custom-timing-settings {
	.custom-time-field-container .time-details {
		padding: 0 !important;
		border: none !important;
	}
	label {
		display: none !important;
	}
}

.w-icons-mob {
	width: 14rem !important;
}
.m-icon-1 {
	span {
		margin-right: 3px;
		display: inline-block;
		width: 24px;
		height: 24px;
		background: #dfbcbc30;
		border-radius: 50%;
		text-align: center;

		svg {
			height: 15px;
			width: 15px;
		}
	}
	.active {
		background-color: #d4a655 !important;
	}
}

.account-status {
	.unpaid {
		color: #ff3131;
		background: rgba(255, 49, 49, 0.16) !important;
		padding: 5px 20px;
	}

	.paid {
		color: #019d4c;
		background: rgba(1, 157, 76, 0.16) !important;
		padding: 5px 20px;
	}

	.new {
		color: #fb881e;
		background: rgba(251, 136, 30, 0.16) !important;
		padding: 5px 20px;
	}
}

.actions-list {
	.payment-text {
		overflow: hidden;
		color: var(--light-copy-text-muted, #777);
		text-overflow: ellipsis;
		white-space: nowrap;
		font-family: Cairo;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 16px; /* 133.333% */
		text-transform: uppercase;
	}
	.payment-row {
		display: flex;
		align-items: center;
		.payment-icon {
			margin-right: 10px;
		}
		.payment-text-label {
			color: var(--050001, #050001);
			font-family: Cairo;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
}

.fontStyleAccounting {
	color: #778294;
	font-size: 12px;
	font-weight: 500;
}

.font-style-dark {
	font-size: 14px;
	color: #000;
}

.heading-accounting {
	font-size: 24px;
	font-weight: 600;
	color: #d4a655;
}

.c-grey {
	color: #313131;
}

.text-filed-visibility-hidden {
	.control-field {
		visibility: hidden !important;
	}
	button {
		visibility: hidden !important;
	}
}

.accounting-border-bottom {
	width: 100%;
	border-bottom: 1px solid #dddcdc;
}

.dropdown-menu-accounting {
	display: none;
	position: absolute;
	right: 100%;
	width: 100%;
	background: #FFF;
	top: 50%;
	border-radius: 8px;
}

.list-menu:hover .dropdown-menu-accounting {
	display: block;
	z-index: auto;
}