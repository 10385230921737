.special-allotment {
	background-color: #fff;

	// Start Special Data Container
	.special-data {
		padding: 26px 12px;
		.sort {
			display: flex;
			justify-content: space-between;
			margin-bottom: 6px;
			// margin-top: 15px;
			p {
				font-size: 20px;
				font-weight: 700;
				color: #121;
				span {
					color: #d4a655;
				}
			}
			label {
				font-size: 14px;
			}
			select {
				border: none;
				font-size: 14px;
				font-weight: 800;
				background: #fff;
			}
		}
		.item-data-container {
			border: 1px solid #efefef;
			border-radius: 8px;
			background-color: #fff;
			margin-bottom: 15px;

			.item-data {
				display: flex;
				gap: 12px;
				padding: 16px;
				.image {
					img {
						width: 280px;
						height: 160px;
						border-radius: 9px;
					}
				}
				.data {
					width: 100%;
					h3 {
						font-weight: 700;
						margin-bottom: 5px;
						color: #050001;
						font-size: 18px;
					}
					.starts {
						display: flex;
						gap: 5px;
						line-height: 1;
						height: 20px;
						i {
							font-size: 14px;
							color: rgba(250, 196, 48, 1) !important;
						}
						.solid-star {
							color: rgba(221, 220, 220, 1) !important;
						}
					}
					span {
						display: inline-block;
						color: #d4a655;
						font-size: 14px;
						margin-bottom: 9px;
					}
					.location {
						display: flex;
						gap: 6px;
						margin-bottom: 15px;
						align-items: center;
						svg {
							margin-top: 2px;
						}
						span {
							color: rgba(5, 0, 1, 1);
							font-size: 14px;
							font-weight: 500;
						}
					}

					.actions {
						display: flex;
						justify-content: space-between;
						align-items: center;
						button {
							font-size: 14px;
							text-decoration: underline;
							padding: 0;
							color: #1e85ff !important;
						}
						button:focus {
							box-shadow: none !important;
						}
						.rate {
							color: #019d4c;
							background-color: rgba(1, 157, 76, 0.08);
							padding: 3px 7px;
							border-radius: 12px;
							line-height: 1;
							font-weight: 600;
						}
						.price {
							display: flex;
							flex-direction: column;
							span {
								color: #707170;
								font-size: 13px;
								margin: 0;
							}
							p {
								font-weight: 700;
								font-size: 24px;
								line-height: 1;
								color: rgba(5, 0, 1, 1);

								.currency {
									color: rgba(5, 0, 1, 1);
									font-size: 16px;
									font-weight: 400;
								}
								.nights {
									color: #707170;
									font-size: 15px;
									font-weight: 500;
								}
							}
						}
					}
				}
			}
		}
	}

	// Start Filter
	.special-filter {
		border-right: 1px solid rgba(239, 239, 239, 1);
		padding: 26px;
		h2 {
			color: rgba(17, 34, 17, 1) !important;
			font-size: 20px;
			margin-bottom: 32px;
			font-weight: 700;
		}
		.filters {
			h5 {
				color: #121 !important;
				font-weight: 700;
				cursor: pointer;
				font-size: 16px;
				img {
					margin-top: -4px;
				}
			}
			.multi-range-slider {
				border: none !important;
				box-shadow: none !important;
			}
			.multi-range-slider .bar-inner {
				background-color: #121;
				height: 2px;
			}
			.multi-range-slider .bar-right,
			.multi-range-slider .bar-left {
				background-color: #fba50d;
				height: 2px;
				padding: 0;
			}

			.multi-range-slider .thumb {
				background-color: #d4a655 !important;
			}
			.multi-range-slider .thumb::before {
				content: "";
				box-shadow: none !important;
				border: none !important;
			}
			.multi-range-slider .labels {
				margin-inline: -10px;
			}
			.multi-range-slider .label {
				color: #121;
				font-size: 14px;
			}
			.starts {
				.custom-checkbox {
					margin-bottom: 8px;
				}
				label {
					display: flex;
					gap: 17px;
					padding: 0px 14px;
				}
				.custom-control-label::after {
					border: 2px solid #121;
					border-radius: 4px;
					width: 24px;
					height: 24px;
					top: 0;
				}
				.custom-control-input:checked ~ .custom-control-label::before {
					top: 0;
					width: 24px;
					height: 24px;
				}
				.custom-control-label::before {
					border: 0 !important;
				}
				i {
					font-size: 19px;
				}
				i.fas {
					color: rgb(250, 196, 48) !important;
					font-size: 19px;
				}
			}
			.custom-checkbox {
				margin-bottom: 8px;
			}
			label {
				display: flex;
				gap: 17px;
				padding: 0px 8px;
			}
			.custom-control-label::after {
				border: 2px solid #222;
				border-radius: 4px;
				width: 1.2rem;
				height: 1.2rem;
				top: 0;
			}
			.custom-control-input:checked ~ .custom-control-label::before {
				top: 0;
				width: 1.2rem;
				height: 1.2rem;
			}
			.custom-control-label::before {
				border: 0 !important;
			}
			.control-field-inner {
				height: 45px !important;
			}
			.control-field__input {
				margin-top: 6px !important;
			}
			.control-field__input::placeholder {
				color: #dddcdc !important;
			}
			.collapse {
				transition: all 0.5s ease-in-out;
			}
			.list-with-height1 {
				min-height: 200px;
				overflow: auto;
				.more {
					font-size: 16px;
					color: rgba(30, 133, 255, 1);
					font-weight: 700;
					cursor: pointer;
				}
			}
			hr {
				margin-block: 32px;
			}
		}
	}
	// Start Allotment Details
	.allotment-details {
		// padding-bottom: 10px;
		.nav-tabs {
			background: #fff;
			border-bottom: 1px solid #dddcdc !important;
			.nav-item {
				.nav-link {
					color: #707170;
					font-size: 18px;
					font-weight: 600;
					cursor: pointer;
					border: none;
					padding: 24px 16px;
				}
				.active {
					color: #d4a655;
					padding: 24px 16px;
					border-bottom: 1px solid #d4a655;
				}
			}
		}
		.tab-content {
			padding: 16px;
			.pricing {
				.pricing-filter {
					display: flex;
					gap: 5px;
					div {
						// display: flex;
						// flex-direction: column;
						label {
							color: #707170;
							font-size: 14px;
							font-weight: 400;
							margin-bottom: 2px;
						}
						.no-gutters.control-field {
							padding: 8px 16px !important;
						}
						.control-field__select {
							margin-top: 3px;
							margin-inline: 5px;
							font-size: 16px;
							color: #050001;
							font-weight: 600;
						}
					}
				}
				.select-option {
					border: 1px solid #dbdade;
					// border-bottom: none;
					border-radius: 8px;
					.head {
						background-color: #f7f6f6;
						padding: 14px;
						border-bottom: 1px solid #dbdade;
						border-radius: 8px 8px 0 0px;
						h6 {
							color: rgba(112, 113, 112, 1);
							font-size: 14px;
							font-weight: 700;
							letter-spacing: 1px;
							text-transform: uppercase;
						}
					}
					.option-row {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 9px 14px;
						border-bottom: 1px solid #dbdade;
						.item {
							display: flex;
							flex-direction: column;
							span {
								color: #828382;
								font-size: 13px;
								font-weight: 400;
								line-height: 1;
							}
							p {
								color: #000;
								font-size: 16px;
								font-weight: 400;
							}
							div {
								p {
									color: #000;
									font-size: 16px;
									font-weight: 700;
								}
								span {
									color: #000;
									font-size: 12px;
									font-weight: 400;
								}
							}
						}
						button {
							padding: 5px 32px;
							font-size: 14px;
							font-weight: 700;
							border-radius: 8px;
							background: #d4a655 !important;
						}
						button:hover {
							color: #fff;
						}
					}
					.option-row:last-of-type {
						border-bottom: none;
					}
				}
				.availableRooms {
					.head {
						button {
							border-radius: 8px;
							border: 1px solid #dddcdc;
							background: #fff;
							color: #707170;

							font-size: 16px;
							font-weight: 500;
						}
					}

					.room-row {
						padding: 0px 14px;
						.item {
							display: flex;
							flex-direction: column;
							width: 18%;
						}
						.num-rooms {
							border-left: 1px solid #dbdade;
							[dir="rtl"] & {
								border-right: 1px solid #dbdade;
							}
						}
						.control-field__body {
							width: 145px;
							border-radius: 0;
						}
						.control-field {
							border: none;
							padding: 0px 5px !important;
							margin: 0 !important;
							span {
								color: #707170;
								font-size: 14px;
								font-weight: 400 !important;
							}
							svg {
								fill: #d4a655;
							}
						}
						.control-field__select {
							padding: 0;
							color: #050001;
							font-size: 16px;
							width: 100%;
							top: -4px;
						}
						.control-field-inner {
							height: 45px !important;
						}
					}
					.requestResAction {
						margin-top: 16px;
						button:disabled,
						button[disabled]{
							background: transparent linear-gradient(180deg, #767676 0%, #979797 97%, #c7c7c7 100%) 0% 0% no-repeat padding-box !important;
							border: none !important;
						}
						button {
							border-radius: 8px;
							background: #cb9a51 !important;
							width: 230px;
							height: 50px;
							display: flex;
							justify-content: center;
							align-items: center;
							border: 1px solid #efefef;
							font-size: 18px;
							font-weight: 700;
							color: #fefeff;
						}
						
					}
				}
			}
			.overview {
				color: #050001;
				font-size: 14px;
				font-weight: 500;
			}
			.hotel-facilities {
				display: flex;
				justify-content: space-start;
				flex-wrap: wrap;
				margin: 0;
				p {
					width: 32%;
					margin-bottom: 10px;
					color: #050001;
					font-size: 14px;
					font-weight: 400;
					span {
						margin-inline: 5px;
					}
				}
			}
			.gallery {
				padding: 12px 2px;

				.react-multi-carousel-list {
					// height: 370px;
					align-items: baseline;
				}
				.react-multi-carousel-item {
					img {
						width: 98% !important;
						height: 270px;
					}
				}
				.react-multiple-carousel__arrow--left {
					bottom: 35px;
					left: 90%;
					// right: 0;
				}
				.react-multiple-carousel__arrow--right {
					bottom: 35px;
					right: calc(0% + 1px);
					// left: 45%;
				}
				.react-multiple-carousel__arrow {
					// position: absolute;
					border-radius: 8px;
					border: 1px solid rgba(212, 166, 85, 0.06);
					background: #d4a655;
				}
				.react-multiple-carousel__arrow::before {
					font-size: 12px;
					font-weight: 700;
				}
				.carousel-button-group {
					display: flex;
					justify-content: flex-end;
					margin-inline: 10px;
					margin-top: 10px;
					gap: 10px;

					button {
						border-radius: 8px;
						border: 1px solid rgba(212, 166, 85, 0.06);
						background: #d4a655;
						width: 50px;
						height: 50px;
						display: flex;
						flex-wrap: wrap;
						align-content: center;
						justify-content: center;
						svg {
							path {
								stroke: #fff;
							}
						}
					}
					.disable {
						background-color: #faf4eb !important;
						svg {
							path {
								stroke: #050001;
							}
						}
					}
				}
			}
		}
	}
	.rate {
		color: #019d4c;
		background-color: rgba(1, 157, 76, 0.08);
		padding: 3px 7px;
		border-radius: 12px;
		line-height: 1;
		font-weight: 600;
	}
	.rate-yellow {
		color: #d4a655;
		background: #faf4eb;
		padding: 3px 7px;
		border-radius: 12px;
		line-height: 1;
		font-weight: 600;
	}
	.rate-danger {
		color: #EA5455;
		background: rgba(234, 84, 85, 0.10);
		padding: 3px 7px;
		border-radius: 12px;
		line-height: 1;
		font-weight: 600;
	}
}
//Summary booking
.allotment-summary-booking {
	margin-top: -50px;
	padding: 8px 26px;
	background-color: #fff;
	.p-hotel-info {
		color: #707170;
		font-size: 20px;
		font-weight: 600;
		margin-top: 8px;
		margin-bottom: 15px;
	}
	.row {
		margin-inline: 0 !important;
	}
	.hotel-info {
		padding: 24px 26px 24px 24px;
		border-radius: 8px;
		border: 0.5px solid #e0e0e0;
		.info-data {
			display: flex;
			flex-wrap: nowrap;
			align-items: stretch;
			gap: 20px;
			.image {
				img {
					width: 245px;
					height: 115px;
					border-radius: 10px;
				}
			}
			.data {
				h3 {
					color: #050001;
					font-size: 18px;
					font-weight: 700;
					margin-bottom: 8px;
				}
				.stars {
					display: flex;
					gap: 3px;
					margin-bottom: 8px;
					i {
						width: 20px;
						height: 20px;
						color: #fac430;
					}
					.solid-star {
						color: #dddcdc !important;
					}
				}
				.location {
					margin-bottom: 8px;
					svg {
						width: 20px;
						height: 20px;
					}
					span {
						color: #050001;
						font-size: 14px;
						font-weight: 500;
						margin-inline: 3px;
					}
				}
				.more-info {
					display: flex;
					gap: 16px;
					div {
						display: flex;

						svg {
							width: 20px;
							height: 20px;
							margin-top: 3px;
						}
						p {
							color: #707170;
							text-align: right;
							font-size: 16px;
							font-weight: 500;
							margin-inline: 3px;
							span {
								color: #050001;
								font-size: 16px;
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}
	.gust-details {
		padding: 24px 26px 24px 24px;
		border-radius: 8px;
		border: 0.5px solid #e0e0e0;
		margin-block: 16px;
		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		.box {
			// border-radius: 5px;
			// border: 0.5px solid #e0e0e0;
			// padding: 10px 8px;
			.details {
				// padding: 10px 8px;
				padding: 15px 24px;
				border-radius: 5px;
				border: 0.5px solid #e0e0e0;
				margin-bottom: 20px;
				.head-info {
					display: flex;
					justify-content: space-between;
					.room {
						display: flex;
						align-items: center;
						gap: 10px;
						svg {
							width: 24px;
							height: 24px;
						}
						.data {
							span {
								color: #797a79;
								font-size: 16px;
								font-weight: 400;
							}
							p {
								color: #000;
								font-size: 16px;
								font-weight: 600;
								line-height: 1;
							}
						}
					}
					.pax {
						display: flex;
						justify-content: center;
						align-items: center;
						gap: 10px;
						svg {
							width: 24px;
							height: 24px;
						}
						p {
							color: #050001;
							font-size: 16px;
							font-weight: 600;
						}
					}
				}
				hr {
					background: #e0e0e0;
					// height: 0.5px;
					margin-bottom: 5px;
				}
				.inputs {
					display: flex;
					justify-content: space-between;
					.input-item {
						width: 32%;
						margin-bottom: 10px;
						.control-field__label {
							color: #707170;
							font-size: 14px;
							font-weight: 400;
						}
						.control-field {
							height: 50px;
							padding: 11px 16px !important;
							border-radius: 8px;
							border: 0.4px solid #e0e0e0;
							background: #fefeff;
						}
						.control-field__select {
							color: #050001;
							font-size: 16px;
							font-weight: 600;
						}
						.control-field__body {
							padding: 0 !important;
						}
						.control-field__input::placeholder {
							color: #dddcdc !important;
						}
						svg{
							fill:#050001
						}
					}
				}
				.switch {
					span {
						display: inline-block;
						color: #000;
						font-size: 14px;
						font-weight: 600;
						margin-bottom: 10px;
					}
					.control-field__label {
						display: none;
					}
					.control-field__input::placeholder {
						color: #dddcdc !important;
					}
				}
			}
		}
	}
	.remark {
		padding: 24px 26px 24px 24px;
		border-radius: 8px;
		border: 0.5px solid #e0e0e0;
		margin-bottom: 16px;
		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		.control-field__input::placeholder {
			color: #dddcdc !important;
		}
		.control-field__label {
			display: none;
		}
	}
	.important-info {
		padding: 24px 26px 24px 24px;
		border-radius: 8px;
		border: 0.5px solid #e0e0e0;
		margin-bottom: 16px;
		h6 {
			color: #eb5757;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		.item {
			margin-bottom: 16px;
			.head-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 6px 10px;
				background: #f7f6f6;
				.room {
					display: flex;
					align-items: center;
					gap: 10px;
					svg {
						width: 24px;
						height: 24px;
					}
					.data {
						span {
							color: #797a79;
							font-size: 16px;
							font-weight: 400;
						}
						p {
							color: #000;
							font-size: 16px;
							font-weight: 600;
							line-height: 1;
						}
					}
				}
				.pax {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 10px;
					svg {
						width: 24px;
						height: 24px;
					}
					p {
						color: #050001;
						font-size: 16px;
						font-weight: 600;
					}
				}
			}
			.content-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 16px 20px 16px;
				div {
					padding: 8px 12px;
					border-radius: 6px;
				}
				.cancle {
					background: #fff;
					p {
						color: #050001;
						font-size: 15px;
						font-weight: 700;
					}
					span {
						color: #050001;
						font-size: 15px;
						font-weight: 600;
					}
				}
				.charge {
					background: rgba(234, 84, 85, 0.1);
					text-align: center;
					width: 235px;
					p {
						color: #000;
						font-size: 15px;
						font-weight: 700;
					}
					span {
						color: #000;
						font-size: 12px;
						font-weight: 400;
						span {
							color: #000;
							font-size: 16px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}

	.summary-info {
		padding: 24px 26px 24px 24px;
		border-radius: 8px;
		border: 0.5px solid #e0e0e0;
		h6 {
			color: #050001;
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		.room {
			margin-bottom: 20px;
			.room-num {
				color: #050001;
				font-size: 16px;
				font-weight: 600;
				i {
					margin-inline: 16px;
					font-weight: 900;
					font-size: 5px;
				}
			}
			.info {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 0;
				span {
					color: #707170;
					font-size: 16px;
					font-weight: 400;
					margin-inline: 39px;
				}
				p {
					color: #333;
					font-size: 16px;
					font-weight: 400;
				}
				.cost {
					color: #d4a655;

					font-size: 16px;
					font-weight: 700;
					.cur {
						color: #333;
						font-size: 12px;
						font-weight: 400;
						display: inline-block;
					}
				}
			}
			hr {
				background: #f7f6f6;
				margin: 10px 0 10px 39px;
				[dir="rtl"] & {
					margin: 10px 39px 10px 0px;
				}
				height: 1px;
			}
		}

		hr {
			background: #f7f6f6;
			margin: 20px 6px ;
		
		}
		.total-cost {
			h6 {
				margin-bottom: 20px;
				color: #050001;
				font-size: 18px;
				font-weight: 600;
			}
			p {
				margin-bottom: 20px;
				color: #d4a655;
				font-size: 34px;
				font-weight: 700;
				line-height: 1;
				span {
					color: #050001;
					font-size: 20px;
					font-weight: 400;
				}
			}
		}
		.terms {
			margin-bottom: 20px;
			label {
				color: #707170;
				font-size: 16px;
				font-weight: 500;
				padding: 2px 8px;
				cursor: pointer;
			}

			.custom-control-label::after,
			.custom-control-label::before {
				width: 24px;
				height: 24px;
				border: 2px solid #112211;
				border-radius: 5px;
			}
		}
		button {
			width: 100%;
			color: #fefeff;
			font-size: 18px;
			font-weight: 700;
			border-radius: 8px;
			background: #d4a655;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.solid-star {
	color: rgba(221, 220, 220, 1) !important;
}

@media screen and (max-width: 992px) {
	.item-data-container .item-data {
		flex-wrap: wrap;
	}
}
