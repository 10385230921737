.error-404 {
	background: url("../../../assets/images/svg/404_error_image.svg");
	background-repeat: no-repeat;
	height: 85vh;
	background-position: center;
	position: relative;
	padding-bottom: 0.5rem;

	&__title {
		font-size: calculateRem($text-title);
		font-weight: $font-weight-medium;
	}

	&__content {
		width: 65%;
		margin: auto;
		font-size: calculateRem($text-subtitle);
	}
}
