@media (min-width: 320px) and (max-width: 576px) {
	.wallet-tabs {
		.nav-tabs {
			flex-wrap: wrap;
      margin-bottom: 25px;
			div {
				width: 100% !important;
				.wallet-transaction-tab {
					padding: 0 !important;
				}
			}
			.wallet-pay-buttons {
				margin: unset !important;
        margin-top: 4px !important;
				.recharge-buttons {
					justify-content: center;
					align-items: center;
          gap: 10px;
          padding: 0px 20px;
          .btn-link{
            background: #ebebeb;
            padding: 4px;
          }
          button{
            white-space: nowrap;
          }
				}
			}
		}
    .product-build__tabs-content{
      margin-top: 30px;
    }
	}
  .send-modal{
    width: 100% !important;
    .modal-head{
      width: 100%;
    }
  }
  .filter-btn {
    button{
      white-space: nowrap;
      img{
        display: none;
      }
    }
  }
}
