.reservation {
	background: $gray-100;
}
// Start Header
.reservation__header {
	min-height: 75px;
	// background-image: url(../../../assets/images/product-builder/product-header.png);
	background-repeat: no-repeat no-repeat;
	background-position: inherit;
	background-size: cover;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0.5rem;
} //End header

//Start Reservation Accounting
.reservation__item {
	font-size: $text-caption;
	color: #3f4f61;
	.text {
		font-weight: 700;
	}
	.icons {
		i {
			padding-inline-start: 0.3rem;
		}
	}
}
.reservation__inputs {
	padding: 0.5rem;
	.head {
		font-weight: 700;
	}
}
.reservation__accounting-box {
	padding: 0.5rem;
	.head {
		font-weight: 700;
		// padding: 0.3rem 0;
		padding-bottom: 0.3rem;
	}
	.accounting-form {
		background-color: $white;
		padding: 1rem 2rem;
		border: 1px solid #d8dbde;
		border-radius: 5px;
	}
	.price {
		display: flex;
		justify-content: space-between;
		padding: 0.625rem 0.625rem 0.3125rem 0.625rem;
		flex: 1 1 auto;
		border: 1px solid $gray-200;
		border-radius: 0.3125rem;
		min-height: 55px;
		align-items: center;
		background: $gray-100;
		margin-bottom: 0.3rem;
		margin-top: 0.3rem;
	}
	.pay-form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.pay-input {
			width: 25%;
			text-align: center;
			margin: 0 0.3rem;
			button {
				width: 100%;
				min-height: 50px;
			}
		}
	}
}
//End Reservation Accounting

//Reservation Tab
.main-tabs {
	padding: 0.5rem;
	background: $gray-100;
	.main-nav {
		margin: 1rem 0;
		border: none;
		.nav-tabs {
			border-bottom: none;
			margin-bottom: 1rem;
		}
		.nav-item {
			min-height: 70px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $white;
			min-width: 150px;
			// margin-#{var(--right)}: 0.5rem;
			margin-inline-start: 0.5rem;
			.nav-link {
				border: none;
				color: $dark-blue;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				min-height: 70px;
			}
		}
		.nav-link.active {
			min-width: 150px;
			color: $white !important;
			min-height: 70px;
			&::after {
				content: "";
				right: 50%;
				border-top-color: transparent;
				// border-#{var(--right)}-color: transparent;
				border-inline-start-color: transparent;
				border-bottom-color: $white;
				// border-#{var(--left)}-color: transparent;
				border-inline-end-color: transparent;

				bottom: 0;
				[dir="rtl"] & {
					right: auto;
					left: 50%;
				}
			}
		}
	}

	//traveler-row
	.traveler-row {
		display: flex;
		flex-wrap: wrap;
		.traveler-col {
			width: 20%;
			padding-right: 5px;
			padding-left: 5px;
		}
	}

	//passport-upload
	.passport-upload {
		background: $white;
		width: 100%;
		height: 150px;
		margin: 0.5rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		i {
			color: $gray-300;
		}
	}
}

//Accommodation Tab
.hotel-tab {
	.nav-link {
		padding: 0.5rem 3rem;
		background: $white;
	}
	.nav-link.active {
		background: $gray-300 !important;
		min-height: auto;

		&::after {
			display: none;
		}
	}
} //hotel-tab
.makkah-content {
	padding: 1rem;
	background: $white;
	.head-title {
		font-size: $text-title3;
		color: $dark-blue;
		margin-bottom: 0.8rem;
		margin-top: 0.8rem;
		font-weight: 700;
	}
	.traverlers-content {
		background: $gray-200;
		padding: 0.5rem;
		border-radius: 5px;
		height: 490px;
		overflow: auto;

		.traverlers-data {
			background: #dedede;
			border: 1px solid #d1d1d1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.3rem;
			border-radius: 5px;
			margin-bottom: 0.3rem;
			min-height: 56.59px;
			.head {
				color: $dark-gray;
				font-size: $text-body;
				.room-no {
					font-size: $text-caption;
				}
			}
			.drag-icon {
				width: 45px;
				height: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: $text-title3 + 5;
				color: $white;
			}
			.icon-box {
				width: 45px;
				height: 45px;
				background: gray;
				text-align: center;
				border-radius: 50%;
				font-size: $text-caption;
				padding-top: 0.2rem;
				color: $white;
			}
		}
		.active-drag {
			background: $blue;
			cursor: pointer;
			.head {
				color: $white;
			}
		}
	} //travelers-content

	.rooms-box {
		.rooms-content {
			background: $gray-100;
			border: 1px solid $gray-200;
			border-radius: 5px;
			padding: 1rem 1.5rem;
			box-shadow: 0px 3px 6px #00000029;
			height: 490px;
			overflow: auto;
			.room-item {
				background: $white;
				border-radius: 5px;
				overflow: hidden;
				box-shadow: 0px 3px 6px #00000029;
				min-height: 225px;

				.room-head {
					display: flex;
					justify-content: space-between;
					padding: 0.3rem;
					background: $gray-200;
					font-size: $text-caption;
					.fa-trash-alt {
						color: $red;
					}
					.fa-edit,
					.add-bed {
						color: $blue;
					}
					.fa-unlock-alt {
						color: $gray-300;
					}
					ul {
						display: flex;
					}
					button {
						padding: 0rem 0.2rem;
					}
					i {
						font-size: $text-caption;
					}
				}
				.room-box {
					padding: 0.3rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					background: #dedede;
					margin: 0.3rem;
					border-radius: 5px;
					font-size: $text-body;
					.room-icon {
						color: $gray-300;
						cursor: pointer;
						font-size: $text-caption;
					}
					.room-data {
						color: $dark-gray;
						font-size: $text-caption;
					}
					.gray-color {
						color: $dark-gray;
					}
				}
			} //room-item
			.bg-gray {
				background-color: #dddddd;
			}
		}
	} //rooms-box
} //makkah-content

.additional-service {
	.head {
		padding-top: 1.5rem;
		font-weight: 700;
		padding: 0 5px;
		color: $dark-blue;
	}
	.extra-item {
		border: 1px solid $gray-300;
		border-radius: 5px;
		li {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid $gray-300;
			padding: 0.5rem;
			&:last-child {
				border: none;
			}
			.title {
				color: $dark-gray;
			}
			.price {
				font-weight: 700;
				color: $dark-blue;
			}
		}
	}
}

.accounting {
	padding: 1rem 0;
	.head {
		padding-top: 1.5rem;
		font-weight: 700;
		padding: 0 5px;
		color: $dark-blue;
	}
	.account-item {
		display: flex;
		justify-content: space-between;
		background: $gray-100;
		border: 1px solid #d8dbde;
		border-radius: 5px;
		padding: 1rem;
		.title {
			color: $dark-gray;
		}
		.price {
			font-weight: 700;
			color: $dark-blue;
		}
	}
}

.application-modal {
	.room-price {
		width: 100%;
		display: flex;

		.room-item {
			margin: 0;
			color: $dark-gray;
			width: auto;
			height: 50px;
			text-align: center;
			overflow: hidden;
			border: 1px solid #dedede;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				padding-top: 0.3rem;
			}
			input,
			.room-text {
				border: none;
				width: 100%;
				height: 100%;
				text-align: center;
				color: $dark-gray;
				font-weight: 700;
				width: 180px;
				display: flex;
				justify-content: center;
				align-items: center;
				// input placeholder
				&::placeholder {
					color: $gray-300;
					font-weight: normal;
				}
			}
			.bg-primary {
				background-color: #1777fb3e !important;
				color: #1777fb;
				&::placeholder {
					color: #1777fb;
					font-weight: 700;
				}
			}
		}
	}
}

.main-input-search {
	position: relative;
	margin-bottom: 0.3rem;
	input {
		border: none;
	}
	i {
		position: absolute;
		// #{var(--right)}: 10px;
		right: 10px;
		top: 10px;
		color: $gray-200;
		overflow: hidden;
		background: $white;
		[dir="rtl"] & {
			right: auto;
			left: 10px;
		}
	}
} //main-input-search
