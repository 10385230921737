@import "variables.scss";

.public-page-preview-services {
	background-color: #fbfbfa;
	.web-builder-hero-header header nav {
		.dropdown button {
			color: var(--text-secondary-color) !important;
			text-decoration: none;
			font-size: 16px;
			font-weight: 600;
			padding: 4px 10px !important;
			text-transform: capitalize;
		}


		ul li {
			color: var(--text-secondary-color) !important;
		}
		// .show.dropdown .dropdown-toggle {
		// 	color: #fff !important;
		// }
	}

	.service-container {
		margin-top: 40px;
		margin-inline: 7em;
	}

	.public-page-header {
		padding: 64px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		background-color: #f5f8fc;

		.page-name {
			font-size: 28px;
			font-weight: 700;
		}

		.bread-crumb {
			display: flex;
			gap: 8px;
			align-items: center;
			span {
				color: #707170;
				font-size: 18px;
			}
			& span:first-of-type {
				color: rgba(var(--web-page-primary-color));
				font-size: 18px;
				font-weight: 700;
			}
		}
	}

	.public-page-flight {
		background-color: white;
		border-radius: 12px;
		// padding: 32px;
		width: 100%;

		.main-air-line-logo {
			width: 140px;
			height: auto;
		}
		.small-air-line-logo {
			width: 60px;
			height: auto;
			margin-inline-end: 28px;
		}

		.flight-header {
			font-size: 24px;
			font-weight: 700;
		}

		.flight-details {
			margin-top: 32px;
		}

		.flight-type {
			font-size: 20px;
			color: #050001;
		}

		.flight-details-container {
			border: 1px solid #dddcdc;
			border-radius: 4px;
			padding: 24px 20px;
			margin-top: 16px;

			.trip-details {
				display: flex;
				align-items: center;

				.flight-time-line {
					display: flex;
					flex: 1;
					padding: 10px 120px;
					align-items: center;
				}

				.flight-time {
					flex: 1 1 auto;
					display: flex;
					gap: 8px;
					padding-inline: 16px;
					padding-top: 8px;

					div {
						flex: 1 1 auto;
					}
				}

				.flight-duration {
					border-top: 1px solid #707170;
					text-align: center;
					color: #707170;
					padding-top: 10px;
					font-size: 18px;
				}

				.stop-duration {
					border-top: 1px dashed #707170;
					text-align: center;
					padding-top: 8px;
					span {
						font-size: 18px;
						color: #707170;
					}
					.stop-color {
						color: #f53d3d;
					}
				}

				.flight-departure p:first-of-type,
				.flight-arrival p:first-of-type {
					font-size: 32px;
				}

				.flight-departure {
					& p:last-of-type {
						text-align: end;
						font-size: 20px;
						color: #707170;
					}
				}
				.flight-arrival p:last-of-type {
					font-size: 20px;
					color: #707170;
				}
			}
		}

		.trip-details-container {
			display: flex;
			flex-direction: column;
			width: max-content;
			.waiting-time {
				background: #f5f8fc;
				padding: 12px;
				align-self: center;
				margin-block: 16px;

				span {
					color: rgba(var(--web-page-primary-color));
				}

				.waiting-lable {
					color: #707170;
					margin-inline-start: 8px;
				}
			}
		}

		.air-line {
			span {
				font-size: 18px;
				color: #707170;
			}
		}

		.flight-stops {
			display: flex;
			align-items: center;
			gap: 8px;
			height: 68px;
			margin-top: 16px;
			.trip-duration {
				color: #707170;
			}

			.flight-depart-arrival {
				display: flex;
				flex-direction: column;
				align-items: center;

				& div:first-of-type,
				& div:last-of-type {
					width: 9px;
					border: 2px solid #dddcdc;
					height: 9px;
					border-radius: 50%;
				}

				& div:nth-of-type(2) {
					width: 2px;
					height: 50px;
					background: #dddcdc;
				}
			}

			.ports {
				min-height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				p {
					font-size: 18px;
					color: #050001;
				}
			}
		}
	}

	.request-form {
		.control-field {
			align-items: center;
			border-radius: 6px;
			border: 1px solid #DBDADE;
			background-color: #FFF;
			flex-wrap: nowrap;
		}
		.control-field__prepend span {
			background-color: transparent;
		}

		input::placeholder,
		textarea::placeholder, 		
		[class$=-placeholder] {
			color: #4B465C99 !important;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
		}
		[class$=-singleValue], 
		[class$=-placeholder] {
			margin: 0 10px !important;
		}
		.textarea {
			width: 100%;
			padding: 7px 10px;
			border-radius:  6px;
			border: 1px solid #DBDADE;
			background-color: #FFF;
		}
		.pax-box {
			border-radius: 6px;
			border: 1px solid #DBDADE;
			padding: 7px 14px;
			input {
				border: 0;
				text-align: center;
				width: 35px;
			}
			.btn:disabled {
				background: transparent !important;
				svg path{
					stroke: #707170;
				}
			}
		}
		.submit-btn {
			padding: 12px 32px;
			font-size: 1rem;
			font-weight: 700;
			color: #FEFEFF;
			background-color: rgba(var(--web-page-primary-color));
		}
		.rooms-input-container {
			position: relative;
			max-width: 100%;
			margin-block: .25rem;
			padding: 9px 14px;
			border-radius: 6px;
			background-color: #FFFFFF;
			border: 1px solid #DBDADE;

			.placeholder {
				font-size: 15px;
				font-weight: 400;
				color: #2d2d2d;
				margin-block: 6px;
			}
			.rooms-dropdown {
				position: absolute;
				width: calc(100% + 2px);
				top: calc(100% + 4px);
				inset-inline: -1px;
				padding: 10px;
				border-radius: 6px;
				background-color: #fff;
				box-shadow: 0px 0px 35px 0px #0000000D;
				// box-shadow: 0px 0px 35px 0px #000000;
				z-index: 3;
			}
			.submit-rooms-btn {
				padding: 10px 24px;
				border: 1px solid rgba(var(--web-page-primary-color));
				background-color: rgba(var(--web-page-primary-color));
				border-radius: 6px;
				font-size: 14px;
				font-weight: 400;
				color: #fff;
			}
		}
	}
	.package-pricing .header-item {
		flex-direction: row !important;
		min-width: 30% !important;
		font-size: 18px;
		font-weight: 600;
		color: #14527C;
		svg path {
			stroke: #14527C;
			fill: #14527C1A;
		}
	}
}

.web-builder-content button.dropdown-item {
	text-transform: capitalize !important;
}
.web-builder-preview {
	.web-builder-hero-header header {
		padding: 16px 5em;
	}
}

/************************ new services design ****************************/
.view-package-wrapper {
	background-color: #fff;

	.service-title {
		font-size: 22px;
		font-weight: 700;
		line-height: 41.23px;
	}

	.location,
	.duration {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 400;
		line-height: 33px;
		gap: 4px;
		color: #707170;

		svg path {
			stroke: #707170;
			;
		}
	}

	.book-price {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px;
		border-radius: 4px;
		border: 1px solid rgba(var(--web-page-primary-color), .3);
		gap: 32px;

		span {
			font-weight: 400;
			font-size: 16px;
			line-height: 17px;
			color: #050001;
		}

		.min-price {
			font-weight: 700;
			font-size: 24px;
			line-height: 25px;
		}

		.book-btn {
			padding: 4px 32px;
			font-weight: 700;
			line-height: 30px;
			background-color: rgba(var(--web-page-primary-color));
			color: #fff;
		}
	}

	.package-carousel {
		.react-multi-carousel-list {
			height: 480px;
			border-radius: 10px;
			.react-multi-carousel-track {
				height: 100%;
				background-color: #dddcdc;
			}
			.react-multiple-carousel__arrow--left,
			.react-multiple-carousel__arrow--right {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 35px !important;
				height: 35px !important;
				border-radius: 50%;
				border: 1px solid transparent;
				z-index: 2 !important;
				background-color: rgba(var(--web-page-primary-color));

				// .r-left-icon {
				// 	rotate: 180deg;
				// }

				&::before {
					content: none;
				}
			}
		}

		.react-multi-carousel-item {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.hotel-slide img {
			max-width: 100%;
			height: auto;
			// width: 100%;
			// height: 100%;
			// object-fit: cover;
		}
	}

	.public-page-header {
		padding: 20px;
		display: flex;
		justify-content: center;
		background-color: #f5f8fc;

		.page-name {
			font-size: 28px;
			font-weight: 700;
		}

		.bread-crumb {
			display: flex;
			gap: 8px;
			align-items: center;

			span {
				color: #707170;
				font-size: 18px;
			}

			& span:first-of-type {
				color: rgba(var(--web-page-primary-color));
				font-size: 18px;
				font-weight: 700;
			}
		}
	}

	.packages-navigation {
		padding: 0;
		margin: 24px 0;
		justify-content: flex-start;
		background-color: #fff;
		border-bottom: 1px solid rgba(var(--web-page-primary-color), .3);

		.item {
			padding: 12px 0;
			font-weight: 600;
			color: #050001;
			text-transform: capitalize;
			border-bottom: 2px solid transparent;
		}

		.item.active {
			color: rgba(var(--web-page-primary-color));
			border-bottom: 2px solid rgba(var(--web-page-primary-color));

			svg path {
				stroke: rgba(var(--web-page-primary-color));
			}
		}
	}


	.package-content {
		flex-basis: calc(67% - 12px);
		max-width: calc(67% - 12px);

		.package-item {
			padding: 30px 20px;
			max-width: 100%;
			border: 1px solid rgba(var(--web-page-primary-color), .3);
		}
	}

	.package-request {
		flex-basis: calc(33% - 12px);
		max-width: calc(33% - 12px);

		.request-form {
			position: relative;
			background-color: #fff;
			border: 1px solid #F7F6F6;
			border-radius: 8px;
			box-shadow: 0px 0px 14px 0px #00000005;

			&>.row {
				padding: 0 14px 16px;
			}

			.header {
				padding: 12px 20px;
				margin-bottom: 12px;
				border-radius: 8px 8px 0 0;
				font-size: 18px;
				font-weight: 700;
				color: #fff;
				background-color: rgba(var(--web-page-primary-color));
			}

			.prices {
				width: 100%;
				padding: 6px 10px;
				margin-top: 1rem;
				// background-color: rgb(245, 248, 252);
				background-color: rgba(var(--web-page-primary-color), .05);
				border-radius: 6px;
				color: rgba(var(--web-page-primary-color));

				span {
					font-weight: 600;
					font-size: 18px;
					line-height: 16px;
				}

				.total-price {
					font-weight: 700;
					font-size: 18px;
					line-height: 34px;
					color: #050001;
				}

				svg path {
					stroke: rgba(var(--web-page-primary-color));
					fill: rgba(var(--web-page-primary-color), 0.102);
				}
			}

			.submit-btn {
				padding: 8px 32px;
				border-radius: 6px;
				background-color: rgba(var(--web-page-primary-color));
			}
		}

		.required-docs {
			background-color: rgba(var(--web-page-primary-color), .05);
			border: 1px solid rgba(var(--web-page-primary-color), .3);

			svg path {
				stroke: rgba(var(--web-page-primary-color));
				;
			}
		}
	}

}

@media screen and (max-width: 767px) {
	.view-package-wrapper, .public-page-preview-services {
		.package-request .request-form {
			position: relative;
			top: 0;
		}

		.package-details-container {
			width: 100% !important;
			padding: 1rem;
		}

		.product-itinerary-title h3 {
			font-size: 1rem !important;
		}

		.itinerary-segment {
			gap: 4px !important;

			.segment-details {
				padding: 12px 6px !important;
			}
		}

		.package-content,
		.package-request {
			flex-basis: 100% !important;
			max-width: 100% !important;
		}

		.packages-navigation {
			overflow-x: auto;
			padding: 12px;
			justify-content: flex-start;
			flex-wrap: nowrap !important;
			z-index: 2 !important;
		}

		.package-view-tabs {
			overflow-x: auto;
			flex-wrap: nowrap !important;
		}

		.package-item {
			padding: 16px 12px !important;
		}

		.public-page-flight {
			padding: 16px 0; 
			.flight-type {
				font-size: 1rem !important;
			}

			.flight-details-container {
				padding: 10px 16px !important;

				.flight-details {
					margin-top: 16px !important;
				}

				.trip-details p {
					font-size: 1rem !important;
				}

				.flight-time-line {
					padding: 10px 16px !important;
				}

				.main-air-line-logo {
					width: 60px !important;
				}

				.ports p {
					font-size: 14px !important;
				}
			}
		}

		.package-view-container {
			padding: 16px 12px !important;

			.title {
				margin-top: 12px !important;
			}

			.package-view-tabs {
				gap: 16px !important;
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.view-package-wrapper {
		.package-carousel .react-multi-carousel-list {
			max-height: 340px ;
		}
	}
}

@media screen and (max-width: 1023px) {
	.view-package-wrapper {
		.public-page-flight {
			.flight-type {
				font-size: 1rem !important;
			}

			.flight-details-container {
				padding: 10px 16px !important;

				.flight-details {
					margin-top: 16px !important;
				}

				.trip-details p {
					font-size: 1rem !important;
				}

				.flight-time-line {
					padding: 10px 16px !important;
				}

				.main-air-line-logo {
					width: 60px !important;
				}

				.ports p {
					font-size: 14px !important;
					width: calc(100% - 60px);
					margin-bottom: 10px;
				}
			}
		}
		.package-item {
			padding: 16px 12px !important;
		}

		.package-view-container {
			padding: 16px 12px !important;

			.title {
				margin-top: 12px !important;
			}

			.package-view-tabs {
				gap: 16px !important;
			}
		}
		.package-details-container {
			width: 100% !important;
			padding: 1rem;
		}

		.product-itinerary-title h3 {
			font-size: 1rem !important;
		}

		.itinerary-segment {
			gap: 4px !important;

			.segment-details {
				padding: 12px 6px !important;
			}
		}
	}
}

// @media screen and (min-height: 900px) {
// 	.view-package-wrapper .request-form {
// 		position: sticky !important;
// 		top: 16px;
// 	}
// }