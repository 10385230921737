@media (min-width: 320px) and (max-width: 576px) {
	.banner-flight {
		padding-top: 4em;
		padding-bottom: 2em;
		height: auto;
		.modify {
			padding-block: 1em;
			flex-wrap: wrap;
			height: auto;
			.bg-nxt {
				width: 80%;
			}
		}
	}

	.box-types {
		 .type{
			text-align: start;
		 }
	}
.flight-modify-container{
	padding-inline: 1em;
}
	.select-flight {
		margin-block: 1em;
	}
	.flight_item {
		padding: 8px;
		.footer-toogle {
			justify-content: space-around;
			flex-wrap: wrap;
			gap: 1em;
		}
	}

	.flight-key-value {
		overflow-x: auto;
		div {
		min-width: fit-content;
    padding-inline-end: 16px;
	}}

	.flight-table-height {
		min-height: auto !important;
	}
}
