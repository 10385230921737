.cardtitle {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.titlehead {
  background-color: #EBEBEB;
  color: #0F3E5E;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
}

.titlebody {
  background-color: #fff;
  color: #0F3E5E;
  width: 20%;
  padding: 5px;
}

.cardcomapny {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #EDEDED;
  margin-bottom: 10px;
}

.comhead {
  text-align: center;
  background-color: #EBEBEB;
  border-bottom: 1px solid #EDEDED;
  padding: 5px 0px;
}

.combody {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.combody img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.itineraryBox {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  padding-left: 70px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid #ededed;

}

[dir="rtl"] .itineraryBox {
  padding: 20px;
  padding-right: 70px;

}

.boxcollapse {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

}

.closecollapse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EBEBEB;
  border: 1px solid #DFDFDF;
  border-radius: 10px;
  padding: 0px 5px;
  padding-left: 15px;
  position: relative;
  height: 90px;
}

.collapseIcon {
  position: absolute;
  left: -50px;
  background-color: #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

[dir="rtl"] .collapseIcon {
  position: absolute;
  right: -50px;
  background-color: #EBEBEB;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.itineraryBox .boxcollapse .collapseIcon::after {
  content: "";
  position: absolute;
  bottom: -36px;
  width: 150%;
  border-bottom: 2px dotted #979797;
  transform: rotate(270deg);
}


.itineraryBox .boxcollapse .opencoll::after {
  content: "";
  position: absolute;
  bottom: -90px;
  width: 160px;
  border-bottom: 2px dotted #979797;
  transform: rotate(270deg);
}

.itineraryBox .boxcollapse:last-child .collapseIcon::after {
  display: none;
}

.infoPackage {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

.stepsinfo .stepsPackage {
  background-color: #28A745;
  position: relative;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}

.stepsinfo {
  width: 186px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepsinfo .stepsPackage::before {
  content: "";
  position: absolute;
  top: 48%;
  left: -56px;
  width: 56px;
  height: 3px;
  background-color: #979797;
}

.stepsinfo .stepsPackage::after {
  content: "";
  position: absolute;
  top: 48%;
  right: -56px;
  width: 56px;
  height: 3px;
  background-color: #979797;
}

.point {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  z-index: 11;
}

[dir="rtl"] .airplaneicon {
  transform: rotate(180deg);
}

.opendcollapse {
  background-color: #fff;
  position: relative;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
  border: 1px solid #DFDFDF;
  border-radius: 0px 0px 10px 10px;
  border-top: 0px;
}

.opendcollapse .airstep {
  background-color: #F4F9FF;
}

.opendcollapse .airstep:nth-child(2)::after {
  content: "";
  position: absolute;
  left: 0;
  top: 25%;
  margin-left: 10px;
  border: 1px dashed #B1B1B1;
  height: 55%;
}

.opendcollapse .airstep:nth-child(2)::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 15%;
  margin-left: 5px;
  border: 1px solid #B1B1B1;
  padding: 5px;
  border-radius: 50%;
}

.opendcollapse .airstep:nth-child(1)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 20%;
  margin-left: 5px;
  border: 1px solid #B1B1B1;
  padding: 5px;
  border-radius: 50%;
}

.transite::before {
  content: "";
  position: absolute;
  left: 0;
  top: 47%;
  margin-left: 5px;
  border: 1px solid blue;
  padding: 5px;
  border-radius: 50%;
}

.transite {
  width: 100%;
  background-color: #fff;
}

.imgscompany img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  padding: 5px 4px;
}

.imgscompany {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.triptime {
  color: #A0A0A0;
  border-top: 1px solid #A0A0A0;
  padding-top: 5px;
}

.tripdetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 8px 5px
}

.custombox {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  th,table{
    border-top: 0px !important;
  }
  
  
}

.custombox i {
  width: 16px;
  text-align: center;
}

.headbox {
  background-color: #DFDFDF;
  color: #0F3E5E;
  padding: 15px;
}

.bodybox {
  background-color: #fff;
  padding: 15px;
}

.thstyle {
  background-color: #0F3E5E !important;
  border-radius: 10px 10px 0px 0px;
  color: #fff !important;
  font-weight: bold !important;
}


.guesthead {
  display: flex;
  align-items: center;
  gap: 10px;
}

.guestbody {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  border: 1px solid #D7A04B;
  background-color: #F2EEE7;
  border-radius: 10px;
  min-height: 80px !important;

}

.guestbody .guestrow:last-child {
  border-bottom: 0;

}

.guestnum {
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
  color: #0372BC;
  border: 1px solid #EEF0F4;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guestlabel {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guestrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  border-bottom: 2px solid #D7A04B;
}

.checkoutBox {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.add-traveler-Modal{
  .modal-content{
    border-radius: 8px;
  }
  .handle-traveler{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    input{
      border: 1px solid rgb(208, 208, 208);
      border-radius: 8px;
      width: 48px;
      height: 48px;
      margin-inline:16px;
      text-align: center;
    }
    .increment-decrement{
      border: 1px solid #D7A04B;
      border-radius: 50%;
      width: 48px;
      height: 48px;
      background-color: white;
    }

  }
  .save-button{
    width: 100%;
    background-color: #062840;
    border-radius: 8px;
    margin-top: 16px;
    border: none;
    color: white;
    padding: 8px;
    font-weight: 600;
  }
}
.add-travelers-icon{
  color: #D7A04B;

  i{
    margin-inline-end: 4px;
  }
  &:hover{
    cursor: pointer;
  }
}
.btncheckout {
  background-color: #28A745 !important;
  color: #fff !important;
  padding: 12px 100px !important;
}
.btncheckout:disabled {
  background-color: #28a7466a !important;
  color: #fff !important;
  padding: 12px 100px !important;
  cursor:not-allowed;
}
.search_field {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-basis: calc(33.3% - 1rem);
  max-width: calc(33.3% - 1rem);
  height: 72px;
  margin: 10px 0px;
  padding: 1rem 0 0;
  padding-bottom: 0.5rem;
  padding-inline-start: 0;
  background-color: #f9f9fa;
  border: 1px solid #eef0f4;
  border-radius: 8px;
}

.search_field_half {
  flex-basis: calc(22.65% - 1rem);
  max-width: calc(22.65% - 1rem);
  margin: 10px 0px;
  height: 72px;
}


.search_field label {
  display: flex;
  align-items: flex-start;
  padding: 0 0.9rem;
  font-size: 0.7rem;
  font-weight: 700;
}

.nightbox {
  border-left: 1px solid #DFDFDF;
  border-right: 1px solid #DFDFDF;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 10px;
  color: #979797;
}

.movebox {
  width: 35%;
  display: flex;
}

.listicon li .material-symbols-outlined {
  color: #28A745;
}

@media (min-width:320px) and (max-width:768px) {
  .cardtitle {
    flex-direction: column;
  }

  .titlehead {
    width: 100%;
  }

  .titlebody {
    width: 100%;
    text-align: center;
  }

  .cardcomapny {
    width: 49%;
  }

  .itineraryBox {
    padding: 10px;
    margin-bottom: 10px;
  }

  [dir="rtl"] .itineraryBox {
    padding: 10px;
    margin-bottom: 10px;
  }

  .collapseIcon {
    background-color: #fff;
    position: relative;
    left: 0px;

  }

  [dir="rtl"] .collapseIcon {
    background-color: #fff;
    position: relative;
    right: 0px;

  }

  .itineraryBox .boxcollapse .collapseIcon::after {
    display: none;
  }

  .itineraryBox .boxcollapse .collapseIcon::before {
    display: none;
  }

  .guesthead {
    gap: 0px;
  }

  .search_field,
  .search_field_half {
    flex-basis: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    margin-bottom: 0.5rem;
  }

  .guestbody {
    padding: 0px;
  }

  .guestrow {
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
  }

  .guestlabel {
    width: 100%;
  }

  .checkoutBox {
    flex-wrap: wrap;
    justify-content: center;
  }

  .btncheckout {
    width: 100% !important;
    padding: 10px 20px !important;
  }

  .tripdetails {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    position: relative;
  }

  .tripdetails::before {
    content: "";
    position: absolute;
    left: 5%;
    border: 1px dashed #B1B1B1;
    height: 100px;
  }

}


.booking_summary,
.package_summary {
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

}

.packages_details .label {
  display: flex;
  justify-content: space-between;
  padding: 10px !important;
}

.packages_details .label:first-of-type {
  width: 66%;
  background-color: #EBEBEB;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  margin: 1.6rem 0;
}

.tab_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-width: 120px;
  width: 120px;
  padding: 0.6rem .2rem;
  color: #b1b1b1;
  background-color: #fff;
  border: 1px solid #e0e1e3;
  border-radius: 10px;
  font-weight: 300;
  user-select: none;
  cursor: pointer !important;
}

.tab_item_active {
  background-color: var(--navy-600);
  color: #fff;
  font-weight: 500;
  border-color: transparent;
}

.tab_item:not(.tab_item_active) {
  cursor: default;
}

/****Start Packages*****/
.packages_details .services_included .services_header {
  margin-top: 15px;
  background-color: #F3F3F3;
  border: #EDEDED;
  color: #0F3E5E;
  padding: 15px 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 600;
  font-size: 15px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

}

.packages_details .services_included .services_content {
  background-color: #fff;
  border: 1px solid #EDEDED;
  padding: 5px 8px;
  border-radius: 0 0 3px 3px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.packages_details .services_included .services_content p {
  margin-bottom: 5px !important;
}

.packages_details .services_included .services_content i {
  margin: 0 8px !important;
  font-size: 14px
}

.packages_details .services_included .services_content .required_documents_content p {
  margin-bottom: 2px !important;
}

.package_summary_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.package_summary_img img {
  border-radius: 50%;
}

.package_summary span {
  font-size: 13px;
  font-weight: 600;
}

.package_itinerary {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #EDEDED;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);


}

.package_itinerary .itinerary_air_collapse {
  background-color: #EBEBEB;
  padding: 5px 20px;
  border: 1px solid #DFDFDF;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 7px;

}

.itinerary_air_collapse div {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: normal;
}

.itinerary_air_collapse p {
  color: #0F3E5E;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}

.itinerary_air_collapse .product_arrival {
  flex-direction: row;
  width: 120px;
  justify-content: space-between
}

.itinerary_air_collapse .product_arrival .circle {
  color: gray;
  position: relative;
  font-size: 10px;
  margin-top: 8px;
}

.itinerary_air_collapse .product_arrival .transitons_count {
  position: relative;
  background-color: rgb(40 167 69);
  padding: 2px 4px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 1.8;
  color: white;
  z-index: 2 !important;
}

.itinerary_air_collapse .product_arrival .transitons_count::after {
  content: "";
  position: absolute;
  top: 14px;
  left: 40px;
  width: 27px;
  height: 1px;
  background-color: gray;
  z-index: -1 !important;
}

.itinerary_air_collapse .product_arrival .transitons_count::before {
  content: "";
  position: absolute;
  top: 14px;
  right: 42px;
  width: 27px;
  height: 1px;
  background-color: gray;
  z-index: -1 !important;
}

.itinerary_air_collapse_content {
  background-color: #fff;
  border: 1px solid #DFDFDF;
  border-top: 0;
  padding: 20px;
  border-radius: 0 0 7px 7px;
  margin-bottom: 25px;
}

.itinerary_air_collapse_content .collapse_header {
  font-size: 14px;
  font-size: 500;
}

.itinerary_air_collapse_content .collapse_header img {
  border-radius: 50%;
  margin-inline: 5px;
}

.itinerary_air_collapse_content .collapse_data {
  display: flex;
  width: 78%;
  margin: auto;
  justify-content: space-between;
}

.itinerary_air_collapse_content .collapse_data div {
  position: relative;
}

/* .itinerary_air_collapse_content .collapse_data div:first-child:after {
  content: "2h 10m";
  position: absolute;
  top: 14px;
  left: 240px;
  width: 50px;
  height: 1px;
  background-color: #A9A9A9;
  font-size: 12px;
  text-align: center;
  color: #A0A0A0;
  line-height: 3.8;
} */

.collapse_inner_return {
  display: flex;
  align-items: center;
}

.collapse_inner_return .collapse_data div:first-child:after {
  content: "2h 10m";
  position: absolute;
  top: 14px;
  left: 225px;
  width: 50px;
  height: 1px;
  background-color: #A9A9A9;
  font-size: 12px;
  text-align: center;
  color: #A0A0A0;
  line-height: 3.8;
}

.itinerary_air_collapse_content .collapse_data p {
  font-size: 15px;
  color: #0F3E5E;
  margin-bottom: 0;
}

.itinerary_air_collapse_content .collapse_data span {
  font-size: 14px;
}

.itinerary_image {
  background: #EBEBEB;
  margin: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: none;
}

.itinerary_Collapse,
.hotel_collapse,
.collapse_inner_circle {
  position: relative;
}

.itinerary_Collapse::after,
.hotel_collapse::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 25px;
  height: 100%;
  border: 1px dashed #EDEDED !important;
  z-index: 0;
}

.collapse_inner_circle {
  margin: 0px 12px;
}

.collapse_inner_return:not(:last-of-type) .collapse_inner_circle:after {
  content: "";
  position: absolute;
  top: 16px;
  left: 4px;
  height: 200%;
  border: 1px dashed #EDEDED !important;
  z-index: 0;
}

.collapse_inner_circle i {
  color: #EDEDED;
  font-size: 10px;
}

.hotel_collapse .hotel_collapse_cloud {
  padding: 0 20px;
  position: relative;
}

.hotel_collapse .hotel_collapse_cloud::after {
  content: "";
  position: absolute;
  top: -17px;
  left: -6px;
  width: 1px;
  height: 64px;
  background-color: #DFDFDF;
  box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%)
}

.hotel_collapse .hotel_collapse_cloud::before {
  content: "";
  position: absolute;
  top: -17px;
  right: 125px;
  width: 1px;
  height: 64px;
  background-color: #DFDFDF;
  box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%)
}
.hotelName {
  font-size: 20px !important;
}
/**** End Packages******/
@media(min-width: 320px)and (max-width: 480px) {
  .label {
    display: block;
  }

  .hotelName {
    font-size: 16px !important;
  }

  .changebtn {
    font-size: 12px !important;
  }

  .cardimg {
    width: 80px;
    height: 80px;
  }

  .underline {
    font-size: 10px !important;
  }
}

/***** Start Responsive Packages *******/
@media(min-width: 320px)and (max-width: 480px) {
  .itinerary_air_collapse {
    padding: 2px !important;
  }

  .packages_details .label {
    width: 100% !important;
  }

  .itinerary_header_mobile {
    display: flex !important;
    flex-direction: row !important;
  }

  .itinerary_header_mobile .itinerary_image {
    background-color: #fff;
  }

  .itinerary_header_mobile p {
    display: flex;
    align-items: center;
  }

  .itinerary_air_collapse_content .collapse_data {
    display: flex;
    flex-direction: column !important;
  }

  .itinerary_air_collapse_content .collapse_data div {
    margin-bottom: 10px !important;
  }

  .itinerary_air_collapse .itinerary_header_hotel_mobile,
  .itinerary_air_collapse div {
    flex-direction: row !important;
  }

  .itinerary_air_collapse .itinerary_header_hotel_mobile p {
    margin-top: 20px;
  }

  .air_data_container .collapse_header img {
    border-radius: 0 !important;
  }

  .itinerary_air_collapse_content .collapse_data div:first-child:after,
  .itinerary_Collapse::after,
  .hotel_collapse::after,
  .hotel_collapse .hotel_collapse_cloud::after,
  .hotel_collapse .hotel_collapse_cloud::before {
    content: none;
  }

  .itinerary_header_hotel_mobile {
    display: flex;
    justify-content: flex-start !important;
    flex-wrap: nowrap !important;
  }

  .itinerary_header_hotel_mobile p {
    display: inline
  }

  .hotel_collapse_cloud {
    padding: 0 20px !important;
    width: 50%;
  }

  .package_summary {
    margin: 0 10px;
  }

  .services_included_end {
    margin: 0 10px;
  }
}

/***** End Responsive Packages *******/
.room_details_container {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.room_title {
  /* flex-grow: 1 !important; */
}
.infoBox{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.roomName{
  font-weight: bold;
  margin-bottom: 0;
}
.room_Sub_title {
  color: #008128;
  margin-bottom: 0;
}

.select_room {
  /* align-items-center p-2 justify-content-between d-flex w-100 */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  background-color: #E8EAEC;

}

.room_price {
  color: #0F3E5E;
  font-weight: bold;
  margin-bottom: 0;
}

.selecet_button {
  background-color: white !important;
  margin-inline-start: 8px !important;
}

.cancelation_Policies {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cancelation_title {
  font-size: 14px;
  font-weight: bold;
}

.dates {
  font-size: 12px;
  font-weight: bold
}


.line,
.first_line {
  font-size: 13px;

}

.non_refund_color {
  color: #CE0012 !important;
  border-color: #CE0012 !important;

}
.refund_color {
  color: #0F3E5E !important;
  border-color: #0F3E5E !important;

}

.line {
  border-top: 2px solid;
  width: 50%;
  position: relative;
  text-align: center
}

/* .last_line {
  border-top: 2px solid ;
  width: 50%;
  position: relative;
  text-align: center;
} */


.first_line::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid ;
  border-radius: 50%;
  left: 0;
  top: -6px;
  background-color: white;
}


.first_line::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-radius: 50%;
  right: 0;
  top: -6px;
  background-color: white;
}
.next_line::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 1px solid ;
  border-radius: 50%;
  right: 0;
  top: -6px;
  background-color: white;
}


.image_gallery_item {
  height: 400px !important;
}
.image_gallery_item img {
  width: 92% !important;
  height: 94% !important;
  margin: auto;
  object-fit: cover;
  display: block;
}


.policies_field {
  padding: 10px;
  background-color: #F2EEE7;
  border-radius: 4px;
  margin: 0 10px;
}
.room_details_booking{
  background-color: #fff;
  padding: .7rem;
  border-radius: .2rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

}
.TabsBox{
  padding-inline: 24px;
  background-color: #E8EAEC;
  height: 55px;
.nav-tabs{
  height: 100%;
}
  .nav-item{
    display: inline-flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 0px 25px;
    a{
      color:rgba(0, 0, 0, 0.6);
      font-family: Almarai;
    font-weight: 500;
    font-size: 0.85rem;
    }
    a.active{
      color:#D7A04B;
      background-color: transparent;
      font-family: Almarai;
    font-weight: 500;
    font-size: 0.95rem;
    line-height: 1.25;
    text-transform: uppercase;
    }
  }
  .nav-item + a.active{
    border-bottom: 2px solid #D7A04B;

  }
 
}

/*Mobile devices */
@media(min-width: 320px )and (max-width: 480px)  { 

.MuiPaper-root,.MuiDialog-paperScrollBody{
  width: calc(100% - 15px) !important;
  margin: 0 !important;
}
.dialogHotelTabs{ 
  overflow: auto !important;
}
.policies_field_content{
  flex-direction: column;
  text-align: center;
  gap: 8px;
}
.policies_field{
  width: 100% !important;
}
.selectRoomContainer{
  width: 100%;
  justify-content: space-between;
 flex-direction: column;
}
.image_gallery_item {
  height: 220px !important;
}
.image_gallery_item img {
  width: 85% !important;
  height: 85% !important;
  margin: auto;
  object-fit: cover;
  display: block;
}
.roomName{
  font-size: 14px;
}
.room_Sub_title {
  font-size: 12px;
}
.TabsBox{
  padding-inline: 0;
}
.facilitiesName{
  font-size: 14px !important;
}
}
.dialogHotelTabs_package{
  background-color: #fff;
  padding: 0 15px;
}
@media(min-width: 481px )and (max-width: 666px)  { 
  .selectRoomContainer{
    width: 100%;
    justify-content: space-between;
   flex-direction: column;
    }
}
.text-light-success{
  color:#10A711
}
.bg-light-success{
  background-color:#10A711
}

.search_field_half-5{
  width: 100% !important;
  background-color: #008128;
}
.search_field.search_field_half{
  .control-field,.custom-control-field{
    border:none !important;
  }
} 
.search_field.search_field_half:focus{
  border: 1px solid #D7A04B;
}
.ContactInfo {
  background-color: #fff;
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 10px;
}


.month-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
  width: 300px;
  height: 270px;
  grid-gap: 8px;
  gap: 8px;
  padding: 8px;
  background-color: #ffffff;

  div {

    display: flex;
    justify-content: center;
    align-items: center;
    color: #0000005a;
  }
  .active {
    color: black;
  }
  .selected-day{
    position: relative;
    &::after{
      content: "";
      border-radius: 50%;
      height: 5px;
      width: 5px;
      background-color: #ffa71a;
      position: absolute;
      bottom: 0px;
    }
  }
}


.child-dropdown {
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:focus, &:active{
    box-shadow: none;
    border-radius: 0px;
    border: none;
  } 
  span{
    font-size: 14px;
    color: black;
    text-align: start;
  }
  i{
    font-size: 16px;
    text-align: end;
  }
  span, i{
    flex: 1 1 auto;
    padding-inline: 12px !important;

  }
}

.dropdown-hover{
  &:hover{
    box-shadow: none;
    background-color: #eeeeee;
  }
}

.dropdown_item{
  &:active{
    background-color: transparent !important;
  }
}

.dropdown-menu-arrow {
  &::before{

    content: "";
    position: relative;
    display: block;
    inset-inline-start: -10px;
    inset-block-start: 12px;
    width: 0;
    border-top: 10px solid transparent;
    border-inline-end: 10px solid #9e9e9e;
    border-bottom: 10px solid transparent;
  }
}

.toggle-parent{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-radius: 4px !important;
  font-size: 14px !important;
  white-space: nowrap;
  max-width: 100%;
  i {
    font-size: 14px;
  }

  span{
    text-overflow: ellipsis;
overflow: hidden;
padding-block: 2px;
  }
}