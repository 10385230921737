:root {
	--border-color: #f7f6f6;
	--primary-color: #d4a655;
	--red-color: 233, 107, 107;
	--yellow-color: 248, 235, 111;
	--green-color: 127, 240, 179;
	--blue-color: 30, 133, 255;
	--purple-color: 199, 107, 242;
	--text-primary-color: #050001;
	--text-secondary-color: #707170;
	--web-page-primary-color: var(--blue-color);
	--web-page-border-raduis: 6px;
	--web-page-font-family: 'Almarai', sans-serif;
	--sections-block-padding: 80px
}
