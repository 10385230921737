@import "base/variables";

.transfer-list {
	.transfer-wallet {
		bottom: ($var-spacer * -1);
		inset-inline-end: ($var-spacer * -1);
	}
	.transfer-cart {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: #d29d4d;
		width: 67px;
		height: 67px;
		position: fixed;
		inset-inline-end: 20px;
		inset-block-end: 20px;
	}
	.badge {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		position: absolute;
		background-color: #c30101;
		color: white;
		width: 24px;
		height: 24px;
		top: -6px;
		inset-inline-end: 7px;
		font-size: 14px;
	}
}

.add-to-cart {
	text-align: start;
}
.transfer-cart-model {
	text-align: start;
	.bin {
		border-start-end-radius: 5px !important;
		border-end-end-radius: 5px !important;
		background-color: #eaeaea;
		display: flex;
		align-items: center;
		max-width: 30px;
	}
	.total-cost {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
		padding: 0.5rem;
		background-color: #cbcbcb;
	}
	.continue-btn {
		background-color: #fff;
		border: 1px solid #cb9a51;
		color: #cb9a51;
		font-size: 16px;
	}
}

@media screen and (max-width: 992px) {
	.transfer-cart-model {
		.bin {
			max-width: 100%;
			width: 100%;
			margin-top: 16px !important;
			border-radius: 0 0 4px 4px !important;
		}
	}
}
