.theme-7 {
  .home-page-transfer-section {
    --image-offset: 60px;
    padding-block: var(--sections-block-padding);

    &__content {
      display: flex;
      justify-content: space-between;
      gap: 2.6rem;
      flex-wrap: wrap;
    }

    &__image-wrapper {
      padding-inline-end: var(--image-offset);
      width: auto;
      height: 450px;
      flex: 1;
      max-width: 500px;
      min-width: 100%;
      margin-inline: auto;
      
      @media screen and (min-width: 768px) {
        min-width: 572px;
      }

      div {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 183, 172, 1);
        border-bottom-right-radius: 20px;
        position: relative;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        inset-block-start: calc(var(--image-offset) * -1);
        inset-inline-end: calc(var(--image-offset) * -1);
        object-fit: cover;
      }
    }

    &__details {
      flex: 1;
      min-width: 100%;
      
      @media screen and (min-width: 768px) {
        min-width: 400px;
        margin-top: calc((var(--image-offset) / 2) * -1);
      }
    }

    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2;
      margin-bottom: 0.4rem;
    }

    &__description {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: rgba(112, 113, 112, 1);
    }

    &__cards-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .home-page-transfer-card {
      width: 100%;        
      background-color: white;
      padding: 1rem;
      border-radius: 0.8rem;
      border: 1px solid rgba(229, 229, 229, 1);
      display: flex;
      gap: 1rem;
      align-items: center;

      &__image {
        max-width: 230px;
        width: 230px;
        height: 140px;
        img {
          width: 100%;
          border-radius: 8px;
          object-fit: cover;
          -webkit-user-select: none;
          user-select: none;
          height: 100%;
        }
      }

      &__content {
        flex-grow: 1;
        flex-shrink: 0;
      }

      &__details {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
      }

      &__tag {
        padding: 0.25rem 1.7rem;
        background-color: rgba(117, 53, 114, 1);
        color: white;
        width: fit-content;
        border-radius: 90px;
        text-transform: capitalize;
      }

      &__title {
        font-size: 1.25rem;
        font-weight: 700;
        color: black;
      }

      &__body {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        p {
          font-weight: 500;
          color: rgba(112, 113, 112, 1);
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        svg {
          --size: 21px;
          width: var(--size);
          height: var(--size);

          path {
            stroke: rgba(112, 113, 112, 1);
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      &__cards-list {
        flex-direction: row;
        flex-wrap: nowrap;
        width: calc(100vw - 16px);
        overflow-x: auto;
        margin-inline-start: -25px;
        padding-inline: 24px;

        li {
          max-width: fit-content;
          width: fit-content;
          display: inline-block;
        }
      }

      .home-page-transfer-card {
        flex-direction: column;
        height: 100%;
        width: max-content;

        &__content {
          width: 100%;
        }
      }
    }
  }
}
