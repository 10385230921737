.b2c-invoice-wrapper {
	.show-button {
		display: flex;
		justify-content: center;
		button {
			outline: none;
			box-shadow: none;
			transition: all 0.3s ease-in-out;
			&:hover {
				background: $goldColor;
				color: $white;
			}
		}
	}
	.hotel-detail-section {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		border: 1px solid #d1d1d1;
		padding: 15px;
		margin: 5px 0 !important ;
		min-height: 180px;
		@media (max-width: 575px) {
			display: block;
			text-align: center;
		}

		.img-box {
			min-height: 150px;
			width: 200px;
			height: 150px;
			//border: 1px solid #c6cbd0;

			@media (max-width: 575px) {
				width: 100%;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
		.total-price-box {
			width: 180px;
			background: #f9f9f9;
			border: 1px solid #eee;
			height: 130px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin: auto;
			@media (max-width: 575px) {
				width: 100%;
			}
			> h6 {
				color: #d5d5d5;
				font-weight: bold;
			}
		}
		.details-button {
			width: 180px;
			color: $white;
			margin-top: 0.2rem;
			border-radius: 0;
			font-weight: 700;
			font-size: $text-caption;
			@media (max-width: 575px) {
				width: 100%;
			}
			&:hover {
				color: $white;
				background: #4a6572 !important;
			}
			a {
				&:hover {
					color: $white !important;
				}
			}
		}
	} //hotel-detail-section
}
.box-container {
	background-color: #ffffff;
	padding: 12px 12px !important;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.11);
}
.hotel-title {
	font-size: 16px;
	color: #0c3b5c;
	font-weight: 500;
}
.hotel-title2 {
	span {
		font-size: 15px;
		white-space: nowrap;
	}
	.link a {
		color: #cb9a51;
		text-decoration: none !important;
	}
}
.hotel-titleHotel {
	font-size: 16px !important;
	color: #0c3b5c !important;
	font-weight: 700 !important;
}
.titleHotelDescription {
	font-size: 15px !important;
	margin: 0 !important;
	color: #4e4d4d !important;
}
