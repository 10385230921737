// Product Builder
.product-builder-wrapper {
	padding: 0px 26px;
	display: flex;
	flex-direction: column;
	gap: 24px;

	.productBuilder-title {
		font-size: 20px;
		font-weight: 600;
		color: #707170;
		line-height: 30px;
	}

  .productBuilder-title-secondary {
    font-size: 18px;
    font-weight: 700;
    color: #050001;
  }
.trip-planner-table{
  .thead-bg th:last-child{
    text-align: justify !important;
  }
}
  .hr-horizontal {
    background: #F7F6F6;
    width: 100%;
  }
  

	.productBuilder-bg-wrapper {
		background-color: #fff;
		padding: 24px 26px 24px 24px;
		border: 1px solid #f7f6f6;
		display: flex;
		flex-direction: column;
		gap: 10px;
    border-radius: 8px;
    
    .thead-bg th {
      color: #707170 !important;
      font-size: 14px !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      line-height: 24.24px !important;
      text-transform: uppercase !important;
    }

    .table-bordered th, .table-bordered td {
      vertical-align: middle !important;
    }

		.btn-addApplication {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding: 7px 20px;
			background-color: #d4a655;
			font-size: 16px;
			font-weight: 700;
			color: #fff;
      border-radius: 4px;
		}

    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 70%;

      .filter-inputs {
        flex: 1;

        .control-field__label {
          font-size: 14px;
          font-weight: 400;
          color: #707170;
          line-height: 26.4px;
        }

        .filter-clear {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          padding: 12px 16px;
          border-radius: 6px;
          background: #FAF4EB;
          border: 1px solid #EED9BA;
          color: #6A4D1B;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .filter-inputs:first-child {
        flex: 2;
      }

      .filter-inputs:last-child {
        margin-top: 1.5rem;
      }
    }
	}
}

.actions-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 12px 0px;
  top: 30px;
  inset-inline-end: 0;
  border-radius: 8px;
  border: 1px solid #e6e5e5;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.03);
  z-index: 3;
  // display: none;
  max-height: 20rem;
  overflow-y: auto;
  z-index: 10;
  // opacity: 0;
  // visibility: hidden;
  // transform: translateY(-10px);
  // transition: opacity .5s ease, visibility .5s ease, transform .5s ease;

  &.show {
    display: flex;
    // transform: translateY(0);
    // opacity: 1;
    // visibility: visible;
    // transition: opacity .5s ease, visibility .5s ease, transform .5s ease;
  }

  .item {
    padding: 4px 16px;
    font-size: 16px;
    font-weight: 400;
    gap:10px;
    color: #050001;
    &.stroke svg path {
      stroke: #292d32;
    }
  }

  h5 {
    padding: 8px 16px;
    font-size: 12px;
    color: #777;
    font-weight: 500;
    text-transform: uppercase;
  }
  hr {
    margin: 8px 0;
  }
}

@media screen and (max-width: 1126px) {
  .product-builder-wrapper .productBuilder-bg-wrapper .filter-wrapper {
    width: 100%;
    flex-wrap: wrap;
    .product-builder-wrapper .productBuilder-bg-wrapper .filter-wrapper .filter-inputs {
      flex-basis: 33.333%;
    }
  }
}