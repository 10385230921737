.bg-layout {
	background: transparent linear-gradient(180deg, #ffffff 0%, #ebebeb 100%) 0%
		0% no-repeat padding-box;
	min-height: 100vh;
}

.text-orange {
	color: #cb9a51;
	font-weight: 700;
}

.bg-next {
	background-color: #d5d5d5;
	color: #9e9e9e;
	font-size: 16px;
	text-transform: uppercase;
}

.bg-bk {
	background-color: #979797;
	color: white;
	font-size: 16px;
	text-transform: uppercase;
}

.bg-back {
	color: #0c3b5c;
	font-size: 16px;
}
.w-95 {
	width: 95%;
}
.k-market-search-container .bg-nxt {
	padding: 0.8rem;
}
// changing this class proprties will apply on all buttons in the app so be careful
.bg-nxt {
	background: rgba(var(--web-page-primary-color));
	//background: rgb(222,162,55);
	color: #ffffff;
	font-size: 16px;
	border: 0;
}

.bg-nxt:hover {
	//background-color: #bf8d42;
	background-color: rgba(var(--web-page-primary-color));
	color: #ffffff;
}
.bg-succ {
	background:#00b437 !important ;
	//background: rgb(222,162,55);
	color: #ffffff;
	font-size: 16px;
	border: 0;
}
.bg-succ:hover{
	background:#00b436f1 !important ;
	color: #ffffff;
}
.bg-bk:hover {
	background-color: #6b6b6b;
	color: #ffffff;
}

.progress-bar {
	background-color: #0c3b5c;
}

html[dir="ltr"] .image-uploader__plus-btn {
	right: 0;
	color: #fe6c72;
	font-size: 30px;
}
html[dir="rtl"] .image-uploader__plus-btn {
	left: 0;
	color: #fe6c72;
	font-size: 16px;
}
.img-holder {
	width: 10.25rem;
	height: 10.25rem;
	border-radius: 180px;
	vertical-align: middle;
	border: 1px solid #eae8e9;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f2f2f2;
	overflow: hidden;
}
.img-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.img-icon {
	border-radius: 200px;
	display: block;
	margin: 0 auto;
	width: 3rem;
	height: 10rem;
}
.text-underline {
	text-decoration: underline;
}

.log-first {
	height: 40vh !important;
	font-size: 20px;
}

.make-text-hight {
	line-height: 10px;
}
.letter-logo {
	width: 30px;
	height: 30px;
	font-size: 16px;
	font-weight: 600;
	color: #000;
	background-color: transparent;
	border: 1px solid #EFEFEF;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-transform: capitalize;
}

.number-icon {
	font-size: 17px;
	cursor: pointer;
}
.number-h {
	height: 44px !important;
}
