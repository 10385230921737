@import "./variables.scss";

.truncate-price,
.truncate {
  overflow: hidden;
  max-width: 14ch;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mirror-ltr {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

html {
  &:dir(rtl) {
    .mirror-rtl {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    .mirror-ltr {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  .custom-container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    /* Adjust padding as needed */
  }
}

.web-builder-hero-header {
  position: relative;
  z-index: 9;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: #fff;

    .contact-button {
      border: none;
      border-radius: var(--web-page-border-raduis);
      background-color: rgba(var(--web-page-primary-color));
      color: white;
      padding: 8px 32px;
      text-wrap: nowrap;
    }
    .call-us svg path {
      stroke: rgb(var(--web-page-primary-color));
    }

    .logo img {
      height: 60px;
      // width: 160px;
      cursor: pointer;
    }

    .logo {
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .nav-container {
      display: flex;
      gap: 42px;
    }

    nav {
      display: flex;
      align-items: center;
    }

    nav ul {
      display: flex;
      gap: 16px;
      align-items: center;

      li {
        color: rgba(var(--web-page-font-color)) !important;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        text-wrap: nowrap;
      }
    }

    .nav-mobile-icon,
    .close-nav {
      display: none;
    }
  }

  .service-dropdown {
    font-size: 16px;
    font-weight: 600;
    background-color: initial !important;
    border-color: initial !important;
    color: rgba(var(--web-page-font-color)) !important;
    padding: 0;

    &:focus,
    &:focus-visible {
      box-shadow: none !important;
    }
  }

  .more-dropdown {
    position: absolute;
    top: 0;
    right: 0;
    left: unset !important;
    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: transparent !important;
      border-color: transparent !important;
      color: #000000;
      padding: 0.5rem 1rem;
      font-size: 16px;
      box-shadow: none !important;
      text-align: start;
      gap: 16px;

      &:active {
        color: #000000;
      }
      &::after {
        transform: rotate(-90deg);
        margin-top: 2px;
        margin-inline-start: 4px;
      }
      [dir="rtl"] &::after {
        transform: rotate(90deg);
      }
    }
  }

  .more-subnav-dropdown {
    top: -11px !important;
    right: unset !important;
    left: 100% !important;
    transform: unset !important;

    [dir="rtl"] & {
      right: 100% !important;
      left: unset !important;
    }
  }

  .dropdown-item {
    color: rgba(var(--web-page-font-color)) !important;
  }

  .contact-phones {
    position: relative;

    &:hover .phone-list {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: transform 0.35s ease;
    }

    .phone-list {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      padding: 16px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.08);
      gap: 16px;
      transform: translateY(-2px);
      transition: transform 0.35s ease;
    }
    a {
      color: rgb(var(--web-page-primary-color));
    }
  }
}

.wb-hero {
  position: relative;
  padding-bottom: 0 !important;
  padding-bottom: 4em;
  height: 600px;

  .hero-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000082;
      z-index: 1;
    }
  }

  // for non slider templates
  .hero-bg {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .react-multi-carousel-list,
  .react-multi-carousel-track {
    height: 100%;
  }

  .react-multi-carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .react-multiple-carousel__arrow {
    background-color: rgba(var(--web-page-primary-color));
    z-index: 3 !important;

    // .r-left-icon {
    //   rotate: 180deg;
    // }

    &::before {
      content: none;
    }
  }

  .hero-content-container {
    display: grid;
    position: absolute;
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    width: 100%;
    padding: 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    grid-gap: 4px;
    gap: 4px;
    text-align: center;
    z-index: 2;

    .header {
      color: #fff;
      background-color: transparent;
      border: none;
    }

    .body {
      color: white;
    }
  }

  .react-multi-carousel-dot-list {
    bottom: 20px;
    z-index: 2;

    button {
      width: 16px;
      height: 16px;
      border: 3px solid #fff;
      background-color: #fff;
    }

    .react-multi-carousel-dot--active button {
      background-color: rgba(var(--web-page-primary-color));
    }
  }

  .update-hero-layout {
    display: flex;
    position: absolute;
    background-color: #fff;
    width: max-content;
    border-radius: 4px;
    padding: 5px 8px;
    left: 15px;
    bottom: 15px;
    z-index: 2;

    .hero-content-layout {
      background-color: #fff;
      border: none;
    }
  }
}

.web-builder-content {
  padding: 2em 1em;

  // background-color: #fbfbfa;
  & * {
    font-family: var(--web-page-font-family) !important;
  }

  .fa,
  .far,
  .fas {
    font-family: "Font Awesome 5 Free" !important;
  }

  /////////////// common Styles Start /////////////////
  .service-card-details-name {
    padding: 12px;
    background-color: white;
    width: 100%;
    border-radius: 8px;
  }

  .web-builder-filled-btn {
    display: flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: rgba(var(--web-page-primary-color));
    color: #fefeff;
    font-size: 16px;
    font-weight: 700;
  }

  /////////////// common Styles end /////////////////

  /////////////// services content //////////////
  .services-content {
    .service-header {
      // width: max-content;
      font-weight: 700;
      color: rgba(var(--web-page-font-color));
      font-size: 28px;
    }

    .service-body {
      // margin-top: 18px;
      color: #707170;
      font-size: 16px;
      font-weight: 500;
      // max-width: 80%;
      // width: fit-content;
      // margin: 0 auto;
    }
  }

  ////////////////////// Start Hero //////////////////

  .web-builder-hero {
    position: relative;
    padding-bottom: 0 !important;
    background-position: center;
    padding-bottom: 4em;
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      &.has-api-integration {
        min-height: 850px;
      }
    }
    .main-content-right {
      justify-content: flex-start;
    }
    .update-hero-layout {
      bottom: 15px;
    }

    .hero-content-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      // padding: 2rem;
      .edit-box-actions-parent {
        position: absolute;
        left: 50%;
      }
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #00000082;
        z-index: 1;
      }
    }

    .edit-box-container {
      flex-grow: 0 !important;
    }

    .react-multi-carousel-list,
    .react-multi-carousel-track {
      height: 100%;
    }

    .react-multi-carousel-item img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .react-multiple-carousel__arrow {
      background-color: rgba(var(--web-page-primary-color));
      z-index: 3 !important;

      // .r-left-icon {
      //   rotate: 180deg;
      // }

      &::before {
        content: none;
      }
    }

    .web-builder-hero-content {
      // display: grid;
      grid-template-columns: repeat(1, auto);
      // justify-content: space-between;
      justify-content: center;
      // gap: 24px;
      // padding: 3em 5em;

      .header {
        color: #fff;
        text-align: center;
        // line-height: 67.5px;
      }

      .body {
        color: white;
        text-align: center;
      }
    }

    &:has(.content-layout-2, .content-layout-3) {
      li,
      .service-dropdown {
        color: var(--text-secondary-color) !important;
      }
    }

    .content-layout-2 {
      grid-template-columns: repeat(2, auto);

      .body {
        color: var(--text-secondary-color);
      }
    }

    .content-layout-3 {
      grid-template-columns: repeat(1, auto);

      .hero-content {
        grid-row-start: 1;
      }

      .body {
        color: var(--text-secondary-color);
      }
    }

    .content-layout-theme-1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      gap: 4px;
      // text-align: center;
      z-index: 2;
      max-width: 100%;
      width: 800px;
    }

    .content-layout-theme-2 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      text-align: start;
      max-width: 700px;
      padding-inline-start: 10rem;

      .search-form-web-builder {
        background-color: #ffffff80;
        border-radius: 10px;
        padding: 10px;
      }
    }

    .react-multi-carousel-dot-list {
      bottom: 12px;
      z-index: 2;

      button {
        width: 16px;
        height: 16px;
        border: 3px solid #fff;
        background-color: #fff;
      }

      .react-multi-carousel-dot--active button {
        background-color: rgba(var(--web-page-primary-color));
      }
    }

    .update-hero-layout {
      display: flex;
      background-color: white;
      width: max-content;
      border-radius: 4px;
      padding: 5px 8px;
      position: relative;
      // bottom: -43px;
      left: 20px;
      z-index: 2;

      .upload-file-container {
        border-inline-start: 1px solid rgb(193, 193, 193);
        padding-inline-start: 8px;
        margin-inline-start: 6px;

        label {
          display: flex;
          align-items: center;
          margin: 0;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .hero-content-layout {
        background-color: white;
        border: none;
      }
    }
  }

  .has-api-integration {
    height: auto;
    min-height: 600px;
    justify-content: center;

    .hero-content-container {
      position: static !important;
      transform: unset !important;
      justify-content: center;
      align-items: center;
      .button-collapse,
      .form-group {
        text-align: start;
      }
    }

    .hero-slider-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .web-builder-hero-content {
      position: relative;
      top: unset;
      transform: unset;
      // max-width: 900px;
      padding-block: 2rem;
      top: unset;
      left: unset;
    }

    .react-multi-carousel-item {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000082;
        z-index: 1;
      }
    }
  }

  .web-builder-content {
    .web-builder-hero .slider-web-builder-hero {
      .react-multi-carousel-list {
        button {
          background-color: rgba(var(--web-page-primary-color));
        }
      }
    }
  }

  .d-flex-language-contact {
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      stroke: rgba(var(--web-page-primary-color));
    }
  }

  .webBuilder-contact {
    p {
      color: #707170;
      font-size: 1rem;
      font-weight: 700;
      width: 120px;
    }

    .text-primary-web-builder {
      color: rgba(var(--web-page-primary-color));
    }
  }

  ////////////////////// End Hero //////////////////

  ////////////////////// Start Our Service //////////////////
  .wb-our-service {
    padding-block: var(--sections-block-padding);
    background-color: #f5f8fc;

    .services-content {
      text-align: center;
      max-width: 50%;
      margin: 0 auto;
      padding: 0 0 1.6rem;
    }
  }

  .web-builder-content-our-service {
    margin-top: 20px;
    // padding-inline: 30px;
    display: grid;
    grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
    @media screen and (max-width: 767px) {
      grid-template-columns: calc(100% / 2) calc(100% / 2);
    }
    gap: 16px;
    background-color: #f5f8fc;
    .services-content {
      grid-column-start: 1;
      grid-column-end: 3;
      text-align: center;
    }
    &_card {
      position: relative;
      height: 100%;
      .our-service-icon {
        margin-bottom: 8px;
        img {
          width: 40px;
          height: 40px;
        }
      }

      .toggle-check-box {
        text-align: end;
      }
    }

    .our-service_card-with-image {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
      min-height: 300px;
      padding: 0 0 10px;
      overflow: hidden;

      .our-service-icon {
        position: relative;
        height: 200px;

        label {
          width: 100%;
          height: 100%;
          margin-bottom: 0 !important;
          transition: all 0.5s ease-in-out;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 41.38%,
            rgba(0, 0, 0, 0.9) 100%
          );
          bottom: 0;
        }

        .add-element-box {
          position: relative;
          z-index: 2;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 0 0;
          object-fit: cover;
        }

        .img-data {
          position: absolute;
          bottom: 16px;
          inset-inline-start: 16px;
          z-index: 2;

          [dir="rtl"] & {
            left: unset;
            right: 16px;
          }

          .service-body {
            color: #fbfbfa;
            font-size: 16px;
            font-weight: 500;
          }

          .service-header {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
          }
        }

        .upload-file-container {
          height: 100%;
          background: rgb(200, 199, 199);
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
        }

        .upload-file-container {
          border-radius: 10px 10px 0 0;
        }
      }

      .toggle-check-box {
        margin-inline: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .service-content {
        padding: 16px;
        color: #707170;
        font-size: 16px;
        font-weight: 500;
      }

      .add-element-box {
        background-color: rgba(112, 113, 112, 0.8);
        min-height: 200px;
        width: 100%;
        border-radius: 10px 10px 0 0;
        justify-content: center;
      }
    }

    .our-service_card-with-icon {
      background-color: #fff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;

      // gap: 10px;
      .our-service-icon {
        background-color: rgba(var(--web-page-primary-color), 0.16);
        display: flex;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        svg,
        path {
          stroke: rgba(var(--web-page-primary-color)) !important;
        }

        svg {
          width: 50%;
          height: 50%;
        }
      }

      .service-body {
        margin-top: 10px;
        color: rgba(var(--web-page-primary-color));
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
      }

      .service-header {
        color: rgba(var(--web-page-font-color));
        font-size: 20px;
        font-weight: 700;
      }

      .service-content {
        color: #707170;
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0;
      }

      .toggle-check-box {
        margin-inline: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .soon {
      position: absolute;
      right: 0;
      top: 0;
      padding: 3px 10px;
      background-color: rgba(var(--web-page-primary-color));
      color: white;
      border-radius: var(--web-page-border-raduis);
    }
  }

  .all-web-builder-our-service-carousel {
    // padding-block: var(--sections-block-padding);
    position: relative;

    .package-card-container {
      height: 100%;
    }

    .react-multiple-carousel__arrow {
      background: rgba(var(--web-page-primary-color));
      min-width: 35px !important;
      min-height: 35px !important;
      border-radius: 50%;
      z-index: 2 !important;
    }

    .react-multiple-carousel__arrow--left {
      left: calc(0% + -30px);
      top: 38%;
      z-index: 2 !important;

      &::before {
        content: "" !important;
      }
    }

    .react-multiple-carousel__arrow--right {
      right: calc(0% + -30px);
      top: 38%;
      z-index: 2 !important;

      &::before {
        content: "" !important;
      }
    }

    // .r-left-icon {
    //   rotate: 180deg;
    // }

    .react-multi-carousel-track {
      align-items: stretch;
    }

    .service-slide {
      .our-service_card-with-image {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
        // min-height: 290px;
        overflow: hidden;

        .toggle-check-box {
          margin-inline: 15px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .service-content {
          padding: 16px;
          color: #707170;
          font-size: 16px;
          font-weight: 500;
        }

        .add-element-box {
          background-color: gray;
          min-height: 200px;
          border-radius: 10px 10px 0 0;
          width: 100%;
          justify-content: center;

          svg rect {
            fill: #fff !important;
          }

          svg path {
            stroke: #050001 !important;
          }
        }
      }
    }

    .service-slide {
      padding-inline-end: 16px;
    }

    // .service-slide:not(:first-child) {
    // 	padding: 0 16px;

    // }
  }

  ////////////////////// End Our Service //////////////////

  ////////////////////// Start About Us //////////////////
  #about-us-section {
    background-color: white;
    padding-block: var(--sections-block-padding);
  }

  .web-builder-content-about-us {
    // padding: 2em 10%;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;

    .about-us-details {
      // padding-inline-start: 2em;
      .about-us-title {
        color: rgba(var(--web-page-primary-color));
        font-weight: 700;
        line-height: 24px;
      }

      .h4 {
        font-weight: 700;
        margin: 8px 0;
      }

      .text-body {
        color: #707170 !important;
        font-weight: 500;
        font-size: 18px !important;
        white-space: pre-line;
      }
    }

    .about-media-container {
      aspect-ratio: 3/2;

      img {
        object-fit: cover;
        width: 100%;
        // height: 100%;
      }

      .add-element-box {
        width: 100%;
        height: 100%;
        justify-content: center;
      }
    }

    .about-media-image {
      aspect-ratio: unset;
    }
  }

  ////////////////////// End About Us //////////////////

  ////////////////////// Start Our Pakcages //////////////////

  .web-builder-content-our-packages {
    padding: 2em 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    background-color: white;

    .services-content {
      padding-inline: 30%;
      text-align: center;
    }

    .add-element-box {
      height: auto;
      max-width: 293px;
    }

    .package-card-container {
      position: relative;
      padding: 20px;
      background-size: cover;
      display: flex;
      min-height: 270px;
      height: 100%;

      .package-card-details {
        align-self: flex-end;
        flex: 1;
        padding: 16px;
        color: white;
        background: linear-gradient(
          180deg,
          rgba(203, 203, 203, 0.1725) 0%,
          rgba(45, 45, 45, 0.69) 44.79%,
          rgba(0, 0, 0, 0.93) 100%
        );
      }
    }
  }

  //***** with slider****/
  .web-builder-content-our-packages-with-slider {
    padding-block: var(--sections-block-padding);
    background-color: #fff;

    .our-packages-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .services-content {
        padding: 0 0 1.6rem;
        .service-header {
          color: rgba(var(--web-page-font-color));
          font-size: 28px;
          font-weight: 700;
        }

        .service-body {
          color: #707170;
          font-size: 16px;
          font-weight: 500;
        }
      }

      button {
        display: none;
        background-color: rgba(var(--web-page-primary-color));
        border-radius: 8px;
        padding: 10px 32px;
        color: #fefeff;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .add-element-box {
      margin: 25px auto;
    }

    .package-card-details {
      position: relative;
      min-height: 300px;
      height: 100%;
      border-radius: 10px;
      border: 1px solid #e2e8f0;
      background: #fff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
      // margin-bottom: 15px;
      cursor: pointer;
      overflow: hidden;

      .package-img {
        height: 240px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          // border-radius: 10px 10px 0px 0px;
          object-fit: cover;
        }

        .package-img-data {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          bottom: 20px;
          padding-inline: 10px;

          p {
            color: #fefeff;
            font-size: 18px;
            font-weight: 700;
          }

          .starts {
            display: flex;
            gap: 2px;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180.41deg,
            rgba(0, 0, 0, 0) 67.1%,
            #000000 99.65%
          );
          bottom: 0;
        }
      }

      .card-ribbon {
        position: absolute;
        top: 16px;
        left: 0;
        padding: 3px 10px;
        border-radius: 0px 6px 6px 0px;
        background-color: #fac430;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        z-index: 3;
      }

      .package-info {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 0 0 15px 15px;
        .location {
          margin-bottom: 2px;
          span {
            color: #707170;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .date {
          margin-bottom: 5px;
          span {
            color: #050001;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .hotel {
          span {
            color: #050001;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .hr {
          background-color: #e2e8f0 !important;
          margin-block: 8px;
          height: 1px;
        }

        .price {
          align-items: center;

          span {
            color: #050001;
            font-size: 16px;
            font-weight: 500;
          }

          .amount {
            color: rgba(var(--web-page-primary-color));
            font-size: 24px;
            font-weight: 700;

            .currency {
              color: rgba(var(--web-page-primary-color));
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }

    .all-web-builder-our-service-carousel {
      // padding: 14px 0px !important;

      .btn-mobile {
        display: none;
        background-color: rgba(var(--web-page-primary-color));
        border-radius: 8px;
        padding: 10px 32px;
        color: #fefeff;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  ///****** with image ****/
  .web-builder-content-our-packages-with-image {
    position: relative; // margin-top: 1rem;
    padding-block: var(--sections-block-padding);

    // padding: 40px 0;
    // margin-inline: 55px;
    &::before {
      content: "";
      position: absolute;
      width: 70%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid #f7f6f6;
    }

    .our-packages-header {
      text-align: center;
    }

    .all-web-builder-our-service-images {
      display: flex;
      flex-wrap: wrap;

      gap: 8px;

      .edit-box-container {
        height: 350px;
      }

      .our-service-item {
        height: 400px;
        flex-basis: calc(33.33% - 6px);
      }
    }

    .package-card-container {
      height: 100% !important;
      background-size: cover !important;
      position: relative;
      .package-card-details {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .package-info {
        background: linear-gradient(
          180deg,
          rgba(203, 203, 203, 0.1725) 0%,
          rgba(45, 45, 45, 0.69) 44.79%,
          rgba(0, 0, 0, 0.93) 100%
        );
        margin-top: auto;
        padding: 16px;
        .rate {
          // margin-bottom: 8px;
          .starts {
            display: flex;
            gap: 4px;
          }

          p {
            color: #fefeff;
            font-size: 18px;
            font-weight: 700;
          }
        }

        .location {
          margin-bottom: 8px;

          span {
            color: #fefeff;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .date {
          margin-bottom: 8px;

          span {
            color: #fefeff;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .hotel {
          span {
            color: #fefeff;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .price {
        position: absolute;
        top: 16px;
        inset-inline-end: 16px;
        border-radius: 4px;
        background: rgba(0, 0, 0, 0.34);
        padding: 4px 12px;
        [dir="rtl"] & {
          left: unset;
          right: 34px;
        }
        p {
          font-size: 12px;
          font-weight: 500;
          color: #fefeff;
        }

        .amount {
          font-size: 20px;
          font-weight: 700;
          color: #fefeff;
          line-height: 1.1;

          .currency {
            font-size: 16px;
            font-weight: 700;
            color: #fefeff;
          }
        }
      }
    }

    .add-element-box-packages {
      margin: 0 auto 1rem;
    }

    .our-packages-header {
      .service-header {
        margin-bottom: 18px;
      }

      .service-body {
        margin-bottom: 24px;
      }
    }
  }

  ////////////////////// End Our Pakcages //////////////////

  ////////////////////// Start Hotel  //////////////////
  .web-builder-content-hotels {
    background-color: transparent;
    padding-block: var(--sections-block-padding);

    .hotels-top-section {
      padding: 40px;
      display: flex;
      justify-content: space-between;
      text-align: center;
      gap: 75px;

      & > div {
        flex: 1;
      }

      .add-element-box {
        min-height: 140px;
      }
    }

    // hotel grid
    .hotels-grid-view {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      // padding-inline: 16px;
      width: 100%;
    }
  }

  .service-card-with-btns {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    min-height: 400px;
    background-color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    border: 1px solid rgba(var(--web-page-primary-color), 0.16);
    gap: 8px;
    .card-details {
      width: 100%;
    }

    .card-img {
      position: relative;
      width: 100%;
      height: 240px;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-content {
      padding: 0.8rem;
    }

    .service-name {
      font-size: 18px;
      color: #050001;
      font-weight: 700;
      line-height: 24px;
    }

    .location {
      display: flex;
      gap: 4px;

      color: #050001;
      font-size: 14px;
      font-weight: 500;

      svg path {
        stroke: #707170;
      }
    }

    .card-footer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-self: flex-end;
      padding: 10px 0;
      background-color: #fff;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
        align-items: flex-start;
        .web-builder-filled-btn {
          width: 100%;
        }
      }
      .amount,
      .currency {
        color: #050001;
        line-height: 110.9%;
      }
    }

    .amount {
      color: #fbfbfa;
      font-size: 24px;
      font-weight: 700;
      line-height: 160.9%;
    }

    .currency {
      font-size: 14px;
      font-weight: 400;
      line-height: 160.9%;
    }
  }

  .overlay-service-card {
    position: relative;
    display: flex;
    padding: 20px;
    min-height: 350px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;

    .card-details {
      display: flex;
      align-self: flex-end;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding: 16px;
      gap: 8px;
      border-radius: 8px;
      background-color: rgba(5, 0, 1, 0.6);
      color: #fff;

      .hotel-name {
        font-size: 18px;
        color: #fbfbfa;
        font-weight: 700;
        line-height: 24px;
      }

      .price {
        display: flex;
        flex-direction: column;
        color: #fbfbfa;

        [dir="rtl"] & {
          left: unset;
          right: 34px;
        }

        span {
          font-size: 14px;
          font-weight: 400;
        }

        .amount {
          font-size: 24px;
          font-weight: 700;
          line-height: 38.62px;
        }
      }
    }

    .location {
      display: flex;
      align-items: center;
      color: #fbfbfa;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .font-grey-light {
    color: #707170;
    font-size: 14px;
    font-weight: 400;
    line-height: 160.9%;
  }

  .services-carousel-container {
    .react-multiple-carousel__arrow {
      background: rgba(var(--web-page-primary-color));
      min-width: 35px !important;
      min-height: 35px !important;
      border-radius: 50%;
      z-index: 2 !important;
    }

    .react-multiple-carousel__arrow--left {
      left: calc(50% - 45px);
      bottom: 0;

      &::before {
        content: "" !important;
      }
    }

    .react-multiple-carousel__arrow--right {
      right: calc(50% - 45px);
      bottom: 0;

      &::before {
        content: "" !important;
      }
    }

    // .r-left-icon {
    //   rotate: 180deg;
    // }

    // .react-multi-carousel-track {
    //   margin-bottom: 3rem !important;
    // }
  }

  ////////////////////// End  Hotel //////////////////

  ////////////////////// Start Tours  //////////////////
  .web-builder-content-tours {
    background-color: transparent;
    padding-block: var(--sections-block-padding);

    .tours-carousel-overlay {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      width: 100%;
      background-color: transparent;

      .react-multiple-carousel__arrow--left {
        left: 1px;
        top: 35%;
        height: 35px;

        &::before {
          content: "" !important;
        }
      }

      .react-multiple-carousel__arrow--right {
        right: 1px;
        top: 35%;
        height: 35px;

        &::before {
          content: "" !important;
        }
      }
    }
  }

  ////////////////////// End  Tours //////////////////
  ////////////////////// Start Flights  //////////////////
  .web-builder-content-flights {
    background-color: white;
    padding-block: var(--sections-block-padding);

    .flights-top-section {
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      & > div {
        flex: 1;
      }

      .height-90 {
        min-height: 40px;
      }

      .services-content {
        max-width: 50%;
        text-align: center;
      }

      .add-element-box {
        max-width: 50%;
      }
    }

    .flights-detials {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      margin-inline: 20%;

      .service-card-details-name {
        background-color: transparent;
        background: linear-gradient(
          180deg,
          rgba(203, 203, 203, 0.1725) 0%,
          rgba(45, 45, 45, 0.69) 44.79%,
          rgba(0, 0, 0, 0.93) 100%
        );
        color: white;
      }
    }
    .flights-cards-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    .flight-service-card {
      padding: 10px 24px;
      border: 1px solid #e5e5e5;
      border-radius: 8px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        h3 {
          color: #050001;
          font-weight: 700;
          font-size: 20px;
        }
      }

      .destination-count {
        padding: 10px 20px;
        background-color: rgba(var(--web-page-primary-color));
        color: #fbfbfa;
        border-radius: 30px;
      }

      .destinations-list {
        display: flex;
        align-items: center;
        gap: 10px;

        svg path {
          stroke: rgba(var(--web-page-primary-color));
        }

        svg {
          align-self: center;
          width: 14px;
          height: 10px;
          [dir="rtl"] & {
            transform: scale(-1);
          }
        }

        .port-country {
          font-size: 14px;
          font-weight: 400;
          line-height: 16.24px;
          color: #050001;
        }

        .destination-port {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          font-weight: 700;
          font-size: 18px;
          line-height: 20.88px;
        }

        .addition-destinations {
          position: relative;

          &:hover .addition-destinations-list {
            display: flex;
            flex-direction: column;
            gap: 12px;
          }
        }

        .addition-destinations-count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid rgba(var(--web-page-primary-color));
          color: rgba(var(--web-page-primary-color));
        }

        .addition-destinations-list {
          display: none;
          position: absolute;
          top: 30px;
          left: 0;
          padding: 16px;
          border: 1px solid #dddcdc;
          border-radius: 8px;
          background-color: #ffffff;
          z-index: 3;

          .destination-port {
            align-items: baseline;
            gap: 24px;
          }

          .destination-port:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid #f5f5f5;
            // margin-bottom: 10px;
          }
        }
      }

      .price {
        padding-inline-end: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #707170;
        border-inline-end: 1.5px solid #e5e5e5;

        .amount {
          font-size: 24px;
          font-weight: 700;
        }
      }

      .cta-btn {
        padding: 10px 12px;
        border: 1px solid rgba(var(--web-page-primary-color));
        border-radius: 4px;
        font-weight: 400;
        color: rgba(var(--web-page-primary-color));
        transition: all 0.3s ease-in;
        text-wrap: nowrap;

        &:hover {
          background-color: rgba(var(--web-page-primary-color));
          border: 1px solid rgba(var(--web-page-primary-color));
          color: #fff;
          transition: all 0.3s ease-in;
        }
      }
    }

    .flight-service-builder-card {
      display: flex;
      position: relative;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid rgba(var(--web-page-primary-color), 0.16);
      color: #050001;
      gap: 1rem;
      overflow: hidden;

      .card-image {
        width: 140px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-details {
        display: flex;
        flex-direction: column;
      }

      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
      }

      .location {
        color: rgba(var(--web-page-primary-color));
      }

      .price {
        margin-top: auto;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: baseline;

        .amount {
          font-weight: 700;
          font-size: 24px;
          color: rgba(var(--web-page-primary-color));
        }

        .currency {
          color: rgba(var(--web-page-primary-color));
        }
      }

      svg path {
        stroke: rgba(var(--web-page-primary-color));
      }
    }
    .react-multiple-carousel__arrow--right {
      bottom: unset;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-end: -15px;
      [dir="rtl"] & {
        inset-inline-end: unset;
        inset-inline-start: -40px;
      }
    }
    .react-multiple-carousel__arrow--left {
      bottom: unset;
      top: 50%;
      transform: translateY(-50%);
      inset-inline-start: -40px;

      [dir="rtl"] & {
        inset-inline-start: unset;
        inset-inline-end: -15px;
      }
    }
  }

  ////////////////////// End  Flights //////////////////

  ////////////////////// start  Transfer //////////////////
  .web-builder-content-transfer {
    padding-block: 70px;
    background-color: #f5f8fc;

    .transfer-card {
      display: flex;
      padding: 8px;
      border: 1px solid rgba(var(--web-page-primary-color), 0.16);
      border-radius: 8px;
      background-color: #ffffff;
      color: #707170;
      gap: 12px;
      cursor: pointer;
      overflow: hidden;

      .transfer-card-header {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .card-img {
        width: 200px;
        height: 140px;
        border-radius: 8px;
        aspect-ratio: 1;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-details {
        display: flex;
        flex-direction: column;
        width: calc(100% - 206px);
        gap: 10px;

        .title {
          display: inline-block;
          font-size: 18px;
          font-weight: 700;
          color: #050001;
          max-width: calc(100% - 20px);
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: capitalize;
        }

        svg path {
          stroke: #707170;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        gap: 10px;

        .transfer-card-header {
          display: flex;
          flex-direction: row-reverse;
          gap: 0.5rem;
          align-items: center;
          justify-content: space-between;
        }

        .card-img {
          width: 100%;
          aspect-ratio: 3/1;
        }
        .card-details {
          width: 100%;
        }
      }
    }
  }

  ////////////////////// End  Transfer //////////////////

  ////////////////////// start  other services  //////////////////
  .web-builder-content-other {
    padding-block: 70px;

    .otherservice-cards-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

      gap: 20px;
    }

    .otherservice-card {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(226, 232, 240, 1);
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      overflow: hidden;

      .card-details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 10px 1rem 1rem;
      }

      .card-img {
        position: relative;
        width: 100%;
        height: 250px;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            180.41deg,
            rgba(0, 0, 0, 0) 67.1%,
            #000000 99.65%
          );
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .title {
        display: block;
        font-size: 18px;
        font-weight: 700;
        color: #050001;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .description {
        font-size: 14px;
        line-height: 21.5px;
        color: #363636b2;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .card-bottom {
        padding: 0 1rem 1rem;
        display: flex;
        margin-top: auto;
      }

      .cta-btn {
        padding: 8px 2rem;
        margin-inline-start: auto;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 8px;
        background-color: rgba(var(--web-page-primary-color));
        color: #fff;
      }

      svg path {
        stroke: #707170;
      }
    }
  }

  ////////////////////// End  other services //////////////////

  ////////////////////// Start custom container  //////////////////
  .web-builder-content-custom-container {
    background-color: white;
    padding-block: 2em;

    .custom-container-top-section {
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      & > div {
        flex: 1;
      }

      .height-90 {
        min-height: 40px;
      }

      .services-content {
        max-width: 50%;
        text-align: center;
      }

      .add-element-box {
        max-width: 50%;
      }
    }

    .custom-container-detials {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 32px;
      margin-inline: 10%;

      .faq-fill-width {
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
      }

      .custom-container-paragraph {
        word-break: break-word;
      }

      .custom-container-video {
        aspect-ratio: 3/2;

        .container-video-input {
          width: 100%;
          height: 100%;
          border: 1px dashed;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 4em;

          button {
            background-color: transparent;
            border: none;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }

      .custom-container-photo {
        aspect-ratio: 3/2;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .custom-container-add-photo {
        height: 100%;

        .upload-file-container {
          height: 100%;
          width: 100%;
          border: 1px dashed;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4em;

          label {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }

  ////////////////////// End  custom container //////////////////

  ////////////////////// Start FAQs  //////////////////
  .web-builder-faqs-container {
    background-color: transparent;
    // padding: 0 2em;

    .faqs-question-wrapper {
      padding: 8px 0;
      margin-bottom: 10px;
      border-bottom: 1px solid rgba(var(--web-page-primary-color), 0.1);
    }

    .faqs-container-question {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1rem;
        font-weight: 600;
        line-height: 40px;
        color: #1f1f1f;
      }
    }

    .faqs-container-body {
      color: var(--text-secondary-color);
    }

    .faq-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid rgba(var(--web-page-primary-color)) !important;
      background-color: rgba(var(--web-page-primary-color), 0.1) !important;
      color: rgba(var(--web-page-primary-color));

      svg {
        width: 16px;
      }

      svg path {
        fill: rgba(var(--web-page-primary-color));
      }
    }

    .faq-with-image {
      .faq-detials {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        align-items: flex-start;
      }

      .upload-file-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #f7f6f6;
        border: 1px dashed #dddcdc;
      }

      .faq-image {
        width: 100%;
        // height: 100%;
        object-fit: contain;
      }
    }

    .faq-column-view {
      .faqs-question-wrapper {
        padding: 1rem;
        border: 1px solid rgba(var(--web-page-primary-color), 0.16);
      }

      .services-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .faq-questions-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        align-items: center;
        margin-top: 20px;
      }
    }
  }

  ////////////////////// End FAQs //////////////////
  ///
  ///
  ////////////////////// Start contact us  //////////////////

  .web-builder-content-contact-us {
    padding: 2em 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    grid-gap: 30px;
    gap: 30px;
    background-color: white;

    .contact-us-layout {
      border-radius: 8px;
      background: rgba(30, 133, 255, 0.08);
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // gap: 20px;
    }

    .content-top-section {
      text-align: center;

      .t-body {
        color: #707170 !important;
        font-size: 16px !important;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .t-title {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 24px;
      }
    }

    .h4 {
      font-weight: 700;
    }

    .contact-us-form {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .contact-us-fields {
        flex: 1 1 auto;

        input::placeholder,
        textarea::placeholder {
          color: #4b465c !important;
        }

        input::placeholder {
          font-size: 15px;
        }

        textarea::placeholder {
          font-size: 13px;
        }
      }

      textarea {
        flex: 1 0 100%;
        border: 1px solid #ebebeb;
        border-radius: 0.3125rem;
        padding: 8px 10px;
      }

      .contact-us-submit-button {
        border: none;
        border-radius: var(--web-page-border-raduis);
        background-color: rgba(var(--web-page-primary-color));
        color: white;
        padding: 8px;
        width: 100%;
      }
    }
  }

  .web-builder-content-contact-us-with-image {
    padding-block: var(--sections-block-padding);

    // background-color: #f5f8fc;
    .contact-us-layout {
      padding: 32px;
      display: flex;
      gap: 50px;
      border-radius: 4px;
      background-color: #f5f8fc;

      .contact-us-data {
        // padding-inline: 32px;
        width: 40%;

        .t-title {
          color: rgba(var(--web-page-font-color));
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .t-body {
          color: #707170;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 24px;
        }

        .contact-us-form {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .input-group {
            margin: 0 !important;
          }

          input::placeholder {
            color: #4b465c;
            font-size: 15px;
          }

          .textarea-field {
            background-color: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 0.3125rem;
            font-size: 13px;
            width: 100%;
            padding: 10px;

            &::placeholder {
              color: #4b465c;
            }
          }

          .contact-us-submit-button {
            border: none;
            border-radius: var(--web-page-border-raduis);
            background-color: rgba(var(--web-page-primary-color));
            color: white;
            padding: 8px;
            width: 100%;
          }
        }
      }

      .contact-us-image {
        width: 60%;

        img {
          width: 100%;
          height: 100%;
          box-shadow: 0px 1px 12px 0px rgba(30, 133, 255, 0.16);
          border-radius: 4px;
        }

        .upload-file-container {
          background-color: #f7f6f6;
          border: 1px dashed #dddcdc;
          width: 100%;
          height: 100%;

          position: relative;

          label {
            height: 100%;
          }

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          svg {
            position: absolute;
            left: 45%;
            top: 45%;
          }
        }
      }
    }
  }

  ////////////////////// End  contact us//////////////////
  //////////////////////  genrice classes //////////////////
  .service-card-details {
    position: relative;
    padding: 20px;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    height: 211px;
    // width: 244px;
  }

  .service-top-section {
    padding: 0 1.6rem 1.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    & > div {
      flex: 1;
    }

    .height-90 {
      min-height: 40px;
    }

    .services-content {
      max-width: 50%;
      text-align: center;
    }

    .add-element-box {
      max-width: 50%;
    }
  }

  .empty-service {
    border: 1px dashed #dddcdc !important;
    background: rgba(112, 113, 112, 0.8) !important;
  }

  .carousel-items-conteiner {
    gap: 8px;
  }

  .add-element-box {
    width: 215px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    border: 1px dashed#DDDCDC;
    background-color: #f7f6f6;
    gap: 16px;

    p {
      color: #6a4d1b;
      font-size: 16px;
      font-weight: 500;
    }

    button {
      background-color: transparent;
      padding-inline: 0;
      border: none;
    }
  }

  .react-multiple-carousel__arrow--left,
  .react-multiple-carousel__arrow--right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    border: 1px solid transparent;
    z-index: 2 !important;
  }

  .react-multiple-carousel__arrow--left:disabled,
  .react-multiple-carousel__arrow--right:disabled {
    background-color: #ebf5fa;
    border: 1px solid #badbee;

    svg path {
      stroke: rgba(var(--web-page-primary-color));
    }
  }
}

////////////////////// Start Edit Box   //////////////////
.edit-box-container {
  border: 1px dashed var(--primary-color);
}

.edit-box-actions-parent {
  display: flex;
  // position: absolute;
  // left: 50%;

  .edit-box-actions {
    background-color: var(--primary-color);
    border-start-start-radius: 4px;
    border-start-end-radius: 4px;
    display: flex;
    padding: 3px;
    width: max-content;
    gap: 6px;
    align-items: center;

    path {
      stroke: white;
    }

    i {
      color: #fff;
    }
  }
}

////////////////////// End Edit Box   //////////////////

.cutom-container-video-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    margin: 0;
  }

  button {
    padding: 6px;
    border: none;
    border-radius: 4px;
  }

  button.cancel {
    background-color: #b5b5b5;
  }
}

.drop-placeholder {
  width: 100%;
  min-height: 350px;
  border: 1px dashed var(--primary-color);
  color: var(--text-secondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 1em;
}

////////////////////// Start Toggle Checkbox  //////////////////
.toggle-check-box {
  .checkbox-label {
    position: relative;
    margin-inline-end: 8px;

    font-size: 12px;
    color: var(--text-secondary-color);
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 18px;
    top: 5px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;

    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid;
    border-color: #c7c7c7;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    inset-inline-start: 2px;
    bottom: 2px;
    background-color: #c7c7c7;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider:before {
    background-color: white;
  }

  input:checked + .slider {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--primary-color);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  &.disabled {
    cursor: default;
    background-color: transparent !important;

    .checkbox-label {
      color: rgba(0, 0, 0, 0.2);
      cursor: not-allowed;
    }

    .slider {
      cursor: not-allowed;
    }
  }
}

.slider-edit-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-inline: 16px;
  width: 100%;
}

.upload-file-container {
  label:hover {
    cursor: pointer;
  }
}

.adding-service-modal {
  img {
    margin-block: 0.5em;
    border-radius: 4px;
    border: 4px solid #f7f6f6;
  }

  .upload-file-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #eed9ba;
    background: #faf4eb;
    padding-block: 8px;
    color: #d4a655;
    border-radius: 4px;
    margin-top: 8px;

    label {
      margin: 0;
    }
  }
}

.primary-button {
  background: #d4a655;
  color: white;
  border: none;
  border-radius: 4px;
  width: 100%;
  padding: 8px;

  &:disabled {
    background-color: #b5b5b5;
  }
}

.footer-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
    linear-gradient(
      0deg,
      rgba(var(--web-page-primary-color)),
      rgba(var(--web-page-primary-color))
    );
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 23px 24px;

  .footer-copyrights {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    margin-top: 12px;
    border-top: 1px solid #94999c;
    width: 100%;
    color: #94999c;
  }

  textarea {
    color: #fff;
  }
}

.footer-social-modal .control-field__label {
  color: var(--text-secondary-color);
}

.nav-modal {
  .modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout {
    div {
      background-color: #dddcdc;
      border-radius: 5px;
      cursor: pointer;

      svg {
        padding: 9px;
      }
    }

    div.active {
      border: 1px solid #d4a655;
    }
  }

  .behavior {
    div {
      margin-inline: 20px;
    }

    input[type="radio"] {
      // accent-color: #d4a655;
    }

    input[type="radio"]:after {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      top: -3px;
      //left: -1px;
      position: relative;
      background-color: #fff;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 1px solid #d4a655;
    }

    input[type="radio"]:checked:after {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      top: -3px;
      position: relative;
      background-color: #d4a655;
      content: "";
      display: inline-block;
      visibility: visible;
      border: 1px solid #d4a655;
    }
  }
}

.main-modal {
  .modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .layout {
    gap: 8px;

    div {
      background-color: #dddcdc;
      border-radius: 5px;
      cursor: pointer;
      padding: 9px;
      gap: 5px;

      svg {
        width: 100%;
      }
    }

    div.active {
      border: 1px solid #d4a655;
    }
  }
}

.faq-modal {
  display: flex;
  flex-direction: column;

  button {
    border: none;
    background-color: transparent;
  }

  .faq-input {
    margin-block: 16px;
    display: flex;
    justify-content: space-between;

    & > div {
      flex: 1;
      padding-inline-end: 8px;
    }

    input {
      padding: 6px;
      border-radius: 4px;
      width: 100%;
      border: 1px solid;
    }

    label {
      display: block;
    }
  }

  .remove-icon {
    align-self: self-end;
    padding-bottom: 9px;
  }

  button.add-icon {
    background-color: #eed9ba;
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .insert-button {
    display: block;
    background: #d4a655;
    padding: 16px 32px;
    color: white;
    border-radius: 8px;
    width: max-content;
    align-self: flex-end;
  }
}

.pointer-event {
  &:hover {
    cursor: pointer;
  }
}

.contact-us-modal-button {
  width: max-content;
  font-weight: 700;
  padding: 32px;
  padding-block: 14px !important;
  align-self: flex-end;
}

.start-price {
  position: absolute;
  top: 20px;
  inset-inline-end: 20px;
  display: flex;
  flex-direction: column;
  border-radius: var(--web-page-border-raduis);
  background-color: rgba(0, 0, 0, 0.34);
  padding: 5px 10px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;

  [dir="rtl"] & {
    left: unset;
    right: 34px;
  }

  .amount {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #fefeff;
  }
}

/////////////////// start package search results
.public-page-search {
  background: #fff;
  min-height: 85vh;

  .web-builder-hero-header header {
    padding: 16px 64px;
  }

  .search-bread-crumb {
    background: #f5f8fc;
    padding: 24px 64px;
    display: flex;
    justify-content: center;
    gap: 7px;

    .home {
      color: rgba(var(--web-page-primary-color));
      font-size: 18px;
      font-weight: 700;
    }

    span {
      color: #707170;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .all-search-result {
    padding: 20px 64px;

    .search-result-layout {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .search-filter {
        flex-grow: 0;
        width: 300px;
      }

      .flight-service-card {
        width: 100%;
      }

      .cards {
        flex-grow: 1;
        width: min-content;
      }
    }

    .row {
      margin-inline: 0 !important;
    }

    .search-filter {
      .reset {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        p {
          color: #050001;
          font-size: 20px;
          font-weight: 500;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid rgba(var(--web-page-primary-color));
          padding: 4px 16px;
          color: rgba(var(--web-page-primary-color));
          font-size: 16px;
          font-weight: 500;
          gap: 4px;

          svg path {
            stroke: rgba(var(--web-page-primary-color));
          }
        }
      }

      .package-type {
        margin-top: 16px;
        border-radius: 8px;
        border: 1px solid #f7f6f6;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.02);
        padding: 16px 10px;
        margin-bottom: 18px;

        .terms-color {
          color: #050001 !important;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .all-inputs {
          div {
            background: #fbfbfa;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 14px;
            margin-top: 16px;

            input {
              width: 18px;
              height: 18px;
            }

            label {
              color: #707170;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0 !important;
            }
          }
        }
      }

      .star-rating {
        border-radius: 8px;
        border: 1px solid #f7f6f6;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.02);
        padding: 16px 10px;
        margin-bottom: 18px;

        .terms-color {
          color: #050001 !important;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .all-inputs {
          div {
            background: #fbfbfa;
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 14px;
            margin-top: 16px;

            input {
              width: 18px;
              height: 18px;
            }

            label {
              color: #707170;
              font-size: 16px;
              font-weight: 400;
              margin-bottom: 0 !important;
              display: flex;
              gap: 4px;
            }
          }
        }
      }

      .price-range {
        border-radius: 8px;
        border: 1px solid #f7f6f6;
        background: #fff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.02);
        padding: 16px 10px;
        margin-bottom: 18px;

        .terms-color {
          color: #050001 !important;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        .multi-range-slider {
          border: none !important;
          box-shadow: none !important;

          .bar-inner {
            background-color: #dddcdc;
            border: none !important;
            box-shadow: none !important;
          }

          .thumb::before {
            background-color: rgba(var(--web-page-primary-color)) !important;
            border: none !important;
            box-shadow: none !important;
          }

          .caption {
            bottom: -25px;
            width: 11px;

            .max-caption,
            .min-caption {
              background-color: #fff !important;
              padding: 8px 16px;
              border-radius: 0 !important;
              color: #222;
              line-height: 1;
              border-radius: 6px !important;
              border: 1px solid #dddcdc;
              box-shadow: none;
              color: #222;
              text-align: center;
              font-weight: 500;
            }
          }

          .bar-left,
          .bar-right {
            box-shadow: none !important;
          }
        }

        .labels {
          display: none;
        }

        .price-input {
          width: 100%;
          padding: 10px 16px;
          border: 1px solid #dddcdc;
          border-radius: 6px;
        }

        .apply-price-filter {
          width: 100%;
          margin-top: 12px;
          padding: 7px 20px;
          font-size: 16px;
          font-weight: 700;
          line-height: 30px;
          background-color: rgba(var(--web-page-primary-color));
          color: #fff;
        }
      }
    }

    .search-result {
      .header {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        p {
          color: #050001;
          font-size: 20px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .select-box {
          display: flex;
          align-items: center;
          gap: 12px;

          p {
            color: #050001;
            font-size: 16px;
            font-weight: 500;
          }

          select {
            min-width: 150px;
            border-radius: 4px;
            border: 1px solid #dbdade;
            background: #fff;
            // padding: 13px 20px;
            padding: 5px 20px;
            color: #707170;
            font-size: 18px;
            font-weight: 400;
          }
        }
      }

      .result {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

        @media screen and (max-width: 767px) {
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        }

        .flight-service-card {
          width: 100%;
        }

        gap: 16px;
        margin-top: 16px;

        .result-item {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          border: 1px solid #e2e8f0;
          background: #fff;
          padding: 8px;
          width: 100%;

          img {
            border-radius: 8px;
            border: 1px solid #f7f6f6;
            height: 180px;
            width: 100%;
            object-fit: cover;
          }

          .location {
            margin-top: 5px;

            span {
              color: #707170;
              font-size: 16px;
              font-weight: 300;
            }
          }

          .name {
            display: flex;
            flex-direction: column;
            margin-block: 1rem;

            h4 {
              color: #050001;
              font-size: 18px;
              font-weight: 700;
            }

            .start {
              margin-top: 3px;
              display: flex;
              gap: 3px;
            }
          }

          .hotel-name {
            display: flex;
            align-items: center;
            color: #707170;
            font-size: 16px;
            font-weight: 300;

            svg path {
              stroke: #707170;
            }
          }

          .route {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
            text-transform: capitalize;

            div {
              display: flex;
              align-items: center;

              p {
                color: #707170;
                font-size: 16px;
                font-weight: 300;
              }
            }
          }

          .price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: auto;
            padding-top: 1rem;
            border-top: 1px solid #e2e8f0;

            div {
              display: flex;
              flex-direction: column;

              span {
                color: #707170;
                font-size: 14px;
                font-weight: 400;
              }

              p {
                color: #050001;
                font-size: 24px;
                font-weight: 600;

                span {
                  color: #050001;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }

            .btn {
              border-radius: 4px;
              background-color: rgba(var(--web-page-primary-color));
              padding: 7px 30px;
              font-size: 16px;
              font-weight: 700;

              a {
                color: #fefeff;
              }
            }
          }
        }
      }

      .no-result {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 95vh;
        svg {
          max-width: 95%;
        }
        h6 {
          color: #000;
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 18px;
          margin-top: 16px;
        }

        p {
          color: #707170;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 28px;
        }

        .btn {
          border-radius: 6px;
          background: rgb(var(--web-page-primary-color));
          padding: 8px 32px;
          color: #fefeff;
          font-size: 16px;
          font-weight: 700;
          width: 40%;
        }
      }
    }

    .filter-mobile-btn,
    .close-filter-btn {
      display: none;
    }
  }
  .flight-service-card {
    display: flex;
    flex-direction: column;
    padding: 16px;

    border-radius: 24px;
    border: 1px solid #f8f8f8;
    // border: 1px solid rgb(var(--web-page-primary-color));
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    background: #ffffff;
    gap: 20px;
    transition: border 0.35s ease-in-out;
    border-color: rgb(var(--web-page-primary-color));

    .content div:nth-child(2) {
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    [dir="rtl"] & .arrow {
      transform: scale(-1, 1);
    }

    .header {
      padding-bottom: 16px;
      border-bottom: 1px solid #e5e5e5;

      h3 {
        display: inline-block;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        max-width: 100%;
        color: #222222;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .destination-port {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: rgb(var(--web-page-primary-color));
      font-size: 20px;
      text-align: center;
      font-weight: 700;
      line-height: 1.2;

      .port-country {
        font-size: 18px;
        color: #222222;
        font-weight: 400;
      }
    }

    .price {
      padding-top: 10px;
      font-size: 16px;
      text-align: center;
      color: #222222;
      border-top: solid thin #e2e8f0;

      @media screen and (max-width: 767px) {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
      }

      .amount {
        color: rgb(var(--web-page-primary-color));
        font-weight: bold;
        font-size: 26px;
        line-height: 22px;
        margin-inline-start: 0.5rem;
        @media screen and (max-width: 767px) {
          margin-left: auto !important;
          margin-right: 0 !important;
          display: block;
        }
      }

      .currency {
        color: rgb(var(--web-page-primary-color));
        font-size: 14px;
        font-weight: 400;
      }
    }

    .destination-count {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 24px;
      color: rgba(var(--web-page-primary-color));
      background-color: rgba(var(--web-page-primary-color), 0.08);
      border: 1px solid rgba(var(--web-page-primary-color), 0.16);
      gap: 6px;
      border-radius: 30px;

      &:hover .addition-destinations-list {
        display: flex;
        width: 150%;
        flex-direction: column;
        gap: 12px;
      }
    }

    .destination-port {
      display: flex;
      justify-content: space-evenly;
      color: #050001;
      gap: 24px !important;

      .port-country {
        font-weight: 400;
        font-size: 14px;
      }

      .arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        background-color: rgba(var(--web-page-primary-color), 0.08);
        border-radius: 50%;

        svg {
          width: 75% !important;
          height: 75%;
        }
      }
    }

    .addition-destinations-list {
      display: none;
      position: absolute;
      width: 100%;
      top: 38px;
      left: 0;
      padding: 16px;
      border: 1px solid #dddcdc;
      border-radius: 8px;
      background-color: #ffffff;
      z-index: 3;

      svg path {
        stroke: rgba(var(--web-page-primary-color));
      }

      .destination-port {
        gap: 16px !important;
        justify-content: space-between;
        align-items: center;

        .font-weight-bold {
          width: 90px;
        }
      }
    }

    .cta-btn {
      padding: 8px 24px;
      border-radius: 4px;
      color: #fff !important;
      background-color: rgba(var(--web-page-primary-color));
      text-align: center;
      text-wrap: nowrap;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .arrow svg path {
      stroke: rgb(var(--web-page-primary-color));
    }
  }

  .flight-service-builder-card {
    display: flex;
    position: relative;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgba(var(--web-page-primary-color), 0.16);
    color: #050001;
    gap: 1rem;
    overflow: hidden;

    .card-image {
      width: 140px;
      height: 120px;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-details {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    .location {
      color: #707170;
    }

    .price {
      margin-top: auto;
      font-size: 14px;
      font-weight: 500;

      .amount {
        font-weight: 700;
        font-size: 24px;
        color: rgba(var(--web-page-primary-color));
      }

      .currency {
        color: rgba(var(--web-page-primary-color));
      }
    }

    svg path {
      stroke: #707170;
    }
  }
}

/// start media queries

.re-position-header-web-builder {
  position: absolute;
  width: 100%;
  top: 0;
}

/******************************** old theme **************************************/
.theme-old {
  .web-builder-hero-header header {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: transparent;
    color: #fff;
  }

  .web-builder-hero {
    background-position: 50%;
    padding-bottom: 4em;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 462px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      color: rgba(var(--web-page-primary-color)) !important;
    }

    .has-bg.service-dropdown {
      color: var(--text-secondary-color) !important;
    }

    .service-dropdown {
      color: #fff !important;
    }

    .web-builder-hero-content {
      align-items: center;
      gap: 12px;
    }
  }

  .web-builder-hero-header header nav ul li {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .web-builder-content-our-service {
    padding: 2em 30%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: initial;
    gap: 24px;
    background-color: #f5f8fc;
  }

  // about us
  #about-us-section {
    background-color: initial;
    padding: initial;
  }

  .about-us-details {
    word-break: break-word;
  }

  .web-builder-content-about-us {
    padding: 2em 10%;
    background-color: #fff;
    align-items: flex-start;

    .media-image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  // hotels
  .web-builder-content-hotels {
    .hotels-top-section {
      align-items: center;
    }
  }

  // tours
  .web-builder-content-tours {
    background-color: #fff;
    padding-block: 2em;

    .tours-top-section {
      padding: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      grid-gap: 30px;
      gap: 30px;
    }

    .add-element-box {
      max-width: 50%;
    }
  }

  .add-element-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 160px;
    min-width: 160px;
    background-color: #f7f6f6;
    border: 1px dashed #dddcdc;
    flex: 1 1;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }
}

/******************************** theme 3 **************************************/

.theme-3 {
  .web-builder-hero-content {
    background-color: #1f1f1f80;
    padding: 1rem !important;
    width: fit-content;
    // min-width: 50%;
    margin: 0 auto;
    border-radius: 10px;
    gap: 10px;
    z-index: 2;
    .header {
      font-weight: 700;
      font-size: 2rem;
      line-height: 120%;
    }
  }

  .search-form-web-builder:not(.market-place-search) {
    // display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    // width: 100%;
    padding: 0 !important;
    // margin-top: 0 !important;
    background: transparent;

    .search-form-select {
      .control-field {
        height: 40px;
        border-radius: 8px;
      }
    }

    .search-form-button {
      padding: 7px 16px !important;
    }
  }

  .web-builder-content-hotels,
  .web-builder-content-tours {
    padding-block: 1rem;
  }

  .section-title {
    margin-top: 1.6rem;
  }

  .slider-edit-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    gap: 16px;
    // padding-inline: 16px;
    padding-inline: 0 !important;
    width: 100%;
  }

  .web-builder-content-our-packages-with-image {
    margin-top: 1rem;
  }

  .web-builder-content-our-packages-with-image
    .package-card-container
    .package-info
    .date {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
  }

  .all-web-builder-our-service-images {
    grid-template-columns: repeat(2, 1fr);

    width: 100%;

    .package-card-container {
      position: relative;
      padding: 20px;
      background-size: cover !important;
      display: flex;
      height: 300px !important;

      .package-card-details {
        align-self: flex-end;
        flex: 1 1;
        padding: 16px;
        color: #fff;
        background: linear-gradient(
          180deg,
          hsla(0, 0%, 79.6%, 0.1725),
          rgba(45, 45, 45, 0.69) 44.79%,
          rgba(0, 0, 0, 0.93)
        );
      }

      .package-info {
        display: flex;
        flex-direction: column;
        // gap: 6px;
        position: static;

        .starts {
          margin-bottom: 6px;
        }
      }
    }

    .our-service-item,
    .edit-box-container {
      height: auto !important;
    }
  }

  .service-top-section {
    padding: 1.6rem !important;
    margin-bottom: 1rem;
  }

  .service-card-details {
    height: 300px !important;

    .service-card-details-name {
      color: #212529;
    }
  }

  .hero-content-container {
    position: relative !important;
    justify-content: center;
  }

  .web-builder-hero-content {
    .header,
    .body {
      text-align: center;
      color: rgba(var(--web-page-primary-color)) !important;
    }
  }
}

.card_rate_svg {
  display: inline-block;
  margin-inline-end: 0.2rem;
  &:last-child {
    margin-inline-end: 0;
  }
}
