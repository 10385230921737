.cart-items-wrapper {
	.hotel-detail-section {
		position: relative;
		.delete-item {
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			[dir="rtl"] & {
				right: auto;
				left: 0;
			}
		}
	}
	.hotel-header {
		h6 {
			color: #354f5c;
			font-weight: 700;
		}
	}
	.total-price-box {
		padding-top: 0;
		height: 100px;
		background: #f9f9f9;
		border: 1px solid #e8e8e8;
	}

	.booking-summary {
		h5 {
			font-weight: 700;
			font-size: $text-headline;
			margin: 0.5rem 0;
		}
		.summary-box {
			display: flex;
			justify-content: space-between;
			font-size: $text-caption;
			padding: 0.2rem 0;
			&:last-child {
				border: none;
			}
		} //summary-box
		.total {
			display: flex;
			justify-content: space-between;
			font-weight: 700;
			font-size: $text-headline;
			background: #e8e8e8;
			padding: 0.3rem;
			border: 1px solid #dee2e6;
			color: #3c3c3c;
		}
	}

	.coupon-btn {
		margin: auto;
		text-transform: uppercase;
		padding: 0.5rem 1rem;
		display: flex;
		border: none;
		background-color: #acacac;
		color: $white;
	}
}

.text-decor {
	text-decoration: underline !important;
	color: $blue;
	transition: all 0.3s ease-in-out;
}
//New UI
.T-box-item {
	background-color: #fff;
	padding: 6px;
	border-radius: 10px;
	border: 1px solid #ececec;
	width: 50%;
	.box-item-contain {
		.img {
			width: 100%;
		}
		.icons {
			display: flex;
			justify-content: space-around;
			// margin-block: 8px;
			margin-bottom: 8px;
			margin-top: 14px;
			.icon-mosque {
				display: flex;
				flex-direction: column;
			}
			span {
				background: #d5312e;
				color: #fff;
				border-radius: 9px;
				font-size: 13px;
				text-align: center;
				margin-top: 3px;
			}
		}
	}
	button {
		width: 100%;
		border: 1px solid #ce933f;
		color: #ce933f;
		background-color: #fff;
		padding: 6px 0;
		font-size: 18px;
		i {
			font-size: 12px;
		}
	}
	button:hover,
	button:active {
		color: #fff;
		background-color: #ce933f;
		border: 1px solid #fff;
	}
	.btn-global {
		// position: relative;
		// top:23px
		margin-top: 21px;
	}
	.btn-start-service {
		svg {
			margin-bottom: 3px;
		}
	}
	.btn-start-service:hover {
		svg {
			path {
				fill: #fff;
			}
		}
	}
}
.booking-service-details {
	padding: 10px;
	.mybooking-search-wrapper {
		height: 250px;
		background-position: center center;
		background-size: cover;
		display: flex;
		align-items: center;
		.icon-services {
			width: 52%;
			[dir="rtl"] & {
				width: 54%;
			}
			margin: auto;
			border-radius: 33px;
			display: flex;
			gap: 25px;
			position: relative;
			.search {
				position: absolute;
				right: 10px;
				top: 11.5%;
				background-color: #ce933f;
				color: #fff;
				width: 60px;
				height: 60px;
				text-align: center;
				border-radius: 50%;
				display: flex;
				justify-content: center;
			}

			div {
				display: flex;
				flex-direction: column;
				align-items: center;
				cursor: pointer;
				padding-block: 3px;
				span {
					font-weight: 600;
				}
			}
		}
	}
	.booking-services-search-wrapper {
		min-height: auto;
		background-size: cover;
		background-position: 100%;
		.icon-services {
			display: flex;
			justify-content: center;
			gap: 10px;
			div {
				cursor: pointer;
				padding: 12px;
				display: flex;
				flex-direction: column;
				align-items: center;
				span {
					font-weight: 600;
				}
			}
			div.active {
				border: 1px solid #cb9a51;
				background-color: #ebc18341;
				border-radius: 5px;
			}
		}
		.button-collapse {
			position: relative;
			.pax-container {
				position: absolute;
				background: white;
				z-index: 9999999;
				// bottom: 45px;
				//max-height: 270px;
				left: 0px;
				right: 0px;
				margin-inline: 0px;
				top: 50px;
				padding: 15px;
				border: 1px solid #F6F5F5;
				box-shadow: 2px 2px 2px #f1f1f1;
				.adult-pax {
					display: flex;
					justify-content: space-between;
					width: 100%;
					p {
						color: #000;
						font-size: 15px;
						span {
							color: #5e778d;
							font-size: 15px;
						}
					}
					.action {
						display: flex;
						gap: 25px;
						button {
							color: #ce933f;
							width: 20px;
							height: 20px;
							border-radius: 50%;
							border: 2px solid #ce933f;
							background-color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 3px;
							font-weight: 600;
							cursor: pointer;
							span{
								margin-bottom: 3px;
							}
						}
						button:disabled{
							color: #D5DAE3;
							border: 2px solid #D5DAE3;
						}
						.adultNum {
							font-size: 18px;
							font-weight: 600;
						}
					}
				}
				.childrensAge{
					.p_children{
						color: #5e778d;
						font-size: 15px;
					}
				}
				.children-age-select{
					
						p{
							font-size: 15px;
    					font-weight: 500;
						}
						select{
							border:none;
							color: #ce933f;

						}
					}
					
				
			}
		}
	}
	.all-services {
		.icon-services .row {
			margin-inline: 0 !important;
		}
		h3 {
			font-weight: 700;
			font-size: 19px;
		}
		.tabs-services {
			display: flex;
			gap: 9px;
			list-style: none;

			li {
				cursor: pointer;
				border: 1px solid #dbdada;
				border-radius: 20px;
				background-color: #fff;
				color: #656665;
				padding: 5px 8px;
				font-size: 17px;
			}
			.active {
				background-color: #faf2e9;
				border: 1px solid #d29b53;
				color: #d29b53;
			}
		}
		.service-item {
			border: 1px solid #f0f0f0;
			border-radius: 5px;
			min-width: 100% !important;
			height: 100%;
			display: flex;
			flex-direction: column;
			// justify-content: space-between;
			img {
				border-radius: 5px 5px 0 0;
			}
			.service-item-body {
				padding: 5px;
				p {
					color: #000000;
					font-weight: 500;
				}
				span {
					font-size: 12px;
				}
			}
			.service-item-price {
				border-top: 1px solid #f0f0f0;
				padding: 5px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.price {
					display: flex;
					flex-direction: column;
					p {
						color: #0072f8;
						font-weight: 700;
						span {
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	.package-card-services {
		height: 220px !important;
		//width:220px !important
	}
}

.btn-outer-yellow {
	width: 100%;
	border: 1px solid #ce933f;
	color: #ce933f;
	background-color: #fff;
	padding: 6px 0;
	font-size: 18px;
	i {
		font-size: 12px;
	}
}
.btn-outer-yellow:hover,
.btn-outer-yellow:active {
	color: #fff;
	background-color: #ce933f;
	border: 1px solid #fff;
}
.text-17 {
	font-size: 17px !important;
}
.services-form {
	button {
		svg {
			margin-bottom: 2px;
		}
	}
}



.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #D29D4D ;
	background-color: #D29D4D ;
}
.slider-service-package{
	.react-multi-carousel-track{
		gap:30px !important
	}
	.react-multi-carousel-item.pakage-slide{
		width: 320px !important;
	}
}
