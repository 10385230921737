.theme-7 {
	.theme_services_section {
		position: relative;
		// padding-right: 3rem;
		// padding-left: 3rem;

		.section_wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			// align-items: center;
			gap: 3rem;

			.services-content {
				.service-header {
					font-size: 1rem;
					font-weight: 600;
					line-height: 1.5;
					color: rgb(var(--web-page-primary-color));
				}

				.service-body {
					font-size: 1.8rem;
					font-weight: 700;
					line-height: 1.4;
					color: #000000;
				}
			}

			.service_cards_container {
				display: flex;
				justify-content: flex-start;
				// align-items: center;
				gap: 1rem;

				.theme_service_card {
					position: relative;
					text-align: center;

					.image {
						margin-bottom: 0.6rem;
						// cursor: pointer;
						overflow: hidden;
						padding: 8px;
						border: 2px solid transparent;
						border-radius: 50%;
						transition: all 0.3s linear;

						img {
							width: 170px;
							height: 170px;
							-o-object-fit: cover;
							object-fit: cover;
							-o-object-position: cover;
							object-position: cover;
							border-radius: 50%;
							transition: all 0.3s linear;
						}

						&:hover {
							border-color: rgb(var(--web-page-primary-color));

							img {
								-webkit-transform: scale(1.05);
								transform: scale(1.05);
							}
						}

						label {
							margin-bottom: 0;
						}
					}

					.text {
						.sub {
							font-size: 1rem;
							line-height: 1.5;
							color: rgb(var(--web-page-primary-color));
							font-weight: 600;
							margin-top: 0;
							margin-bottom: 0;
						}

						.title {
							font-size: 1.2rem;
							line-height: 1.4;
							font-weight: 700;
							color: #000000;
							max-width: 190px;
						}
					}
				}
			}
		}

		@media screen and (max-width: 1360px) {
			padding-right: 2.5rem;
			padding-left: 2.5rem;

			.section_wrapper {
				gap: 2.5rem;

				.heading {
					h3 {
						font-size: 1.7rem;
					}
				}

				.service_cards_container {
					.theme_service_card {
						.image {
							img {
								width: 140px;
								height: 140px;
							}
						}

						.text {
							.title {
								font-size: 1.1rem;
								max-width: 160px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1280px) {
			padding-right: 2rem;
			padding-left: 2rem;

			.section_wrapper {
				gap: 2rem;

				.heading {
					h3 {
						font-size: 1.6rem;
					}
				}

				.service_cards_container {
					.theme_service_card {
						.image {
							img {
								width: 130px;
								height: 130px;
							}
						}

						.text {
							.title {
								font-size: 1rem;
								max-width: 130px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 1199px) {
			padding-right: 1rem;
			padding-left: 1rem;

			.section_wrapper {
				gap: 1.5rem;
				flex-wrap: wrap;
				justify-content: center;

				.heading {
					text-align: center;

					h3 {
						font-size: 1.5rem;
					}
				}

				.service_cards_container {
					flex-wrap: wrap;
					justify-content: flex-start;

					.theme_service_card {
						.image {
							img {
								width: 120px;
								height: 120px;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width: 576px) {
			padding-right: 0;
			padding-left: 0;
			.service-body {
				font-size: 1.2rem !important;
			}
			.section_wrapper {
				.heading {
					h3 {
						font-size: 1.4rem;
					}
				}

				.service_cards_container {
					justify-content: center;

					.theme_service_card {
						.image {
							margin-bottom: 0.3rem;

							img {
								width: 110px;
								height: 110px;
							}
						}
					}
				}
			}
		}
	}
}