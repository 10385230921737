.theme-7 {
	.theme_packages_section {
		position: relative;
		.section_title {
			.heading {
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1.5;
				margin-bottom: 1rem;
				color: #000000;
			}
			.text__content {
				font-size: 1rem;
				line-height: 1.5;
				margin-top: 0;
				margin-bottom: 1.2rem;
			}
		}
		.section_content {
			padding-inline-start: 3rem;
		}
	}
	.theme_package_card {
		position: relative;
		background-color: #ffffff;
		padding: 0.5rem;
		box-shadow: 0 0 8px #cacaca;
		border-radius: 10px;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		margin-right: 0.4rem;
		margin-left: 0.4rem;
		// height: 100%;
		.image {
			overflow: hidden;
			border-radius: 10px;
			img {
				width: 100%;
				height: 160px;
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-fit: center;
				object-fit: center;
				border-radius: 10px;
				transition: all 0.3s ease-in-out;
			}
			&:hover {
				img {
					-webkit-transform: scale(1.2);
					transform: scale(1.2);
				}
			}
		}
		.content {
			padding: 12px;
			// position: relative;
			// padding: 0.5rem 0.25rem 5.5rem 0.25rem;
			.location {
				font-size: 0.9rem;
				color: #919191;
				margin-bottom: 0.5rem;
				svg {
					display: inline-block;
					margin-inline-end: 0.25rem;
					width: 22px;
					height: auto;
					path {
						stroke: #919191;
					}
				}
			}
			.package_name {
				margin-bottom: 1rem;
				.package_title {
					font-size: 1.1rem;
					line-height: 1.5;
					font-weight: 700;
					color: #000000;
					a {
						display: inline-block;
						color: #000000;
						transition: all 0.3s ease-in-out;
						@include mixins.ellipsis;
						&:hover {
							color: rgb(var(--web-page-primary-color), 0.75);
						}
					}
				}
			}
			.package_meta {
				display: flex;
				flex-direction: column;
				gap: 12px;
				svg {
					display: inline-block;
					// margin-inline-start: 0.25rem;
					width: 20px;
					height: auto;
					path {
						stroke: rgb(var(--web-page-primary-color));
					}
				}
				.hotel_name,
				.package_date {
					font-size: 0.85rem;
					font-weight: 600;
					line-height: 1.5;
					@include mixins.ellipsis;
				}
			}
			.package_footer {
				// position: absolute;
				// bottom: 0.5rem;
				// right: 0.5rem;
				// left: 0.5rem;
				margin-top: auto;
				border-top: 1px solid #dedede;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
				padding-top: 1rem;
				margin-top: 1rem;

				.pricing {
					.from_text {
						font-size: 0.85rem;
						font-weight: 700;
						line-height: 1;
					}
					.actual_price {
						margin: 0;
						font-weight: 700;
						font-size: 22px;
						line-height: 1.2;
						color: rgb(var(--web-page-primary-color));
						span {
							font-weight: 500;
							font-size: 14px;
						}
					}
				}
				.cta {
					height: 100%;
					.theme_btn {
						display: inline-block;
						padding: 0.25rem 0.6rem;
					}
				}
			}
		}
	}


	.react-multi-carousel-list {
		padding-bottom: 3rem;
		.react-multi-carousel-dot-list {
			// direction: ltr;
			bottom: 0;
			gap: 6px;
			li {
				&.react-multi-carousel-dot--active {
					button {
						background-color: rgb(var(--web-page-primary-color));
						width: 14px;
						height: 14px;
					}
				}
				button {
					border: 0;
					margin: 0;
				}
			}
		}
	}

	.carousel-button-group {
		width: 100%;
		// background-color: #000;
		position: absolute;
		bottom: -2px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		justify-content: space-between;
		z-index: 3;

		.react-multiple-carousel__arrow {
			background-color: transparent;
			width: fit-content !important;
			height: auto !important;
			min-width: auto !important;
			min-height: auto !important;
			bottom: 0;

			&::before {
				display: none;
			}

			svg {
				path {
					stroke: #000000;
				}
			}
			&.react-multiple-carousel__arrow--left {
				left: 0;
				// svg {
				// 	-webkit-transform: scaleX(-1);
				// 	transform: scaleX(-1);
				// }
			}
			&.react-multiple-carousel__arrow--right {
				// margin-left: auto;
				right: 0;
			}
		}
	}


	@media screen and (max-width: 1199px) {
		// .theme_packages_section {
		// 	padding-right: 1rem;
		// 	padding-left: 1rem;
		// }
		// .carousel-button-group {
		// 	.react-multiple-carousel__arrow {
		// 		&.react-multiple-carousel__arrow--left {
		// 			left: 39%;
		// 		}
		// 		&.react-multiple-carousel__arrow--right {
		// 			right: 33%;
		// 		}
		// 	}
		// }
	}
	@media screen and (max-width: 991px) {
		// .carousel-button-group {
		// 	.react-multiple-carousel__arrow {
		// 		&.react-multiple-carousel__arrow--left {
		// 			left: 36%;
		// 		}
		// 		&.react-multiple-carousel__arrow--right {
		// 			right: 36.5%;
		// 		}
		// 	}
		// }
		.theme_packages_section {
			.section_content {
				padding-inline-start: 0;
				margin-top: 2rem;
			}
		}
	}
	@media screen and (max-width: 767px) {
		// .carousel-button-group {
		// 	.react-multiple-carousel__arrow {
		// 		&.react-multiple-carousel__arrow--left {
		// 			left: 32%;
		// 		}
		// 		&.react-multiple-carousel__arrow--right {
		// 			right: 33.5%;
		// 		}
		// 	}
		// }
	}
	@media screen and (max-width: 575px) {
		// .carousel-button-group {
		// 	.react-multiple-carousel__arrow {
		// 		&.react-multiple-carousel__arrow--left {
		// 			left: 26%;
		// 		}
		// 		&.react-multiple-carousel__arrow--right {
		// 			right: 27.5%;
		// 		}
		// 	}
		// }
	}
	@media screen and (max-width: 464px) {
		// .carousel-button-group {
		// 	.react-multiple-carousel__arrow {
		// 		&.react-multiple-carousel__arrow--left {
		// 			left: 15%;
		// 		}
		// 		&.react-multiple-carousel__arrow--right {
		// 			right: 16.5%;
		// 		}
		// 	}
		// }
	}
}
