@import "variables.scss";

.mirror-ltr {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

html {
  &:dir(rtl) {
    .mirror-rtl {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.text-primary-color {
  color: rgb(var(--web-page-primary-color)) !important;
  transition: all 0.3s linear;
  &.hover {
    &:hover {
      opacity: 0.7;
    }
  }
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.ms-2rem {
  margin-inline-start: 0.25rem !important;
}

.ms-3rem {
  margin-inline-start: 0.4rem !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.main-web-builder {
  background-color: white;
}

.text-secondary-color {
  color: var(--text-secondary-color) !important;
}

.safa_input_error {
  border: 1px solid #ed2222;
}

.web-builder-custom-modal {
  .web-builder-custom-modal-title {
    .modal-title {
      flex: 1;
      text-align: center;
    }
  }
}

.tools-main-upload {
  border: 3px dashed #dddcdc;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tools-header-primary {
    color: #707170;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Cairo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4.2rem;
  }

  .tools-header-secondary {
    color: #dddcdc;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Cairo;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }

  .tools-header-button {
    height: 3.2rem;
    padding: 0px 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #292d32;
    color: #fff;
  }

  .tools-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      color: #dddcdc;
      font-family: Cairo;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 18.5rem;
      height: 3rem;
      padding: 0px 16px;
      align-items: center;
      gap: 0.5rem;
      border-radius: 6px;
      border: 1px solid #dddcdc;
    }
  }

  .m-modal-2 {
    margin: 8px 0;
  }
  .m-modal-1 {
    margin: 3px 0;
  }
}

.web-builder-library {
  padding: 1rem;
  .web-builder-library-images {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .form-check {
    position: relative;
    padding: 0;
    flex-basis: calc(33.33% - 11px);
    border-radius: 2px;
    overflow: hidden;

    input {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 18px;
      height: 18px;
      padding: 10px;
      filter: invert(1%) hue-rotate(192deg) brightness(1.2);
    }
  }
  .form-check-label {
    margin: 0 !important;
  }
  .gallery-img {
    height: 130px;
    object-fit: cover;
    border: 2px solid transparent;
  }
  .b-multiSelect-image {
    border: 2px solid #d4a655;
  }
}

.nav-flex-1 {
  flex: 1;
}
.fixed-height {
  height: 72px !important;
  align-items: flex-end !important;
}

.f-a-end {
  height: 56px;
}
.toolModal-tabs {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    font-feature-settings: "clig" off, "liga" off;
    color: #707170 !important;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.w-upload-tabs {
  padding: 16px;
  gap: 8px;
  align-self: stretch;
}

.w-library-tabs {
  padding: 24px 16px;
  gap: 8px;
  align-self: stretch;
}
.search-form-select {
  div[class$="-menu"],
  div[class$="-singleValue"] {
    text-transform: capitalize;
  }
}

@media (min-width: 320px) and (max-width: 929px) {
  .w-30-tabs {
    width: 40% !important;
  }
  .tools-input {
    flex-direction: column;
    input {
      width: 100% !important;
      margin-bottom: 0.5rem !important;
    }
  }
  .f-a-end {
    width: 40% !important;
  }
}

.select-template {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding-top: 36px;
  margin-top: -3rem !important;
  .grid-template {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
  .template-name {
    margin-top: 1rem;
    padding: 8px 0;
    font-size: 18px;
    text-align: center;
    border-top: 1.5px solid #dddcdc;
    color: #707170;
  }
  .template-container {
    margin-bottom: 1rem;
    border: 1px solid #dddcdc;
    border-radius: 4px;
    padding: 10px;
  }

  .selectTemplate-heading {
    color: #000;
    font-family: Cairo;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .selectTemplate-text {
    color: #000;
    font-family: Cairo;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .selectTemplate-overlay {
    position: relative;
    border-radius: 4px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 462px;
    display: flex;
    flex-direction: column;
    height: 610px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 1rem;
    // cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      transition: visibility 0.35s, opacity 0.35s;
    }
    .overlay-button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 114px;
      height: 40px;
      padding: 0px 20px;
      align-items: center;
      background-color: #d4a655;
      color: #fff;
      margin-bottom: 8px;
      visibility: hidden;
      opacity: 0;
      z-index: 2;
      transition: visibility 0.35s, opacity 0.35s;
      svg path {
        stroke: #fff !important;
      }
    }
    &:hover::after,
    &:hover .overlay-button {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.35s, opacity 0.35s;
    }
    // .selectTemplate-img {
    // 	cursor: pointer;
    // 	height: 691px;
    // }
  }
}

.addTransition {
  transition: 1s all ease-in-out;
}

@media (min-width: 320px) and (max-width: 768px) {
  .select-template {
    margin-top: 0rem !important;
    .container {
      .row {
        width: auto !important;
      }
    }
  }
  .select-template .selectTemplate-heading {
    line-height: 30px;
    text-align: center;
  }

  .m-t-2 {
    margin-top: 1.5rem !important;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .web-builder-content .web-builder-hero .react-multiple-carousel__arrow {
    display: none;
  }
}

.search-form-web-builder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 10px;
  // background: rgba(255, 255, 255, 0.5);
  padding: 4px 7px;
  gap: 8px;
  max-width: 100%;
  width: 800px;
  margin: 0 auto;

  .search-form-select {
    min-width: 175px;
    width: 100%;
    .control-field__body {
      border-radius: 8px;
    }
  }

  .search-form-button {
    min-width: 110px;
    color: #fff;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    padding: 9px 16px;
    background-color: rgba(var(--web-page-primary-color));
    border-radius: 8px;
  }

  &.search-form-vertical {
    flex-wrap: wrap;
    .search-form-select {
      width: calc(50% - 8px);
    }
    .search-form-button {
      min-width: 100%;
    }
  }
  .search-service-hero {
    width: 100%;
    .tabs-list {
      display: flex;
      gap: 8px;
      justify-content: center;
      overflow-x: auto;
      .tabs-item {
        display: flex;
        gap: 6px;
        background-color: #fff;
        border-radius: 8px 8px 0px 0px;
        padding: 8px 20px;
        cursor: pointer;

        span {
          font-size: 14px;
          font-weight: 500;
          color: rgba(var(--web-page-primary-color));
        }
        svg {
          stroke: rgba(var(--web-page-primary-color));
          // fill: rgba(var(--web-page-primary-color));
        }
      }
      .active-item {
        background-color: rgba(var(--web-page-primary-color));
        span {
          font-size: 14px;
          font-weight: 500;
          color: #fff;
        }
        svg {
          stroke: #fff;
          // fill: rgba(var(--web-page-primary-color));
        }
      }
    }
    .tabs-form {
      background-color: rgba(0, 0, 0, 0.75);
      padding: 14px;
      border-radius: 10px;
    }
    .type {
      color: #fff !important;
      background-color: transparent !important;
      input {
        width: 20px;
        height: 20px;
        accent-color: rgba(var(--web-page-primary-color));
      }
    }
    .react-datepicker__navigation .react-datepicker__navigation-icon::before {
      width: 15px !important;
      height: 15px !important;
    }

    .control-field__body span,
    .control-field__body span {
      display: inline-block;
      text-align: start;
      width: 100%;
      color: rgba(151, 151, 151, 1);
      font-size: 12px;
      font-weight: 500 !important;
    }
    .control-field {
      border-radius: 8px !important;
    }
    .control-field-inner {
      height: auto !important;
    }
    .innerPadding {
      padding: 3px 10px !important;
    }
    .control-field__select {
      div[class$="placeholder"] {
        font-size: 14px;
        font-weight: 500;
        color: rgba(80, 80, 80, 1);
      }
    }
    .control-field__input,
    .control-field__input::placeholder,
    .DateInput_input,
    .DateInput_input::placeholder {
      font-size: 14px;
      font-weight: 500 !important;
      color: rgba(80, 80, 80, 1) !important;
    }

    .bg-nxt {
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
    }
    //   .fix_place_arrow,
    .icon-items-section-services {
      display: none !important;
    }
    .fix_place_arrow i {
      cursor: pointer;
      color: rgba(var(--web-page-primary-color));
    }

    .button-collapse {
      position: relative;

      .pax-container {
        position: absolute;
        background: white;
        z-index: 2;
        left: 0px;
        right: 0px;
        margin-inline: 0px;
        top: 47px;
        padding: 15px;
        border-radius: 8px;
        // border: 1px solid #f6f5f5;
        // box-shadow: 2px 2px 2px #f1f1f1;

        .adult-pax {
          display: flex;
          justify-content: space-between;
          width: 100%;

          p {
            color: #000;
            font-size: 15px;

            span {
              color: #5e778d;
              font-size: 15px;
            }
          }

          .action {
            display: flex;
            gap: 25px;

            button {
              color: rgba(var(--web-page-primary-color));

              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 2px solid rgba(var(--web-page-primary-color));
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 3px;
              font-weight: 600;
              cursor: pointer;

              span {
                margin-bottom: 3px;
              }
            }

            button:disabled {
              color: #d5dae3;
              border: 2px solid #d5dae3;
            }

            .adultNum {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }

        .childrensAge {
          .p_children {
            color: #5e778d;
            font-size: 15px;
          }
        }

        .children-age-select {
          p {
            font-size: 15px;
            font-weight: 500;
          }

          select {
            border: none;
            color: rgba(var(--web-page-primary-color));
          }
        }

        .childrensAge {
          .p_children {
            color: #5e778d;
            font-size: 15px;
          }
        }

        .children-age-select {
          p {
            font-size: 15px;
            font-weight: 500;
          }

          select {
            border: none;
            color: rgba(var(--web-page-primary-color));
          }
        }
      }

      .title-style-container {
        border-radius: 8px !important;
        padding: 7px 10px !important;

        .global-input {
          span {
            font-weight: 400 !important;
            color: rgba(151, 151, 151, 1) !important;
            text-align: start !important;
            font-size: 12px !important;
            font-weight: 500;
          }

          .roomsNumber1 {
            font-size: 14px !important;
            font-weight: 500 !important;
            color: rgba(80, 80, 80, 1) !important;
          }
        }
      }
    }
  }
}
.wpwl-container {
  position: relative;
  background: #f4f4f4 !important;
}
.flight-search {
  .control-field__body span,
  .control-field__body span {
    display: inline-block;
    text-align: start;
    width: 100%;
    color: rgba(151, 151, 151, 1);
    font-size: 12px;
    font-weight: 500 !important;
  }
  .control-field {
    border-radius: 8px !important;
  }
  .control-field-inner {
    height: auto !important;
  }
  .innerPadding {
    padding: 3px 10px !important;
  }
  .control-field__select {
    div[class$="placeholder"] {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: rgba(80, 80, 80, 1) !important;
    }
  }
  .control-field__input,
  .control-field__input::placeholder,
  .DateInput_input,
  .DateInput_input::placeholder {
    font-size: 14px;
    font-weight: 500 !important;
    color: rgba(80, 80, 80, 1) !important;
  }
  .button-collapse {
    position: relative;
    .pax-container {
      position: absolute;
      background: white;
      z-index: 2;
      left: 0px;
      right: 0px;
      margin-inline: 0px;
      top: 50px;
      padding: 15px;
      border: 1px solid #f6f5f5;
      // box-shadow: 2px 2px 2px #f1f1f1;

      .adult-pax {
        display: flex;
        justify-content: space-between;
        width: 100%;
        p {
          color: #000;
          font-size: 15px;
          span {
            color: #5e778d;
            font-size: 15px;
          }
        }
        .action {
          display: flex;
          gap: 25px;
          button {
            color: rgba(var(--web-page-primary-color));

            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid rgba(var(--web-page-primary-color));
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3px;
            font-weight: 600;
            cursor: pointer;

            span {
              margin-bottom: 3px;
            }
          }
          button:disabled {
            color: #d5dae3;
            border: 2px solid #d5dae3;
          }
          .adultNum {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
      .childrensAge {
        .p_children {
          color: #5e778d;
          font-size: 15px;
        }
      }
      .children-age-select {
        p {
          font-size: 15px;
          font-weight: 500;
        }
        select {
          border: none;
          color: rgba(var(--web-page-primary-color));
        }
      }

      .childrensAge {
        .p_children {
          color: #5e778d;
          font-size: 15px;
        }
      }

      .children-age-select {
        p {
          font-size: 15px;
          font-weight: 500;
        }

        select {
          border: none;
          color: rgba(var(--web-page-primary-color));
        }
      }
    }
    .title-style-container {
      border-radius: 8px !important;
      padding: 7px 10px !important;
      .global-input {
        span {
          font-weight: 400;
          color: rgba(151, 151, 151, 1);
          text-align: start !important;
          font-size: 12px;
          font-weight: 500;
        }
        .roomsNumber1 {
          font-size: 14px;
          font-weight: 500 !important;
          color: rgba(80, 80, 80, 1) !important;
        }
      }
    }
  }
  .bg-nxt {
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
  }
  //   .fix_place_arrow,
  .icon-items-section-services {
    display: none !important;
  }
  .fix_place_arrow i {
    cursor: pointer;
    color: rgba(var(--web-page-primary-color));
  }
}
.flight-modify-container {
  input[type="radio"] {
    width: 18px;
    height: 18px;
    accent-color: rgba(var(--web-page-primary-color));
  }
}
