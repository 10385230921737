.service-view-wrapper {
  .gap-1\/2 {
    gap: 0.5rem;
  }

  .gap-1 {
    gap: 1rem;
  }

  .gap-2 {
    gap: 2rem;
  }

  .gap-3 {
    gap: 3rem;
  }

  .service-view-container {
    margin-top: 30px;

    @media screen and (max-width: 1023px) {
      width: 100% !important;
      padding: 1rem;
    }

    .service-content, .service-request {
      contain: inline-size;
      //   flex-basis: calc(65.5% - 12px);
      //   max-width: calc(65.5% - 12px);

      //   @media screen and (max-width: 767px) {
      //     flex-basis: 100% !important;
      //     max-width: 100% !important;
      //   }
    }

    // .service-request {
    //   flex-basis: calc(34.5% - 12px);
    //   max-width: calc(34.5% - 12px);

    //   @media screen and (max-width: 767px) {
    //     flex-basis: 100% !important;
    //     max-width: 100% !important;
    //   }

    //   // >div:first-child {
    //   //   position: sticky;
    //   //   top: 10px;
    //   // }
    // }
  }

  .service-tabBar-wrapper {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 5;

    .service-tabBar {
      overflow-x: auto;

      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;

      overscroll-behavior: contain;

      .service-tabBar-tabs {
        display: flex;
        align-items: center;
        padding: 12px 0;
        gap: 20px;

        .tab {
          display: flex;
          gap: 6px;
          font-size: 18px;
          font-weight: 700;
          color: rgba(5, 0, 1, 1);
          cursor: pointer;
          min-width: max-content;
          align-items: center;

          transition: color 0.3s ease-in-out;

          svg {
            width: 24px;
            height: 24px;
            path {
              stroke: #292d32;
              transition: stroke 0.3s ease-in-out;
            }
          }
        }

        .active-tab {
          color: rgba(var(--web-page-primary-color));

          svg path {
            stroke: rgba(var(--web-page-primary-color));
          }
        }
      }

      .bar {
        position: relative;
        width: 100%;
        height: 3px;
        background-color: rgba(var(--web-page-primary-color), 0.2);
        width: 100%;
        border-radius: 6px;
        will-change: width;

        .active-bar {
          position: absolute;
          --position: 0;
          --width: 100px;
          background-color: rgba(var(--web-page-primary-color));
          transition: inset-inline-start 0.3s ease-in-out,
            width 0.3s ease-in-out;
          will-change: inset-inline-start, width;
          inset-inline-start: var(--position);
          width: var(--width);
          height: inherit;
          border-radius: 6px;
        }
      }
    }
  }

  .service-title {
    font-size: 22px;
    font-weight: 700;
    color: rgba(5, 0, 1, 1);
  }

  .gray-text {
    font-size: 16px;
    font-weight: 600;
    color: #707170;
  }

  .main-container {
    padding: 0px 55px;
  }

  .service-top-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;

    .info-holder {
      width: 100%;
      padding: 20px;
      border-radius: 12px;
      background: rgba(245, 248, 252, 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .info-row {
        color: rgba(112, 113, 112, 1);
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 8px;

        span {
          font-size: 16px;
          font-weight: 700;
          color: #050001;
        }
      }
    }
  }

  .bread-crumb-holder {
    padding: 24px;
    display: flex;
    justify-content: center;
    background-color: #f5f8fc;
    height: 80px;
    gap: 8px;

    .link-home {
      font-size: 18px;
      font-weight: 700;
      color: rgba(30, 133, 255, 1);
    }

    .link {
      font-size: 18px;
      font-weight: 400;
      color: rgba(112, 113, 112, 1);
    }
  }

  .facilities-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 16px;
    column-gap: 30px;

    li {
      span:has(svg) {
        display: flex;
        align-self: center;
      }

      display: flex;
      align-items: center;
      min-width: max-content;
      gap: 6px;
    }
  }

  .include-exclude-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    justify-content: space-between;

    > li ul {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    > li ul li {
      span:has(svg) {
        display: flex;
        align-self: center;
      }

      display: flex;
      align-items: center;
      min-width: max-content;
      gap: 6px;
    }
  }

  .flight-card {
    h2 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .flight-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .airport-info {
        display: flex;
        align-items: center;
        gap: 16px;

        .icon-container {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgba(var(--web-page-primary-color), 0.1);
          border: 1px solid rgba(var(--web-page-primary-color), 1);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          svg {
            path {
              stroke: rgb(var(--web-page-primary-color));
            }
          }
        }

        .airport-details {
          .label {
            font-size: 12px;
            color: rgba(var(--web-page-primary-color), 1);
            background-color: rgba(var(--web-page-primary-color), 0.1);
            padding: 4px 8px;
            border-radius: 20px;
            margin-bottom: 3px;
            display: block;
            width: fit-content;
            font-weight: 500;
          }

          .code {
            font-weight: 600;
            font-size: 16px;
            font-weight: bolder;
          }

          .name {
            font-size: 14px;
            color: #666;
            width: 110px;
          }
        }
      }

      .flight-path {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;

        .path-container {
          display: flex;
          align-items: center;

          svg {
            width: 100%;
            path {
              stroke: rgb(var(--web-page-primary-color));
            }
          }
        }
      }

      .verticle-flight-path {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      position: relative;
      
      .airport-info {
        width: 100%;
        .icon-container {
          display: none !important;
        }

        .airport-code {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          gap: .5rem;
        }
      }

      .airport-details {
        width: 100%;
        .name {
          width: 100% !important;
        }
      }

      .flight-path {
        display: none !important;
      }

      .verticle-flight-path {
        display: block !important;
        position: absolute;
        top: .75rem;
        left: 0;
      }
      
      .flight-content {
        flex-direction: column;
        gap: 1.8rem;
        padding-left: .8rem;
      }
      
    }
  }

  .hotel-card {
    display: flex;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &__image {
      width: 220px;
      max-width: 195px;
      margin-right: 1rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
      }
    }

    &__content {
      flex-grow: 1;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 4px;

      button {
        min-width: max-content;
        border: 1px solid #dddcdc;
        color: #707170;
      }
    }

    &__title {
      font-size: 1.125rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    &__subtitle {
      font-size: 0.875rem;
      color: #4b5563;
    }

    &__gallery-button {
      display: flex;
      align-items: center;
      color: #2563eb;
      font-size: 0.875rem;

      svg {
        margin-right: 0.25rem;
      }
    }

    &__rating {
      display: flex;
      margin-top: 0.5rem;

      svg {
        width: 1rem;
        height: 1rem;
        fill: #f59e0b;

        &.empty {
          fill: #d1d5db;
        }
      }
    }

    &__location,
    &__stay-duration {
      display: flex;
      align-items: center;
      margin-top: 0.75rem;
      font-size: 0.875rem;
      color: #4b5563;

      svg {
        margin-right: 0.25rem;
      }
    }

    &__stay-duration {
      margin-top: 0.25rem;
    }
  }

  .service-card {
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    &__header {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &__details {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      &__item {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
    }

    &__image {
      width: 220px;
      max-height: 195px;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
        aspect-ratio: 3 / 2;
      }
    }

    &__content {
      flex-grow: 1;
    }

    &__title {
      font-weight: 600;
    }

    &__sub-title {
      font-weight: 400;
      color: #4b5563;
    }

    &__rating {
      margin-bottom: 1rem;
    }

    &__location,
    &__stay-duration {
      display: flex;
      align-items: center;
      color: #4b5563;
      gap: 0.3rem;
      font-size: 0.875rem;
    }

    &__location {
      margin-bottom: 0.2rem;
    }

    &__tabs {
      background-color: rgba(245, 248, 252, 1);
      margin-block: 1rem;
    }

    &__tab-list {
      display: flex;
      flex-wrap: nowrap;
      gap: 0.7rem;
      overflow: auto;

      > li {
        min-width: max-content;
      }
    }

    &__tab {
      outline: none;
      border: none;
      background-color: transparent;
      padding: 0.7rem 1rem;
      border-bottom: 2px solid transparent;
      color: rgba(112, 113, 112, 1);
      font-weight: 400;
      cursor: pointer;

      &.active {
        border-bottom-color: rgba(30, 133, 255, 1);
        color: rgba(30, 133, 255, 1);
        font-weight: 600;
      }
    }

    &__description {
      &__label {
        font-size: 1.25rem;
        margin-bottom: 0.7rem;

        &.cancelation {
          color: rgba(234, 84, 85, 1);
          font-weight: 700;
          font-size: 1rem;
        }
      }

      &__value {
        color: rgba(112, 113, 112, 1);
      }
    }

    &__amenties {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: space-between;

      &__amenity {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: rgba(112, 113, 112, 1);
        width: fit-content;

        svg {
          width: 24px;
          path {
            stroke: #292d32;
          }
        }
      }
    }

    &__tags-wrapper {
      display: flex;
      align-items: center;
      gap: 0.4rem;
    }

    &__tag,
    &__tag-rating {
      width: fit-content;
      padding: 0.25rem 1rem;
      background-color: rgba(30, 133, 255, 1);
      color: white;
      border-radius: 90px;
      border: 1px solid rgba(30, 133, 255, 1);
      text-transform: capitalize;

      &.green {
        color: rgba(1, 157, 76, 1);
        background-color: rgba(238, 250, 244, 1);
        border-color: rgba(218, 234, 226, 1);
      }

      &.blue {
        color: rgba(30, 133, 255, 1);
        background-color: rgba(245, 248, 252, 1);
        border-color: rgba(186, 219, 238, 1);
      }
    }

    &__tag-rating {
      display: none;
    }

    &__gallery {
      &__list {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        li {
          max-width: 150px;
          width: 150px;
          max-height: 146px;
          height: 146px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__view-all {
          button {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            background-color: #f7f6f6;
            color: rgba(30, 133, 255, 1);
            display: flex;
            gap: 0.2rem;
            align-items: center;

            svg {
              path {
                stroke: rgba(30, 133, 255, 1);
              }
            }
          }
        }
      }
    }

    &__tab-content-item {
      animation: fade-in 1s ease-in-out;
    }

    &__include-execlude-item {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }

    .carousel-left-arrow,
    .carousel-right-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;
      border: none;
      outline: none;
    }

    .react-multi-carousel-item {
      padding-inline: 16px;
      height: 500px;

      img {
        cursor: grab;
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        width: calc(100% - 50px);
        height: 100%;
        object-fit: cover;

        &:active {
          cursor: grabbing;
        }
      }
    }

    .carousel-left-arrow {
      left: 0;
    }
    .carousel-right-arrow {
      right: 0;
    }

    .react-multi-carousel-list {
      max-height: 400px;
      height: 400px;
    }

    @media screen and (max-width: 767px) {
      padding: 0;
      border: none;
      box-shadow: none;

      &__content {
        width: 100%;
      }

      &__header {
        font-size: 20px;
      }

      &__header {
        flex-direction: column;
      }

      &__image {
        width: 100%;
        img {
          height: 195px;
        }
      }

      &__rating {
        margin-top: .5rem;
        display: flex;
        gap: .5rem;
        justify-content: space-between;

        svg {
          --size: 18px;
          width: var(--size);
          height: var(--size);
        }
      }
      
      &__tag {
        display: none !important;
      }

      &__tags-wrapper {
        flex-wrap: wrap;
        .service-card__tag {
          display: block !important;
          font-size: 1rem;
        }
      }

      &__tag-rating {
        display: block !important;
        font-size: 1rem;
      }
    }
  }

  .attraction-card {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      &__title {
        h2 {
          font-size: 22px;
          font-weight: 700;
          color: rgba(5, 0, 1, 1);
        }
      }

      &__gallery-button {
        outline: none;
        border: 1px solid rgba(221, 220, 220, 1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        color: rgba(112, 113, 112, 1);
      }
    }

    &__card-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1rem;
    }

    &__card {
      display: flex;
      gap: 1rem;
      padding: 0.625rem;
      border: 1px solid rgba(226, 232, 240, 1);
      border-radius: 8px;

      &__icon {
        --size: 60px;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: rgba(245, 248, 252, 1);
        display: grid;
        place-content: center;
      }

      &__title {
        margin-bottom: 1.5rem;

        h3 {
          font-weight: 600;
          color: rgba(5, 0, 1, 1);
        }
      }

      &__location,
      &__stay-duration {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }

      &__location {
        margin-bottom: 0.2rem;
      }
    }
  }

  .flight-service-page {
    width: 100%;

    .content-wrapper {
      position: relative;

      .content-image {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
          filter: brightness(0.7);
        }
      }

      .content-details {
        position: relative;
        z-index: 99;
        min-height: 100vh;
        display: grid;
        place-content: center;
        padding: 2rem;
        max-width: 1080px;
        margin-inline: auto;
      }
    }

    .form-wrapper {
      background-color: rgba(0, 0, 0, 0.48);
      padding: 20px;
      border-radius: 10px;
      backdrop-filter: blur(5px);

      .form-card-header {
        text-align: center;
        color: white;

        h2 {
          font-size: 2rem;
          text-transform: capitalize;
        }
      }

      .radio-btn-group {
        margin-top: 2.5rem;

        display: flex;
        gap: 10px;
        align-items: center;

        .radio-btn {
          display: flex;
          gap: 10px;
          align-items: center;

          label {
            margin: 0;
            color: white;
            cursor: pointer;
          }

          input {
            --size: 1.5rem;
            width: var(--size);
            height: var(--size);
          }
        }
      }

      form {
        .textarea-col {
          .control-field-inner {
            height: 100px;
          }
        }

        .submit-button {
          font-size: 1rem;
          font-weight: 700;
        }

        .add-more-destination-btn {
          background-color: white !important;
          border: 1px solid rgba(var(--web-page-primary-color)) !important;
          color: rgba(var(--web-page-primary-color)) !important;
          font-size: 1rem !important;
          padding: 0.7rem 1.3rem !important;
          border-radius: 8px;

          svg path {
            stroke: rgba(var(--web-page-primary-color));
          }
        }

        // .remove-destination-btn {
        //   height: 54px !important;
        // }
      }
      .fix_place_arrow {
        top: 25px !important;
        inset-inline-end: 7px;
      }
    }
  }

  .hotel-cards-list,
  .flight-cards-list,
  .attraction-cards-list,
  .transportation-cards-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    hr {
      width: 100%;
      background-color: #f5f8fc;
    }
  }

  .pax-selector {
    position: relative;
    width: 100%;
    margin-block: 0.25rem;

    &__button {
      width: 100%;
      background-color: white;
      border-radius: 5px;
      padding: 6px 10px;
      text-align: left;
      border: none;
      cursor: pointer;
      outline: 1px solid transparent;

      &:focus {
        outline: 1px solid rgba(var(--web-page-primary-color));
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__label {
      font-size: 12px;
      color: black;
    }

    &__value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
    }

    &__chevron {
      width: 1.25rem;
      height: 1.25rem;
      color: #9ca3af;
      margin-left: 0.5rem;
    }

    &__dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      min-width: fit-content;
      margin-top: 0.5rem;
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      padding: 1rem;
      z-index: 999;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      gap: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__controls {
      display: flex;
      align-items: center;
    }

    &__control-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      background-color: #e5e7eb;
      border-radius: 9999px;
      border: none;
      cursor: pointer;
      padding: 0 !important;
      transition: all;

      &:hover {
        background-color: #d1d5db;
      }

      &:disabled {
        cursor: not-allowed;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }

    &__icon {
      width: 1rem;
      height: 1rem;
      color: #4b5563;
    }

    &__count {
      margin: 0 0.75rem;
    }

    &__error-message {
      font-size: 0.75rem;
      color: #ef4444;
    }
  }

  .policy {
    .cancelation {
      color: #ea5455;
      font-size: 1rem;
      font-weight: 800;
    }
  }

  .rating {
    display: flex;
    align-items: center;
    gap: 4px;

    .star {
      font-size: 14px;
      color: #ffc649;
    }
  }

  .content-card-collapsable {
    padding: 1rem 1.25rem;
    border-radius: 12px;
    border: 1px solid rgba(186, 219, 238, 1);

    &__button {
      outline: none;
      border: none;
      background-color: transparent;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: .5rem;
    }

    &__title {
      color: #222222;
      font-weight: 700;
      font-size: 1.375rem;
    }

    &__icon {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0deg);
      &.open {
        transform: rotate(180deg);
      }

      svg {
        --size: 1.5rem;
        width: var(--size);
        height: var(--size);
      }
    }
  }

  .content-wrapper-holder {
    padding: 30px 20px 30px 20px;
    border-radius: 12px;
    border: 1px solid rgba(186, 219, 238, 1);

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
    }

    .card-body-text {
      font-size: 16px;
      font-weight: 600;
      color: #707170;
      white-space: pre-line;
      word-break: break-word;
    }
  }

  .service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 12px;
    .icons-holder {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 32px;

      span {
        font-size: 18px;
        font-weight: 400;
        color: rgba(112, 113, 112, 1);
      }
    }

    @media screen and (max-width: 767px) {
      gap: 1rem;

      .service-info, .title-holder {
        width: 100%;
      }

      .tag {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        border-radius: 30px;
        border: 1.5px solid rgba(var(--web-page-primary-color), .09);
        background-color: rgba(var(--web-page-primary-color), .05);
        color: rgba(var(--web-page-primary-color));
      }

      .icons-holder {
        display: none;
      }

      .price-holder {
        display: none;
      }
    }
  }

  .price-holder {
    min-width: 33%;
    padding: 20px 10px 20px 10px;
    border-radius: 4px;
    border: 1px solid rgba(186, 219, 238, 1);
    background-color: #ffffff;

    .price-info {
      font-size: 28px;
      font-weight: 700;
      color: rgba(5, 0, 1, 1);
      line-height: 24px;

      span {
        font-size: 18px;
        font-weight: 400;
        color: rgba(112, 113, 112, 1);
      }
    }
  }

  .service-bottom-card {
    position: fixed;
    bottom: 80px;
    left: 10px;
    right: 10px;
    background-color: #f5f8fc;
    border-radius: 4px;
    padding: 10px;
    z-index: 10;
    border: 1px solid rgba(223, 231, 239, 1);
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    .price-info {
      .price {
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
      }

      .currency,
      .price-per {
        color: rgba(30, 133, 255, 1);
        font-size: 14px;
      }

      .currency {
        margin-inline-start: 3px
      }
    }
    
    .req-form-btn {
      height: 100%;
      font-size: 14px;
    }
  }

  .request-form-holder {
    margin-top: 24px;
    border: 1px solid rgba(226, 232, 240, 1);
    border-radius: 4px 4px 0px 0px;

    .form-header {
      background: rgba(245, 248, 252, 1);
      padding: 24px 20px 24px 20px;
      border-radius: 4px 4px 0px 0px;
    }
  }

  .request-form {
    background-color: #fff;
    border: 1px solid #f7f6f6;
    border-radius: 8px;
    box-shadow: 0px 0px 14px 0px #00000005;

    .header {
      padding: 12px 20px;
      margin-bottom: 12px;
      border-radius: 8px 8px 0 0;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      background-color: rgba(var(--web-page-primary-color));
    }

    .row:not(.phone-row) {
      padding: 0 14px 16px;
    }

    #form-adults,
    #form-children {
      border: none;
      text-align: center;
      width: 35px;
    }

    .btn:disabled {
      background: transparent !important;
    }

    .pax-box {
      border-radius: 6px;
      border: 1px solid #dbdade;
      padding: 7px 14px;
    }

    .textarea {
      width: 100%;
      padding: 7px 10px;
      border-radius: 6px;
      border: 1px solid #dbdade;
      background-color: #fff;
    }

    .rooms-input-container {
      position: relative;
      max-width: 100%;
      margin-block: 0.25rem;
      padding: 9px 14px;
      border-radius: 6px;
      background-color: #ffffff;
      border: 1px solid #dbdade;

      .placeholder {
        font-size: 15px;
        font-weight: 400;
        color: #2d2d2d;
        margin-block: 6px;
      }

      .rooms-dropdown {
        position: absolute;
        width: calc(100% + 4px);
        top: calc(100% + 4px);
        inset-inline: -2px;
        padding: 10px;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0px 0px 35px 0px #0000000d;
        // box-shadow: 0px 0px 35px 0px #000000;
        z-index: 3;
      }

      .submit-rooms-btn {
        padding: 10px 24px;
        border: 1px solid rgba(var(--web-page-primary-color));
        background-color: rgba(var(--web-page-primary-color));
        border-radius: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
    }

    .service-room-input-container {
      position: relative;
      .rooms-input-header {
        display: flex;
        align-items: center;
        padding: 12px 9px;
        background-color: #ffffff;
        border: 1px solid #dbdade;
        border-radius: 6px;
        color: #4b465c99;
        gap: 8px;
        cursor: pointer;

        .arrow-icon {
          margin-inline-start: auto;
        }
      }

      .rooms-dropdown {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 10px 10px 0;
        border: 1px solid #dddcdc;
        background-color: #ffffff;
        box-shadow: 0px 0px 35px 0px #0000001a;
        border-radius: 6px;
        z-index: 9;

        &.opened {
          display: block;
        }
      }
      .rooms-count {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 14px;
        border-radius: 6px;
        background-color: #f7f6f6;
        gap: 10px;
      }
      .rooms-list {
        width: 100%;
        max-height: 400px;
        padding-bottom: 24px;
        overflow-y: auto;
      }
      .add-rooms {
        display: flex;
        justify-content: flex-end;
        padding: 8px 10px;
        margin-inline: -10px;
        background-color: #f7f6f6;

        .btn:disabled {
          background: #707170 !important;
        }
      }
      .control-field__label {
        color: #050001;
      }
      .custom-select-text {
        color: rgba(var(--web-page-primary-color)) !important;
      }
    }

    & [class$="-placeholder"] {
      color: #4b465c99 !important;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      margin: 0 10px !important;
    }
  }

  .submit-btn {
    padding: 8px 32px;
    border-radius: 6px;
    background-color: rgba(var(--web-page-primary-color));
    font-size: 1rem;
    font-weight: 700;
    color: #fefeff;
  }

  .textarea {
    width: 100%;
    padding: 7px 10px;
    border-radius: 6px;
    border: 1px solid #dbdade;
    background-color: #fff;
  }

  .prices {
    width: 100%;
    padding: 6px 10px;
    // background-color: rgb(245, 248, 252);
    background-color: rgba(var(--web-page-primary-color), 0.05);
    border-radius: 6px;
    color: rgba(var(--web-page-primary-color));

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
    }

    .sub-text {
      font-size: 16px;
      color: #000;
    }

    .total-price {
      font-weight: 700;
      font-size: 18px;
      line-height: 34px;
      color: #050001;
    }

    svg path {
      stroke: rgba(var(--web-page-primary-color));
      fill: rgba(var(--web-page-primary-color), 0.102);
    }
  }

  .service-carousel {
    .react-multi-carousel-list {
      max-height: 500px;
      border-radius: 10px;

      .react-multiple-carousel__arrow--left,
      .react-multiple-carousel__arrow--right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px !important;
        height: 35px !important;
        border-radius: 50%;
        border: 1px solid transparent;
        z-index: 2 !important;
        background-color: rgba(var(--web-page-primary-color));

        &::before {
          content: none;
        }
      }
    }

    .hotel-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      cursor: grab !important;

      &:active {
        cursor: grabbing !important;
      }
    }
  }

  .required-docs {
    max-width: 100%;
    padding: 30px 20px;
    background-color: rgba(var(--web-page-primary-color), .05);
    border: 1px solid rgba(var(--web-page-primary-color), .3);
    border-radius: 8px;
    word-break: break-word;
    white-space: pre-line;

    svg path {
      stroke: rgba(var(--web-page-primary-color));
    }
  }

  @media screen and (min-width: 0px) and (max-width: 1023px) {
    .service-top-section {
      grid-template-columns: 1fr;
    }

    .main-container {
      padding: 0px 25px;
    }
  }

  @media screen and (min-width: 1023px) and (max-width: 1440px) {
    .service-top-section {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .react-multi-carousel-list {
      max-height: 340px;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flight-details-collapse {
  .flight-details-collapse-btn {
    font-size: 1rem;
    color: white;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    will-change: transform;
  }

  .flight-details-collapse-title {
    margin-inline-end: .5rem;
  }

  .flight-details-collapse-icon {
    display: flex;
    align-items: center;
    transform: rotate(180deg);
    transition: 0.3s;

    &.open {
      transform: rotate(0deg);
    }
  }

  .card {
    background-color: transparent;
    border: none;
    color: white;
    margin-top: .5rem;
  }

  .flight-details-collapse-body {
    padding: 0;
  }
}

.form-card-header {
  .start-price {
    font-size: 11px;
    position: relative;
    top: 50%;
    right: -16px;
    transform: translateY(-50%);
    
    @media screen and (min-width: 500px) {
      font-size: 16px;
      right: -10px;
    }

    @media screen and (min-width: 768px) {
      right: 0;
    }
  }
}