@import "variables.scss";

@mixin colorStyle($color) {
	background-color: $color;
	border: 1px solid black;
	width: 26px;
	height: 26px;
	border-radius: 50%;
}
@mixin shapeStyle($border) {
	width: 32px;
	height: 32px;
	border-radius: $border;
	border: 1px solid #707170;
	background-color: #f7f6f6;
	align-self: center;
}

.web-builder-header {
	display: flex;
	padding: 16px 24px;
	gap: 8px;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid;
	border-color: var(--border-color);
	.header-publish {
		padding: 8px 32px;
		font-weight: 700;
		background-color: var(--primary-color);
		color: white;
		border: none;
		border-radius: 8px;
	}
	.header-eye-icon {
		margin-inline-end: 4px;
	}
	.header-preview {
		padding: 8px 16px;
		background: linear-gradient(0deg, #eed9ba, #eed9ba),
			linear-gradient(0deg, #faf4eb, #faf4eb);
		border: 1px solid #eed9ba;
		border-radius: 8px;
		color: #6a4d1b;
	}
	.url-icon {
		padding: 9px 10px;
		border-radius: 6px;
		background: #FAF4EB;
	}
}
.web-builder-sidebar {
	position: sticky;
	top: 73px;
	height: calc(100vh - 73px);
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	border-inline-end: 1px solid;
	border-color: var(--border-color);
	.content-header {
		padding: 30px 28px;
		border-bottom: 1px solid;
		border-color: var(--border-color);
		width: 100%;
		h4 {
			font-size: 18px;
			font-weight: 700;
			line-height: 22px;
		}
	}
	.sidebar-nav {
		padding: 40px 16px;
		flex: 0 1 auto;
		display: flex;
		flex-direction: column;
		border-inline-end: 1px solid;
		border-color: var(--border-color);
		button {
			padding: 9px 10px;
			border-radius: 6px;
			margin-bottom: 10px;
			border: none;
			background: none;
			&.active {
				background: #faf4eb;
				path {
					stroke: var(--primary-color);
				}
			}
		}
	}
	.sidebar-content {
		flex: 1 auto;
		/////// content blocks //////
		.sidebar-content-blocks {
			overflow: scroll;
			height: 100%;
			.content-blocks {
				padding: 20px 14px;
				gap: 8px;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				
				.image-container {
					padding: 24px 16px;
					border-radius: 4px;
					gap: 8px;
					background: linear-gradient(0deg, #f7f6f6, #f7f6f6),
						linear-gradient(0deg, #fbfbfa, #fbfbfa);
					border: 1px solid;
					border-color: var(--border-color);
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
			.content-forms {
				.form-title {
					padding: 20px 30px;
					color: #aeaeae;
				}
				.form-details {
					padding-inline: 30px;

					.form-element {
						padding-block: 8px;
						span {
							margin-inline-start: 8px;
						}
					}
				}
			}
		}
	}
	.sidebar-pages {
		.pages-details {
			padding: 20px 30px;

			.pages-element {
				padding-block: 8px;
				span {
					margin-inline-start: 8px;
				}
			}
		}
		.menu-nav-list {
			.nav-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin-top: 0.5rem;
				padding: 9px 16px;
				border-radius: 6px;
				background-color: #F5F5F5;
				gap: 8px;
				.drag-icon {
					width: 24px;
					height: 24px;
					max-width: 24px;
				}
				.btn {
					background: transparent !important;
					border: 0 !important;
					transition: none !important;
				}
				.btn:disabled {
					background: transparent !important;
					opacity: 0.2;
					border: 0 !important;
					transition: none !important;
					cursor: not-allowed;
				}
			}
			.sub-nav-item {
				margin-inline-start: 1rem;
			}
			.delete-item {
				svg path {
					stroke: #707170;
				}
			}
		}
	}
	.sidebar-style {
		.style-color {
			padding: 16px 40px;
			.initial-colors {
				display: flex;
				gap: 16px;
				margin-bottom: 16px;
			}

			.red-color {
				@include colorStyle(rgba(var(--red-color)));
			}
			.yellow-color {
				@include colorStyle(rgba(var(--yellow-color)));
			}
			.green-color {
				@include colorStyle(rgba(var(--green-color)));
			}
			.blue-color {
				@include colorStyle(rgba(var(--blue-color)));
			}
			.purple-color {
				@include colorStyle(rgba(var(--purple-color)));
			}

			.color-input {
				display: flex;
				gap: 16px;
				input {
					width: 80px;
					border-color: lightgray;
					border-radius: 4px;
					padding-inline: 2px;
				}
			}
		}

		.style-font {
			padding: 16px 20px;
		}
		.style-shape {
			padding: 16px 20px;
			fieldset {
				display: flex;
				gap: 8px;
				input {
					align-self: flex-end;
				}

				.shape {
					@include shapeStyle(0px);
				}
				.rounded {
					@include shapeStyle(6px);
				}

				label {
					gap: 8px;
					padding: 8px 10px;
					border-radius: 8px;
					border: 1px solid;
					border-color: #dddcdc;
					display: flex;
					flex-direction: column;
					flex: 1;
					&:has(input[type="radio"]:checked) {
						border-color: var(--primary-color);
					}
				}
			}
		}
		.collapse-header {
			display: flex;
			justify-content: space-between;
			padding: 12px 16px;

			.collapse-name {
				display: flex;
				gap: 8px;
			}
			.toggle-collapse {
				background: none;
				border: none;
			}
		}
	}
}
