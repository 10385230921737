.main-filter {
	display: flex;
	padding: 1rem 0.5rem;
	background-color: $white;
	.main-label {
		position: relative;
		margin-bottom: 0;
		width: 100%;
	}
	.main-input-search {
		border: 1px solid $gray-200;
		height: 35px;
		font-size: $text-caption - 1;
		padding: 0 0.3rem;
		width: 100%;
		outline: none;
		margin: 0 0.2rem;
		color: #757575;
		border-radius: 5px;
		overflow: hidden !important;
		box-shadow: none;
	}
	.reset-filter {
		display: flex;
		align-items: center;
	}
} //main-filter
