@media (min-width: 321px) and (max-width: 576px) {
	.login-page,
	.register-page,
	.reset-password {
		padding: 0 1.5rem;
	}
	.login-page-data,
	.register-page,
	.reset-password {
		img.safa_logo {
			display: block !important;
			margin: 2rem auto;
		}
	}

	.reset-password-bg {
		display: none;
	}

	.notification {
		.notifaction-items-header {
			right: auto !important;
			left: 0 !important;
			position: fixed !important;
			top: 7px !important;
		}
	}
	html[dir="ltr"] .notification .notifaction-items-header {
		right: -100px !important;
		left: auto !important;
	}
	.container-custom-md-modal,
	.container-custom-lg-modal {
		max-width: 320px !important;
	}
	.modal-dialog {
		padding: 30px;
		width: auto !important;
		min-width: 290px !important;
	}
	.inventory-header-totals {
		flex-wrap: wrap;
		.totals_container {
			flex-wrap: wrap;
			justify-content: flex-start !important;
			span {
				margin: 0.25rem !important;
			}
		}
	}

	.inventory {
		.apply,
		.clear {
			button {
				padding: 5px 10px !important;
			}
		}
	}
	.market-search-guide-btn {
		display: none !important;
	}
	.SingleDatePicker_picker {
		// zoom:0.7 !important
	}
	.DayPicker.DayPicker__horizontal {
		zoom: 0.5 !important;
	}
}
@media (min-width: 320px) and (max-width: 425px) {
	.company-profile-safa-tab-img {
		display: none !important;
	}
	.company-profile-w-100-column {
		flex-direction: column !important;
	}
	.company-profile-w-100-column .d-flex {
		width: 100% !important;
	}
	.company-profile-visa-success {
		h1,
		p {
			font-size: 18px !important;
		}
	}
	
}
.lang-dropdown-mobile{
	// margin-inline: 4px;
	background-color: #FEFEFF;
	// border: 1px solid #F7F6F6;
	border-radius:8px ;
	button{
		padding: 1px 0;
		 div{
			padding-inline:4px;
		 }
	}

	.dropdown-toggle::after {
		display: none !important;
		
	}
}
.notification-mobile{
	background-color: #FEFEFF;
	// border: 1px solid #F7F6F6;
	border-radius:8px ;
	// padding-inline: 50px;
	.custom-notification{
		padding: 12px 15px;
	}
	
}
.notification-mobile.dropdown {
	button{

		padding: 0  !important;
	}
}
.currency-switcher-mobile{
	background-color: #FEFEFF;
	border-radius:8px ;
	padding: 9px;

}
.setting-dropdown-mobile{
	background-color: #FEFEFF	;
	border: 1px solid #F7F6F6;
	border-radius:8px ;
	padding: 0px 4px;
	.dropdown-toggle::after{
		display: none !important;
	}
}
@media (min-width: 320px) and (max-width: 600px){
	.navbar{
	padding: 0.5rem;
	}
	.container-fluid{
		padding-inline: 5px !important;
	}
	// .navbar .dropdowns{
	// 	.currency-switcher,.wallet-icon,.setting-dropdown{
	// 		display: none !important;
	// 	}
	// }
}
@media (min-width: 320px) and (max-width: 420px){
	.navbar-brand-icon{
		margin: 0px 30px !important;
	}
	.notification{
		display: inline-block !important;
	}
}
@media (min-width: 320px) and (max-width: 370px){
	.navbar{
		.dropdowns{
			gap:2px !important
		}
	}
}


@media(min-width:700px){
	.navbar-brand-icon-mobile{
		display: none !important;
	}
}
.visibility-hidden{
	visibility: hidden;
}