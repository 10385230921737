.hotel-info {
	.nav-link {
		background: transparent;
	}
}

.hotel-location,
.hotel-rooms,
.hotel-meals,
.hotel-facilities,
.hotel-photos {
	margin: 1rem 0;
}

.icon-plus {
	background: #00b437;
	text-align: center;
	border-radius: 50%;
	color: #fff;
	font-size: 12px;
	margin-inline-start: 0.3rem;
	cursor: pointer;
	width: 25px;
	height: 25px;
	line-height: 25px;
}

.hotel-photos {
	.img-box {
		width: 100%;
		height: 143px;
		background: #f4f4f4;
		margin: 0.5rem 0;
		border: 1px solid #eae8e9;
		border-radius: 10px;
		padding: 1rem;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
