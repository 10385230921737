.theme-3 {
  .whatsapp-btn {
    z-index: 2 !important;
  }

  .header-nav {
    &.nav-opened {
      @media screen and (max-width: 991px) {
        padding-bottom: 100px;
      }
    }

    .header-nav__call {
      position: absolute;
      bottom: 4rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 60px;
      height: 60px;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }

  .footer-temp-two {
    .footerTempTwo-links {
      ul {
        text-align: start;
      }
    }
  }




}



@media screen and (max-width: 992px) {
  html {
    &:dir(rtl) {
      .theme-3 {
        &.web-builder-preview {
          .web-builder-hero-header {
            .nav-container {
              margin-right: auto;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .theme-3 {
    .header-nav {
      .header-nav__call {
        display: flex;
      }
    }
  }
}


.close-navbar {
  border: 0;
  background-color: transparent;
  display: block !important;
  top: 16px;
  left: 36px;

}
