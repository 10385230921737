.tours-list {
	position: relative;
}
.tours-search-wrapper {
	min-height: 185px;
	padding: 60px 15px;
	background-size: cover;
	display: -webkit-flex;
	display: flex;
	background-position: center;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
	-webkit-align-items: center;
	align-items: center;
	margin-top: -50px;
	background-image: url("../../../../src/assets/images/tours-list-bg.png");
	background-repeat: no-repeat;
}
.tours-search-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// flex-wrap: wrap;
	width: 100%;
	gap: 0.4rem;
	padding: 0.4rem;
	border-radius: 5px;
	background-color: #fff;
}
.tours-search-bar > div {
	flex-grow: 1;
	max-width: 20%;
}
.tours-topbar-container .toggle-sort {
	padding: 0 5px;
	font-size: 0.85rem;
}

.tours-topbar-container .toggle-sort i {
	color: #979797;
}

.tour-card {
	position: relative;
	display: flex;
	background-color: #fff;
	margin: 1rem 0;
	padding: 0.8rem;
	padding-right: 0;
	padding-bottom: 0;
	border: 1px solid #ebebeb;
}
[dir="rtl"] .tour-card {
	padding: 0.8rem;
	padding-left: 0;
	padding-bottom: 0;
}
.tour-card-img {
	height: 150px;
	flex-basis: 220px;
	flex-grow: 0;
	flex-shrink: 0;
	overflow: hidden;
}
.tour-card-img img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}
.tour-card-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 0.85rem;
}
.tour-card-info-price {
	font-size: 1rem;
	padding: 0 0.8rem;
	border: 1px solid #ebebeb;
	margin-left: auto;
}
[dir="rtl"] .tour-card-info-price {
	margin-left: initial;
	margin-right: auto;
}
.tour-card-info-price .total {
	font-size: 1.25rem;
	margin-top: -5px;
}

// single tour
.tour-details-info {
	padding: 0.85rem;
	margin-bottom: 1rem;
	border-radius: 10px;
	background-color: #f1f4f7;
}
.tour-details-caution {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	padding: 0.6rem;
	font-size: 0.9rem;
	border-radius: 10px;
	background-color: #ffffff;
	color: #2d2d2d;
}
.tour-details-about .show-full-desc {
	display: inline-block;
	margin-top: 0.6rem;
	text-decoration: underline;
	text-transform: capitalize;
	font-weight: bold;
	color: #0850ad;
	user-select: none;
	cursor: pointer;
}

.tour-details-searchavailibality {
	padding: 0.85rem;
	background-color: #e8f2ff;
	border-radius: 10px;
}
.tour-details-searchavailibality .row {
	margin: 0 -5px;
}
.tour-details-selections {
	padding: 0.85rem;
	margin-top: 1rem;
	background-color: #f1f4f7;
	border-radius: 10px;
}
.tour-details .price {
	color: #027b30;
}
// tour select dates
.tour-details-dates-calender {
	--weekDaysGap: 5px;
	--weekDayWidth: calc((100% / 7) - var(--weekDaysGap));
	padding: 0.85rem;
	background-color: #fff;
	border: 1px solid #e8eaea;
	color: #a0a0a0;
}

.tour-details-dates-calender-weekdayes ul,
.tour-details-dates-calender-days ul {
	margin-top: 0.7rem;
	gap: var(--weekDaysGap);
}

.tour-details-dates-calender-weekdayes li,
.tour-details-dates-calender-days li {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: var(--weekDayWidth) 0 0;
	max-width: var(--weekDayWidth);
	padding: 5px 0;
	margin-top: 0.4rem;
	text-align: center;
	font-size: 0.85rem;
	color: #000;
	cursor: pointer;
	aspect-ratio: 1;
	border: 2px solid transparent;
}

.tour-details-dates-calender-days li {
	color: #a0a0a0;
	height: 20px;
}

.tour-details-dates-calender-weekdayes li.selected {
	font-weight: 900;
	background-color: rgba(var(--web-page-primary-color), 0.05);
	border: 2px solid rgb(var(--web-page-primary-color));
}

.tour-details-dates-calender-weekdayes li.disabled {
	pointer-events: none;
	color: #a2a2a2;
}
.tour-details-dates-calender-weekdayes li.disabled .price {
	display: none;
}
.tour-details-dates-calender-weekdayes li span {
	margin-top: -5px;
}
// tour categories
.tour-details-categories li {
	margin: 0.85rem 0;
	// padding: 0.85rem 0.6rem;
	font-weight: bold;
	background-color: #edeae4;
	border-radius: 5px;
	border: 2px solid transparent;
}

.tour-details-categories li.selected {
	border: 2px solid rgb(var(--web-page-primary-color));
}

.tour-details-categories li.disabled {
	background-color: #e9ecec;
	color: #a6a6a6;
	user-select: none;
	& span {
		font-size: 0.75rem;
	}
	label {
		cursor: inherit;
	}
}

.tour-details-categories li input {
	width: 15px;
	height: 15px;
	margin-top: 3px;
	accent-color: rgb(var(--web-page-primary-color));
	color: #2d2d2d;
}

.tour-details-categories li label {
	cursor: pointer;
}
.tour-details-categories .price {
	font-size: 1.2rem;
}
// tour details summary
.tour-details-summary {
	width: fit-content;
	margin-left: auto;
}

[dir="rtl"] .tour-details-summary {
	margin-left: 0;
	margin-right: auto;
}
.tour-details-summary p {
	font-size: 1rem;
	font-weight: bold;
}

// tours cart
.tours-cart-btn {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 2rem;
	right: 1rem;
	width: 67px;
	height: 67px;
	background-color: rgb(var(--web-page-primary-color));
	border-radius: 50%;
	z-index: 9;
}
[dir="rtl"] .tours-cart-btn {
	right: initial;
	left: 1rem;
}
.tours-cart-btn-indicator {
	position: absolute;
	top: -5px;
	right: 0;
	width: 30%;
	height: 30%;
	background-color: #c30101;
	color: #fff;
	border-radius: 50%;
}
.tours-cart-item-remove-btn {
	height: 100%;
	background-color: #eaeaea;
	border-radius: 0px 5px 5px 0px;
	font-size: 1.2rem;
	color: #c30101;
}
[dir="rtl"] .tours-cart-item-remove-btn {
		border-radius: 5px 0px 0px 5px;
}

.tours-cart-item-total {
	background-color: #cbcbcb;
	& .price {
		font-size: 1.2rem;
		font-weight: bold;
		color: #28a745;
	}
}
.tours-summary-booking {
	position: sticky;
	top: 1rem;
}
// summary
.tours-summary-header {
	display: flex;
	align-items: center;
	height: 30px;
	border-left: 3px solid rgb(var(--web-page-primary-color));
}
[dir="rtl"] .tours-summary-header {
	border-right: 3px solid rgb(var(--web-page-primary-color));
	border-left: none;
}

.tours-summary-header h1 {
	font-size: 1.1rem;
	font-weight: bold;
	color: #0c3b5c;
	padding-left: 1rem;
	text-transform: uppercase;
}
[dir="rtl"] .tours-summary-header h1 {
	padding-left: 0;
	padding-right: 1rem;
}

.tours-summary-reservation {
	margin-top: 1rem;
	padding: 0.8rem;
	padding-bottom: 0;
	background-color: #fff;
	border: 1px solid #ebebeb;
}

.tours-summary-reservation-cautions {
	display: flex;
	align-items: center;
	padding: 0.6rem 0.85rem;
	margin-top: 0.6rem;
	border: 1px solid #aaaaaa;
	border-radius: 10px;
}
.tours-summary-reservation-remarks {
	display: flex;
	flex-direction: column;
	& p {
		font-size: 0.85rem;
	}
}

.tours-summary-reservation-cancelation {
	position: relative;
	margin-top: 1rem;
	font-size: 14px;
	border-top: 1px solid #ebebeb;
	& .price {
		color: #c30101;
	}
}
.price-danger {
	color: #c30101;
}

.tours-summary-reservation-cancelation::after {
	content: "";
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 1px;
	height: 100%;
	background-color: #ebebeb;
}

.tours-summary-passngers-type input {
	width: 16px;
	height: 16px;
	accent-color: #bb822d;
	color: #2d2d2d;
}
.tours-checkout input {
	accent-color: #daa249;
	color: #fff;
}
.tour-checkout-btn {
	background-color: #28a745;
	color: #fff;
	font-size: 1rem;
}
.tour-checkout-btn:hover {
	color: #fff;
}
[dir="rtl"] .tours-cart-container {
	text-align: right !important;
}

@media screen and (max-width: 992px) {
	.tours-search-wrapper {
		margin: 0;
		margin-top: -50px;
	}
	.tours-search-bar {
		flex-wrap: wrap;
	}
	.tours-search-bar > div {
		flex: calc(50% - 0.4rem) 0 0;
		max-width: calc(50% - 0.4rem);
	}
	.tour-card {
		flex-wrap: wrap;
	}
	.tour-details {
		flex-direction: column;
	}
	.tour-details > div {
		max-width: 100%;
	}
	.tour-details-dates-calender-weekdayes .price {
		line-height: 12px;
		margin-top: 3px;
		word-wrap: break-word;
	}
}
@media screen and (max-width: 767px) {
	.tours-search-bar > div {
		flex: calc(100% - 0.4rem) 0 0;
		max-width: calc(100% - 0.4rem);
	}
	.tour-card-img {
		flex-basis: auto;
	}

	.tour-card-img img {
		object-fit: cover;
	}
	.tour-card-info {
		justify-content: flex-start;
	}
	.tour-card-info-price {
		margin-left: auto;
		margin-top: 0.8rem;
	}
	[dir="rtl"] .tour-card-info-price {
		margin-left: initial;
		margin-right: auto;
	}
	.tours-cart-btn {
		width: 50px;
		height: 50px;
	}
	.tours-summary-reservation-cancelation::after {
		content: none;
	}
	.tour-details-selections {
		padding: 0.85rem 0.1rem;
	}
	
	.tour-details-dates-calender {
		--weekDaysGap: 3px;
	}


}

@media screen and (max-width: 480px) {
	.tour-details-dates-calender {
		padding: 0.85rem 0.2rem;
	}

	.tours-cart-container h2{
		font-size: 1.1rem;
		margin-top: 5px;
	}
	
	.tour-details-dates-calender-weekdayes li,
	.tour-details-dates-calender-days li {
		font-size: 10px;
		font-weight: 700;
	}
}
