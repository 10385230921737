@mixin closeWindow{
  overflow: hidden;
  span{
    position: relative;
    z-index: 1;
  }
  &:hover{
    background-color: transparent;
    &::before{
      inset-inline-start: 0;
    }
    &::after{
      inset-inline-end: 0;
    }
  }
  &::before{
    content: '';
    position: absolute;
    top: 0;
    inset-inline-start: -50%;
    width: 50%;
    height: 100%;
    background-color: rgb(var(--web-page-primary-color), 0.8);
    transition: all 0.3s linear;
    z-index: 0;
  }
  &::after{
    content: '';
    position: absolute;
    top: 0;
    inset-inline-end: -50%;
    width: 50%;
    height: 100%;
    background-color: rgb(var(--web-page-primary-color), 0.8);
    transition: all 0.3s linear;
    z-index: 0;
  }
}

@mixin ellipsis{
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}