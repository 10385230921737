.theme-7 {
	.theme_tours_section {
		position: relative;
		.section_title {
			.heading {
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1.5;
				margin-bottom: 1rem;
				color: #000000;
			}
			.text__content {
				font-size: 1rem;
				line-height: 1.5;
				margin-top: 0;
				margin-bottom: 1.2rem;
			}
		}
		// .row_wrapper {
		// 	[class^="col-"] {
		// 		.theme_tour_card:not(.super):nth-last-child(0) {
		// 			display: none;
		// 			// margin-bottom: 1rem;
		// 		}
		// 	}
		// }
		.carousel-button-group {
			width: 100%;
			background-color: #000;
			position: absolute;
			bottom: -2px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			justify-content: space-between;
			z-index: 3;

			.react-multiple-carousel__arrow {
				background-color: transparent;
				width: fit-content !important;
				height: auto !important;
				min-width: auto !important;
				min-height: auto !important;
				bottom: 0;
				&::before {
					display: none;
				}
				svg {
					path {
						stroke: #000000;
					}
				}
				&.react-multiple-carousel__arrow--left {
					left: calc(4% + 1px);
					svg {
						rotate: unset;
						// -webkit-transform: scaleX(-1);
						// transform: scaleX(-1);
					}
				}
				&.react-multiple-carousel__arrow--right {
					right: calc(4% + 1px);
				}
			}
		}
	}
	.theme_tour_card {
		position: relative;
		// min-height: 250px;
		padding: 10px;
		border-radius: 14px;
		border: 1px solid rgba(248, 248, 248, 1);
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
		overflow: hidden;

		.image {
			overflow: hidden;
			border-radius: 10px;
			height: 100%;
			img {
				width: 100%;
				height: 100%;
				// min-height: 200px;
				-o-object-fit: cover;
				object-fit: cover;
				-o-object-position: center;
				object-position: center;
				border-radius: 10px;
				transition: all 0.4s ease-in-out;
			}
			&:hover {
				img {
					-webkit-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
		}
		.content_overlay {
			position: absolute;
			bottom: 1rem;
			left: 1rem;
			right: 1rem;
			background-color: #ffffff;
			padding: 1rem;
			border-radius: 10px;
			z-index: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			.title {
				a,
				span {
					font-weight: 700;
					line-height: 1.5;
					color: #000000;
					transition: all 0.3s linear;
					&:hover {
						color: rgb(var(--web-page-primary-color));
					}
				}
			}
			.destination_name {
				margin-bottom: 0;
				svg {
					display: inline-block;
					margin-inline-end: 0.3rem;
					width: 20px;
					height: auto;
					path {
						stroke: #757575;
					}
				}
				span {
					font-size: 14px;
					font-weight: 600;
					color: #757575;
				}
			}
			.price_wrapper {
				min-width: 65px;
				.from_text {
					color: #000000;
					font-weight: 700;
					font-size: 1rem;
					line-height: 1.5;
				}
				.actual_price {
					color: rgb(var(--web-page-primary-color));
					font-weight: 700;
					font-size: 1rem;
					line-height: 1.5;
					margin-bottom: 0;
					span {
						font-size: 13px;
						font-weight: 600;
					}
				}
			}
		}
		&.super {
			height: 100%;
			// min-height: 500px;
			height: calc(500px - 1rem);
			img {
				height: 100%;
				// min-height: 500px;
				// max-height: 500px;
			}
		}
		&:not(.super) {
			height: 50%;
			// min-height: 242px;
			max-height: calc(250px - 1rem);
			.image {
				height: 100%;
				img {
					height: 100%;
					// min-height: 242px;
					// max-height: 242px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) {
		// .theme_tours_section{
		// 	.carousel-button-group {
		// 		.react-multiple-carousel__arrow {
		// 			&.react-multiple-carousel__arrow--left {
		// 				left: 39%;
		// 			}
		// 			&.react-multiple-carousel__arrow--right {
		// 				right: 39.5%;
		// 			}
		// 		}
		// 	}
		// }
	}
	@media screen and (max-width: 991px) {
		// .theme_tours_section{
		// 	.carousel-button-group {
		// 		.react-multiple-carousel__arrow {
		// 			&.react-multiple-carousel__arrow--left {
		// 				left: 36%;
		// 			}
		// 			&.react-multiple-carousel__arrow--right {
		// 				right: 36.5%;
		// 			}
		// 		}
		// 	}	
		// }
	}
	@media screen and (max-width: 767px) {
		// .theme_tours_section{
		// 	.carousel-button-group {
		// 		.react-multiple-carousel__arrow {
		// 			&.react-multiple-carousel__arrow--left {
		// 				left: 32%;
		// 			}
		// 			&.react-multiple-carousel__arrow--right {
		// 				right: 33.5%;
		// 			}
		// 		}
		// 	}	
		// }
	}
  @media screen and (max-width: 575px) {
		.theme_tours_section{
			
			// .carousel-button-group {
			// 	.react-multiple-carousel__arrow {
			// 		&.react-multiple-carousel__arrow--left {
			// 			left: 26%;
			// 		}
			// 		&.react-multiple-carousel__arrow--right {
			// 			right: 27.5%;
			// 		}
			// 	}
			// }	

			.theme_tour_card .image img {
				height: 300px;
			}
		}
	}
  @media screen and (max-width: 464px) {
		// .theme_tours_section{
		// 	.carousel-button-group {
		// 		.react-multiple-carousel__arrow {
		// 			&.react-multiple-carousel__arrow--left {
		// 				left: 15%;
		// 			}
		// 			&.react-multiple-carousel__arrow--right {
		// 				right: 16.5%;
		// 			}
		// 		}
		// 	}	
		// }
	}
	@media screen and (max-width: 767px) {
		.theme_tour_card {
			min-height: auto;
			.image {
				img {
					min-height: auto;
				}
			}
			.content_overlay {
				bottom: 1rem;
				left: 1rem;
				right: 1rem;
				padding: 1rem;
				gap: 1rem;
				.price_wrapper {
					.from_text {
						font-size: 1rem;
					}
				}
			}
			&.super {
				height: 100%;
				// min-height: auto;
				// img {
				// 	min-height: auto;
				// }
			}
			&:not(.super) {
				height: 100%;
				// min-height: auto;
				// max-height: auto;
				// .image {
				// 	img {
				// 		min-height: auto;
				// 		max-height: auto;
				// 	}
				// }
			}
		}
	}
}
