.theme-4 {
  & {
    --sections-block-padding: 60px;
  }

  // ****************************** header and hero ****************************** //

  .wb-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    background-color: #fff;
    gap: 12px;

    .logo img {
      height: 60px;
      cursor: pointer;
    }

    .main-nav {
      display: flex;
      grid-gap: 16px;
      gap: 16px;
      align-items: center;
      color: #050001;

      li {
        color: #050001;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }

      .btn-secondary,
      .btn-secondary:active:focus {
        // color: #050001 !important;
        font-size: 16px !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      .show > .btn-secondary.dropdown-toggle {
        background-color: transparent;
        border: none;
      }

      .dropdown-menu.show {
        width: 100%;
      }
    }

    .contact-button {
      border: none;
      border-radius: var(--web-page-border-raduis);
      background-color: rgba(var(--web-page-primary-color));
      color: #fff;
      padding: 8px 32px;
    }
  }

  .wb-hero {
    position: relative;
    padding-bottom: 0 !important;
    padding-bottom: 4em;
    height: 600px;

    &:has(.has-api-integration) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      min-height: 800px;
      padding: 0;
    }

    .hero-container {
      // display: flex;
      // flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .hero-bg {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .hero-content-container {
      position: absolute;
      justify-content: center;
      width: 100%;
      padding: 1rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      padding: 24px !important;
      width: 100% !important;
      max-width: 600px;
      background: rgba(34, 34, 34, 0.4784313725);
      border-radius: 4px;
      z-index: 2;

      // .header {
      //   color: rgba(var(--web-page-primary-color))
      // }

      .body {
        color: #ffffff;
      }

      .search-form-vertical {
        width: 100% !important;
      }
    }

    .hero-container::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5098039216);
      z-index: 1;
    }
  }

  // .web-builder-hero {
  //   height: calc(70vh - 72px) !important;
  // }

  #packages-section {
    position: relative;
    padding-block: var(--sections-block-padding);

    &::before {
      content: "";
      position: absolute;
      width: 70%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      border-top: 1px solid #f7f6f6;
    }
  }

  .header-nav__call {
    display: none;
  }

  .services-content {
    text-align: center;
    padding-bottom: 1.6rem;
    margin: 0 auto;
    // max-width: 100% !important;
    // max-width: unset !important;

    .service-header {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: -1rem;
        background-color: rgba(var(--web-page-primary-color));
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 4px;
      }
    }
  }

  // ****************************** our services ****************************** //
  .wb-our-service {
    background-color: transparent;
  }

  .travel-services-container {
    margin: 0 auto;
    // padding: 80px 16px;
    background-color: rgba(var(--web-page-primary-color), 0.08);
  }

  .travel-services-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    padding: 24px;
  }

  .travel-service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .travel-service-image-container {
    position: relative;
    width: 128px;
    height: 128px;
    margin-bottom: 16px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .add-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }

    .upload-file-container {
      border: 1px soild #2222227a;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      z-index: 4;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        margin-bottom: 0;
      }
    }

    & .overlay::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      z-index: 2;
    }
  }

  .travel-service-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .travel-service-title {
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .travel-service-description {
    font-size: 1rem;
    color: #4b5563;
    max-width: 200px;
  }

  .web-builder-content-about-us {
    // padding-inline-start: 0;
    background-color: rgba(var(--web-page-primary-color), 0.08);

    .about-us-details {
      padding: 24px;
      display: flex;
      flex-direction: column;

      &.start {
        align-items: flex-start;
      }

      &.center {
        align-items: center;
      }

      &.end {
        align-items: flex-end;
      }

      .h4 {
        position: relative;
        margin-bottom: 2rem !important;

        span {
          color: rgba(var(--web-page-primary-color));
          filter: grayscale(0.75);
        }

        // width: fit-content;

        &::after {
          content: "";
          position: absolute;
          bottom: -0.75rem;
          background-color: rgba(var(--web-page-primary-color));
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 4px;
          filter: grayscale(0);
        }

        // border-bottom: 4px solid rgba(141, 198, 63, 1)
      }
    }

    .about-us-title {
      display: none;
    }

    .about-media-container {
      margin-inline-start: -24px;

      img {
        width: 100%;
      }
    }

    // .web-builder-filled-btn {
    //   // margin: 0 auto;
    // }
  }

  // about us section
  .who-we-are-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
  }

  .who-we-are-text-section {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .who-we-are-title {
    font-size: 24px;
    color: #666;
    margin-bottom: 20px;
  }

  .who-we-are-text {
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    max-width: 80%;
  }

  .who-we-are-image-section {
    flex: 1;
  }

  .who-we-are-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    .who-we-are-container {
      flex-direction: column;
    }

    .who-we-are-image-section {
      height: 300px;
    }
  }

  // .arrow-icon {
  // 	display: flex;
  // 	align-items: center;
  // 	justify-content: center;

  // 	background-color: rgba(var(--web-page-primary-color));
  // 	color: rgba(var(--web-page-primary-color));

  // 	svg {
  // 		width: 26px;
  // 	}
  // 	svg path {
  // 		fill: rgba(var(--web-page-primary-color));
  // 		stroke: rgba(var(--web-page-primary-color));
  // 	}
  // }

  // packages section
  .package-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    column-gap: 48px;
    row-gap: 32px;

    .package-card-holder {
      position: relative;
      display: flex;
      border-width: 1px 1px 4px 1px;
      border-style: solid;
      border-color: rgba(var(--web-page-primary-color));
      min-width: 356px;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;

      .package-img {
        position: relative;
        width: 45%;
        max-width: 250px;
        min-width: 200px;

        img {
          width: 100%;
          height: 100%;
          min-height: inherit;
          object-fit: cover;
          position: absolute;
        }
      }
    }

    .card-caption-holder {
      display: flex;
      flex-direction: column;
      padding: 18px 16px;
      flex-grow: 1;
      color: #050001;

      .card-title {
        font-size: 18px;
        font-weight: 700;
        color: #050001;
      }

      .price {
        padding: 3px 16px;
        gap: 10px;
        border-radius: 22px;
        background: rgba(var(--web-page-primary-color), 0.08);

        .label {
          font-size: 12px;
          font-weight: 500;
          line-height: 10px;
          color: rgba(var(--web-page-primary-color));
        }

        p {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          color: rgba(var(--web-page-primary-color));

          span {
            font-size: 16px;
          }
        }
      }
    }
  }

  // flight section
  .flight-section {
    background-color: rgba(var(--web-page-primary-color), 0.08);

    .service-top-section {
      max-width: 320px;
      margin: auto;
      padding: 0;
    }
    .services-content {
      max-width: unset !important;
    }
    .h4 {
      margin-bottom: 32px;
    }
  }

  .web-builder-content-flights {
    background-color: transparent;
    position: relative;
  }

  .flights-cards-container {
    .flight-service-card {
      padding: 16px;
      background-color: #ffffff;

      .header {
        padding: 0;
        padding-bottom: 16px;
        border: 0;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 16px;
      }

      .cta-btn {
        padding: 8px 24px;
        color: #fff !important;
        background-color: rgba(var(--web-page-primary-color));
      }

      .destination-count {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px 24px;
        color: rgba(var(--web-page-primary-color));
        background-color: rgba(var(--web-page-primary-color), 0.08);
        border: 1px solid rgba(var(--web-page-primary-color), 0.16);
        gap: 6px;

        &:hover .addition-destinations-list {
          display: flex;
          width: 150%;
          flex-direction: column;
          gap: 12px;
        }
      }

      .destination-port {
        display: flex;
        justify-content: space-evenly;
        color: #050001;
        gap: 24px !important;

        .port-country {
          font-weight: 400;
          font-size: 14px;
        }

        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
          background-color: rgba(var(--web-page-primary-color), 0.08);
          border-radius: 50%;

          svg {
            width: 75% !important;
            height: 75%;
          }
        }
      }

      .addition-destinations-list {
        display: none;
        position: absolute;
        width: 100%;
        top: 38px;
        left: 0;
        padding: 16px;
        border: 1px solid #dddcdc;
        border-radius: 8px;
        background-color: #ffffff;
        z-index: 3;

        svg path {
          stroke: rgba(var(--web-page-primary-color));
        }

        .destination-port {
          gap: 16px !important;
          justify-content: space-between;
          align-items: center;

          .font-weight-bold {
            width: 90px;
          }
        }
      }
    }
  }

  // hotels and attractions section

  .hotel-container,
  .tours-container {
    .card-holder {
      position: relative;
      // min-width: 356px;
      padding: 8px;
      border-radius: 8px;
      border-width: 1px 1px 4px 1px;
      border-style: solid;
      border-color: rgba(var(--web-page-primary-color));
      gap: 8px;
      overflow: hidden;

      .service-img {
        width: 100%;
        height: 240px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-caption-holder {
        padding: 8px;

        .card-title {
          font-size: 18px;
          font-weight: 700;
          color: #050001;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .location {
          font-size: 14px;
          font-weight: 500;
          color: #050001;
          margin-inline-start: 6px;
        }

        .location-icon path {
          stroke: #707170;
        }
      }

      .card-action-holder {
        padding: 10px 0 0;
        display: flex;
        flex-direction: column;
        border-top: 1px solid rgba(247, 246, 246, 1);

        .card-price {
          flex-grow: 1;

          .label {
            text-align: start;
            font-size: 14px;
            font-weight: 400;
            color: #707170;
          }

          .price {
            font-size: 24px;
            font-weight: 600;

            & + p {
              font-size: 14px;

              span {
                color: #707170;
              }
            }
          }
        }

        .book-btn {
          padding: 9px 32px;
          border-radius: 6px;
          background: rgba(var(--web-page-primary-color));
          color: white;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }

  // attractions section
  #tours-section,
  #hotels-section {
    position: relative;
    padding-block: var(--sections-block-padding);

    .services-content {
      max-width: 320px;
    }

    .service-body {
      margin-top: 2rem;
    }
  }

  .service-slide {
    padding-inline-end: 12px;

    @media screen and (max-width: 567px) {
      padding-inline-start: 20px !important;
      padding-inline-end: 0 !important;
    }
  }

  // faq section
  .web-builder-faqs-container {
    margin-top: 0 !important;
    padding-block: var(--sections-block-padding);
  }

  .faq-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    .faq-card-holder {
      padding: 16px;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid rgba(var(--web-page-primary-color));

      .faq-title {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: rgba(31, 31, 31, 1);
        line-height: 40px;
        margin-bottom: 8px;
      }

      .faq-desc {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: rgba(54, 54, 54, 0.7);
      }
    }
  }

  // contact us section
  .contact-container {
    padding-block: var(--sections-block-padding);
    position: relative;

    // min-height: 50vh;
  }

  .contact-holder {
    padding-bottom: 170px;
    position: relative;

    .img-bg {
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      top: 0;
      left: 0;
      height: 65%;
      max-height: 500px;
      width: 100%;
      background-color: #050001;
      object-fit: fill;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .contact-form-holder {
      position: relative;
      width: 50%;
      margin: 24px auto;
      padding: 24px;
      max-width: 600px;
      backdrop-filter: blur(4px);
      background-color: rgba(var(--web-page-primary-color), 0.25);
      border-radius: 4px;
      top: 170px;
      z-index: 3;

      .title-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        margin-bottom: 32px;

        .t-title {
          font-size: 28px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .t-body {
          color: #ffffff !important;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .contact-us-form {
        width: 85%;
        margin: 0 auto;

        .textarea-field {
          background-color: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 0.3125rem;
          font-size: 13px;
          width: 100%;
          padding: 10px;

          &::placeholder {
            color: #dddcdc !important;
            font-weight: 600;
          }
        }

        .submit-btn {
          width: 100%;
          padding: 6px 32px;
          line-height: 30px;
          border-radius: 6px;
          background: rgba(var(--web-page-primary-color));
          color: white;
        }
      }
    }

    .upload-file-container {
      display: flex;
      // align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(112, 113, 112, 0.7);
      z-index: 0;

      label {
        margin-bottom: 0;
      }
    }
  }

  // footer
  .footer-container {
    background: rgba(var(--web-page-primary-color), 0.08);

    .footer-holder {
      // padding: 23px 24px;
      width: 100%;

      .footer-photo {
        width: 100%;
        max-width: 100px;
        max-height: 80px;
        object-fit: contain;
      }

      .footer-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: flex-end !important;
        flex-wrap: wrap;
        gap: 2rem;
      }

      .footer-text {
        font-size: 14px;
        font-weight: 400;
        color: #363636b2 !important;
        cursor: pointer;
      }

      .footer-link-holder {
        display: flex;
        row-gap: 15px;
        column-gap: 30px;
        align-items: center;
        margin-top: 32px;
        flex-wrap: wrap;
        justify-content: center;
      }

      .footer-social-holder {
        display: flex;
        margin: 10px 0px;
        gap: 16px;
        filter: grayscale(0.60);

        svg circle {
          fill: rgba(var(--web-page-primary-color), 0.7);
          fill-opacity: 1;
        }
      }

      .footer-social {
        margin: 0 auto;
        text-align: center;
      }

      .footer-copy-right {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid #e3eed5;
      }
    }
  }

  .empty-service::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(112, 113, 112, 0.7) !important;
    z-index: 2;
  }

  .hotels-detials {
    position: unset !important;
  }

  .web-builder-content-transfer .tours-details {
    position: relative;
  }

  .home-page-transfer-section {
    position: relative;
    padding-block: var(--sections-block-padding);

    .home-page-transfer-card {
      padding: 0.5rem;
      margin-bottom: 5px;
      border-radius: 8px;
      border: 1px solid rgba(141, 198, 63, 1);
      box-shadow: 0px 5px 0px 0px #8dc63f;
      display: flex;
      flex-direction: column;

      &__image {
        img {
          width: 100%;
          aspect-ratio: 3 / 2;
          border-radius: 8px;
          object-fit: cover;
          user-select: none;
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__content {
        margin-top: 0.5rem;
      }

      &__title {
        font-weight: 700;
        font-size: 1.125rem;
      }

      &__tag {
        padding: 0.25rem 1rem;
        border-radius: 90px;
        background-color: rgba(234, 243, 221, 1);
        color: rgba(141, 198, 63, 1);
        text-transform: capitalize;
      }

      &__body {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        flex-wrap: wrap;

        > p {
          display: flex;
          align-items: center;
          gap: 0.2rem;
          font-size: 0.875rem;
        }

        svg {
          --size: 21px;
          width: var(--size);
          height: var(--size);

          path {
            stroke: rgba(112, 113, 112, 1);
          }
        }
      }

      &__footer {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: auto;
      }

      &__price-from {
        font-weight: 400;
        font-size: 0.875rem;
        color: #707170;
        display: block;
        line-height: 1;
      }

      &__price-value {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }

      &__price-value-number {
        font-weight: 600;
        font-size: 1.5rem;
        color: black;
      }

      &__price-value-per-currency {
        font-weight: 400;
        font-size: 0.875rem;
      }

      &__price-value-per-time {
        font-weight: 400;
        font-size: 0.875rem;
        color: #707170;
      }

      &__button {
        border: none;
        outline: none;
        background-color: rgba(141, 198, 63, 1);
        color: white;
        font-weight: 700;
        padding: 0.5rem 3rem;
        font-size: 1rem;
        width: 100%;
      }

    }

    .react-multi-carousel-item {
      padding-bottom: .5rem;
    }
    
    .react-multi-carousel-item > div {
      height: 100%;
      & > div {
        display: flex;
        flex-direction: column;
      }
    }

    // .carousel-button-group {
    //   position: absolute;
    //   top: 93px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   justify-content: space-between;
    //   gap: 370px;
    // }

    .service-top-section {
      max-width: 300px !important;
      margin: auto;
      padding: 0;

      .services-content {
        width: 100%;
        max-width: 100%;
      }

      .h4 {
        margin-bottom: 32px;
      }
    }
  }

  .web-builder-content-other {
    .otherservice-cards-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 1fr;
    }

    .home-page-other-card {
      border-radius: 8px;
      border: 1px solid rgba(141, 198, 63, 1);
      box-shadow: 0px 5px 0px 0px #8dc63f;
      display: flex;

      &__image {
        flex-shrink: 0;
        max-width: 250px;
        min-width: 300px;

        img {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          width: 100%;
          aspect-ratio: 3/2;
          object-fit: cover;
          height: 100%;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        flex: 2;
        padding: 1rem;
      }

      &__divider {
        width: 100%;
        background-color: rgba(226, 232, 240, 1);
        margin-block: 1rem;
      }

      &__title {
        font-size: 1.125rem;
        color: black;
        font-weight: 700;
      }

      &__description {
        font-size: 1rem;
        color: rgba(112, 113, 112, 1);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__footer {
        margin-top: auto;
        display: flex;
      }

      &__cta {
        margin-top: .5rem;
        margin-inline-start: auto;
        button {
          padding: .7rem 1.2rem;
          font-weight: bold;
          height: 100%;
          background-color: rgba(141, 198, 63, 1) !important;
          color: white !important;
          font-size: 1rem;
          border: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .otherservice-cards-container {
        grid-template-columns: 1fr;
        gap: 1rem;
      }

      .home-page-other-card {
        max-width: 500px;
        width: 100%;    
        max-width: 100%; 
        
        &__image {
          max-width: 100px !important;
          min-width: 100px !important;
        }
      }
    }
  }

  .service-top-section {
    // max-width: 320px;
    // margin: auto;
    padding: 0;
  }

  .wb-hero {
    padding: 1rem;

    .hero-container {
      justify-content: center !important;

      .hero-bg {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
      }

      .hero-content-container {
        position: static !important;
        transform: none !important;
        padding: 0.6rem 0.1rem !important;
        width: fit-content !important;
        margin: 0 auto !important;

        .header,
        .body {
          padding-inline: 1rem !important;
        }

        @media screen and (min-width: 768px) {
          padding: 1rem !important;
        }
      }
    }

    .has-api-integration {
      .hero-content-container {
        width: 100%;
        max-width: 800px;
        padding: 24px;

        .global-input {
          text-align: start !important;
        }
      }
    }

    .search-form-select {
      width: calc(100% / 2 - 4px) !important;
      min-width: 0 !important;
    }
  }

  @media screen and (max-width: 600px) {
    // .search-form-select {
    //   width: 100% !important;
    // }

    .wb-our-service {
      padding-inline: 0 !important;
      background-color: transparent !important;
    }

    .about-media-container {
      margin-inline-start: 0;
    }

    .web-builder-content-hotels {
      background-color: transparent !important;
    }

    .package-container {
      grid-template-columns: 1fr;

      .package-card-holder {
        flex-direction: column;
        min-width: unset;
        width: 100%;

        .package-img {
          width: 100%;
          max-width: 100%;
          height: 200px;
        }
      }
    }

    // hotel and tours
    .hotel-container,
    .tours-container {
      .card-action-holder {
        flex-wrap: wrap;
      }

      .service-slide {
        padding-inline-start: 0;
        padding-inline-end: 20px;
      }
    }

    // flights
    .flights-cards-container {
      display: block;

      .content .d-flex {
        flex-wrap: wrap;
      }

      .price {
        display: block !important;

        .amount {
          margin: 0;
        }
      }

      .header {
        flex-wrap: wrap;
        gap: 12px;
      }
    }

    // faq section
    .faq-container {
      grid-template-columns: 1fr;
    }

    .contact-holder {
      padding-bottom: 0;
      padding: 12px;
      border-radius: 4px;
      overflow: hidden;

      .img-bg {
        height: 100%;
        max-height: unset;
      }

      .contact-form-holder {
        width: 100%;
        top: 0;
        margin: 0;
        border-radius: 4px;

        .contact-us-form {
          width: 100%;
        }
      }
    }

    // footer section
    .footer-top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center !important;

      img {
        display: block;
        margin: auto;
      }

      .links-list {
        flex-wrap: wrap;
        gap: 12px !important;
        margin-bottom: 1.2rem;
        justify-content: center;
      }

      .footer-text {
        text-align: center;
      }
    }

    // general
    // .carousel-button-group {
    //   display: none;
    // }
  }

  .services-carousel-container {
    @media screen and (max-width: 567px) {
        width: calc(100vw - 16px);
        margin-inline-start: -25px;
    }
  }

  .carousel-button-group {    
    position: absolute;
    top: 90px;
    right: 0;;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-inline: 1rem;

    .react-multiple-carousel__arrow--right,
      .react-multiple-carousel__arrow--left {
        position: unset;
        transform: none;

        [dir="rtl"] & {
          transform: scale(-1);
        } 
    }

    @media screen and (min-width: 567px) {
      width: 100%;
      max-width: 400px;
      left: 50%;
      transform: translateX(-50%);
    }
  } 
}
