.image-uploader {
	&__img {
		border-radius: 200px;
		display: block;
		margin: 0 auto;
		width: calculateRem(160px);
		height: calculateRem(160px);
	}

	&__outline {
		width: calculateRem(180px);
		height: calculateRem(180px);
		border-radius: 180px;
		padding: calculateRem(10px);
		border: 1px solid $gray-200;
	}

	&__plus-btn {
		position: absolute;
		z-index: 1050;
		// #{var(--right)}: 0;
		top: 0;
		width: 40px;
		height: 40px;
	}
}
