
.payment-box {
	background-color: #f7f7f7;
	border-radius: 10px;
}
.must-box {
	background-color: #cfead5;
	color: #28a745;
	border-radius: 10px;
}
.clear-filter {
	border: 1px solid #666666;
	font-size: 15px;
}
[dir="ltr"] .hotel-found {
	border-left: 3px solid #cb9a51;
	padding-left: 8px;
}
[dir="rtl"] .hotel-found {
	border-right: 3px solid #cb9a51;
	padding-right: 8px;
}
.error-model-title {
	font-size: 17px;
	font-weight: 600;
	color: #1b1e21;
	word-break: break-word;
}
.pointer {
	cursor: pointer;
}


