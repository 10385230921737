// @import "base/variables_rtl";

/* ----------------------------------------------------------------------------------------------------------------------------------------------------
    00. Reset Default CSS
---------------------------------------------------------------------------------------------------------------------------------------------------- */
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0;
}
a {
	text-decoration: none !important;
	cursor: pointer;
}
a:hover {
	text-decoration: none !important;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
fieldset,
img {
	border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style: normal;
	font-weight: normal;
}
ol,
ul {
	list-style: none;
}
caption,
th {
	text-align: var(--left);
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal;
}
q:before,
q:after {
	content: "";
}
abbr,
acronym {
	border: 0;
	font-variant: normal;
}
sup {
	vertical-align: text-top;
}
sub {
	vertical-align: text-bottom;
}
input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}
legend {
	color: #000;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
