.theme-7{
  .theme_faq_section{
    position: relative;
    padding: 3rem 3rem;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 350px;
    border-radius: 10px;
    box-shadow: 0 0 5px #aeaeaece;
    @media screen and (max-width: 991px) {
      padding: 2rem;
    }
    @media screen and (max-width: 575px) {
      padding: 1.5rem;
    }
   }
   .theme_faq_card{
    position: relative;
    border-radius: 1px;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    @media screen and (max-width: 575px) {
      background-color: #ffffffd0;
    }
   }
}