.flight-result {
  .head {
    padding-inline: 40px;
    padding-block: 25px;
    background-color: rgba(245, 248, 252, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .location {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 50%;

      p {
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
        font-weight: 700;
        span {
          color: rgba(30, 133, 255, 1);
        }
      }
      .info {
        gap: 10px;
        padding-inline: 0 !important;
        p {
          font-size: 16px;
          font-weight: 400;
          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
    button {
      padding: 10px 16px 10px 16px;
      font-size: 16px;
      font-weight: 400;
      .edit-icon-mobile{
        display: none;
      }
    }
    
  }
  .content {
    padding-inline: 40px;
    margin-top: 25px;
    background: #fff !important;
    .filters {
      .flight-filter {
        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          p {
            font-size: 20px;
            font-weight: 500;
            color: #050001;
          }
          .reset {
            border: 1px solid rgba(30, 133, 255, 1);
            padding: 8px 16px;
            color: rgba(30, 133, 255, 1);
            background-color: #fff;
            border-radius: 4px;
            font-size: 16px;
            font-weight: 500;
          }
        }
        .stops {
          border: 1px solid rgba(247, 246, 246, 1);
          padding: 16px;
          border-radius: 8px;
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
          margin-bottom: 16px;
          .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            h6 {
              font-size: 18px;
              font-weight: 700;
              color: #050001;
            }
          }
          .airlines-checkbox {
            padding: 10px;
            background: rgba(251, 251, 250, 1);
            margin-bottom: 16px;
            label {
              color: rgba(112, 113, 112, 1);
              font-size: 16px;
              font-weight: 400;
            }
            .custom-control-input:checked ~ .custom-control-label::before {
              border-color: #1e85ff !important;
              background-color: #1e85ff !important;
            }
          }
        }
        .airline {
          .collapse {
            max-height: 350px;
            overflow-y: auto;
            /* Width and height of the scrollbar track */
            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }

            /* Background color of the scrollbar track */
            &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }

            /* Color of the scrollbar thumb */
            &::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 1px;
            }

            /* Color of the scrollbar thumb on hover */
            &::-webkit-scrollbar-thumb:hover {
              background-color: #555;
            }
            .items {
            }
          }
        }
        .flight-times {
          .trips {
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            p {
              font-size: 16px;
              font-weight: 400;
              color: rgba(112, 113, 112, 1);
            }
          }
          .multi-range-slider {
            border: none !important;
            box-shadow: none !important;
            .bar-inner,
            .bar-right,
            .bar-left {
              border: 1px solid rgba(221, 220, 220, 1) !important;
              box-shadow: inset 0px 0px 5px rgba(221, 220, 220, 1) !important;
            }
            .thumb .caption * {
              box-shadow: 0px 0px 2px #222;
              background-color: #1e85ffbf !important;
            }
          }
          .multi-range-slider .bar-inner {
            background-color: rgba(221, 220, 220, 1);
            height: 2px;
          }
          .multi-range-slider .bar-right,
          .multi-range-slider .bar-left {
            background-color: #1e85ff;
            height: 2px;
            padding: 0;
          }
          .labels {
            display: none;
          }
          .multi-range-slider .thumb {
            background-color: #1e85ff !important;
          }
          .multi-range-slider .thumb::before {
            content: "";
            box-shadow: none !important;
            border: none !important;
          }
          .multi-range-slider .labels {
            margin-inline: -10px;
          }
          .multi-range-slider .label {
            color: rgba(221, 220, 220, 1);
            font-size: 14px;
          }
        }
      }
    }
    .num-result {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      p {
        color: #050001;
        font-size: 20px;
        font-weight: 500;
      }
      .sort {
        display: flex;
        align-items: center;
        gap: 13px;
        p {
          color: #050001;
          font-size: 16px;
          font-weight: 500;
        }
        select {
          border: 1px solid rgba(219, 218, 222, 1);
          padding: 3px 20px;
          color: rgba(112, 113, 112, 1);
          font-size: 18px;
          font-weight: 400;
          border-radius: 4px;
        }
      }
    }
    .content-item {
      border: 1px solid rgba(247, 246, 246, 1);
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
      padding: 16px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .data {
        width: 80%;
        .data-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .logo {
            display: flex;
            align-items: center;
            gap: 8px;
            p {
              font-size: 16px;
              font-weight: 400;
              color: rgba(112, 113, 112, 1);
            }
          }
          .box {
            display: flex;
            gap: 10px;
            p {
              background-color: rgba(247, 246, 246, 1);
              padding: 0px 8px 0px 8px;
              border-radius: 4px;

              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 1);
            }
          }
          .stop {
            padding-right: 25px;
            .time-line {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              column-gap: 10px;
              .infoAir {
                display: flex;
                flex-direction: column;
                position: relative;
                top: -12px;
                margin-inline: 12px;
              }
              .takeoff {
                font-size: 22px;
                font-weight: 700;
                color: rgba(5, 0, 1, 1);
              }
              .Landing {
                display: flex;
                flex-direction: column;
                position: relative;
                top: -12px;
                margin-inline: 12px;
              }

              .airport-name {
                color: rgba(112, 113, 112, 1);
                font-size: 16px;
                font-weight: 400;
                text-align: center;
              }
              .spent {
                color: #a0a0a0;
                font-size: 14px;
                padding: 5px 60px 6px 60px ;
                border-top: 2px dashed #d9d9d9;
                position: relative;
              }
              .spent::before {
                content: "";
                position: absolute;
                padding: 3px;
                border: 1px solid #d9d9d9 !important;
                border-radius: 50%;
                top: -4px;
                background-color: #d9d9d9 !important;
              }
              .spent::after {
                content: "";
                position: absolute;
                padding: 3px;
                border: 1px solid #d9d9d9 !important;
                border-radius: 50%;
                top: -4px;
                background-color: #d9d9d9 !important;
              }
            }
            .clock {
              span {
                display: inline-block;
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(112, 113, 112, 1);
                margin-inline: 6px;
              }
            }
          }
        }
        hr {
          background-color: rgba(247, 246, 246, 1);
        }
        .flight-details {
          display: flex;
          justify-content: space-between;
          .details {
            display: flex;
            gap: 8px;
            p {
              font-size: 16px;
              font-weight: 600;
              color: rgba(30, 133, 255, 1);
            }
          }
          .luggage {
            display: flex;
            gap: 9px;
            span {
              color: rgba(112, 113, 112, 1);
              font-size: 16px;
              font-weight: 400;
            }
          }
          .price-mobile{
            display: none;
          }
        }
        .details-dropdown {
          border: 1px solid #dddcdc;
          border-radius: 8px;
          padding: 10px;
          margin-top: 10px;
          .details-head {
            padding: 10px;
            background-color: #f7f6f6;
            display: flex;
            justify-content: space-between;
            p {
              font-size: 14px;
              font-weight: 600;
              color: rgba(5, 0, 1, 1);
            }
          }
          .details-content {
            .details-item {
              display: flex;
              justify-content: space-between;
              margin-block: 10px;
              .logo {
                display: flex;
                flex-direction: column;
                p {
                  font-size: 14px;
                  font-weight: 700;
                  color: rgba(5, 0, 1, 1);
                }
                span {
                  font-size: 14px;
                  font-weight: 400;
                  color: #707170;
                }
              }
              .economy {
                display: flex;
                flex-direction: column;
                gap: 5px;
                p {
                  color: #707170;
                  font-size: 14px;
                  font-weight: 400;
                }
                h6 {
                  font-size: 14px;
                  font-weight: 400;
                  color: rgba(5, 0, 1, 1);
                }
              }
              .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-basis: 70%;
                div {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  h5 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #222;
                  }
                  span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #707170;
                  }
                  p {
                    font-size: 14px;
                    font-weight: 400;
                    color: #222;
                  }
                }
                .clock {
                  color: #707170;
                  font-size: 14px;
                  font-weight: 400;
                }
              }
            }
            .dot-layout {
              width: 100%;
              display: flex;
              justify-content: center;
            }
            .dot {
              display: inline-flex;
              gap: 5px;
              padding: 10px;
              border: 1px dashed rgba(30, 133, 255, 1);
              // min-width: 40%;
              // max-width: 55%;
              // margin: auto;
              div {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: rgba(30, 133, 255, 1);
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: #222;
              }
            }
          }
        }
      }
      .price {
        width: 18%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid rgba(221, 220, 220, 1);

        span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(112, 113, 112, 1);
        }
        p {
          font-size: 24px;
          font-weight: 700;
          color: rgba(5, 0, 1, 1);
          span {
            font-size: 18px;
            font-weight: 700;
            color: rgba(5, 0, 1, 1);
          }
        }
        button {
          padding: 6px 24px 6px 24px;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
  .hotel-item {
    border: 1px solid rgba(226, 232, 240, 1);
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    .img-container {
      position: relative;
      img {
        height: 180px;
        width: 100%;
        border-radius: 8px;
      }
      p {
        background-color: rgba(250, 196, 48, 1);
        border-radius: 0px 6px 6px 0px;
        padding-inline: 12px;
        font-size: 20px;
        font-weight: 400;
        position: absolute;
        top: 20px;
        left: 0;
      }
    }
    .rating {
      display: flex;
      margin-block: 8px;
      gap: 2px;
    }
    .info {
      .title {
        color: #222;
        font-size: 18px;
        font-weight: 700;
      }
      p {
        color: #707170;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 12px;
      }
    }
    .price {
      border-top: 1px solid rgba(226, 232, 240, 1);
      display: flex;
      justify-content: space-between;
      align-items: end;
      div {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        span {
          color: #707170;
          font-size: 14px;
          font-weight: 400;
        }
        p {
          color: #222;
          font-size: 24px;
          font-weight: 600;
          line-height: 1;
          span {
            color: #222;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      button {
        font-size: 16px;
        font-weight: 700;
        // padding: 8px;
      }
    }
  }
  .search-bar{
    border: 1px solid #22222221;
    background-color: #f5f8fc !important;
    .control-field{
      span{
        display: inline-block !important;
  text-align: start !important;
  width: 100% !important;
  color: #979797 !important;
  font-size: 12px !important;
  font-weight: 500 !important
      }
    }
    .title-style-container{
      padding-block:8px ;
    }
    .mybooking-grid-container{
      background-color: #f5f8fc !important;
    }
    .link-mobile,.btn-action-mobile{
      display: none;
    }
    .btn-action{
      display: block;
    }
  }
}
.flight-summary {
  background: #fbfbfa !important;

  .head {
    padding-inline: 40px;
    padding-block: 25px;
    background-color: rgba(245, 248, 252, 1);
    .location {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 50%;

      p {
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
        font-weight: 700;
        span {
          color: rgba(30, 133, 255, 1);
        }
      }
      .info {
        gap: 10px;
        padding-inline: 0 !important;
        p {
          font-size: 16px;
          font-weight: 400;
          span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .summary-content {
    padding-inline: 40px;
    margin-top: 25px;
    background: #fbfbfa !important;
    .details {
      .confirmed {
        border: 1px solid rgba(1, 157, 76, 0.4);
        padding: 16px;
        border-radius: 4px;
        background-color: rgba(1, 157, 76, 0.05);
        margin-bottom: 16px;
        p {
          color: rgba(1, 157, 76, 1);
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }
      }
      .flight-detail {
        border: 1px solid rgba(247, 246, 246, 1);
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
        h3 {
          font-size: 20px;
          font-weight: 700;
          color: #050001;
          margin-bottom: 8px;
        }
        .all {
          border: 1px solid #dddcdc;
          padding: 10px;
          border-radius: 8px;
          margin-bottom: 8px;
        }
        .details-head {
          padding: 10px;
          background-color: #f7f6f6;
          display: flex;
          justify-content: space-between;
          p {
            font-size: 14px;
            font-weight: 600;
            color: rgba(5, 0, 1, 1);
          }
        }
        .details-content {
          .details-item {
            display: flex;
            justify-content: space-between;
            margin-block: 10px;
            .logo {
              display: flex;
              flex-direction: column;
              p {
                font-size: 14px;
                font-weight: 700;
                color: rgba(5, 0, 1, 1);
              }
              span {
                font-size: 14px;
                font-weight: 400;
                color: #707170;
              }
            }
            .economy {
              display: flex;
              flex-direction: column;
              gap: 5px;
              p {
                color: #707170;
                font-size: 14px;
                font-weight: 400;
              }
              h6 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(5, 0, 1, 1);
              }
            }
            .info {
              display: flex;
              justify-content: space-between;
              align-items: center;

              div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                h5 {
                  font-size: 18px;
                  font-weight: 700;
                  color: #222;
                }
                span {
                  font-size: 14px;
                  font-weight: 400;
                  color: #707170;
                }
                p {
                  font-size: 14px;
                  font-weight: 400;
                  color: #222;
                }
              }
              .clock {
                color: #707170;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }
          .dot-layout {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          .dot {
            display: inline-flex;
            gap: 5px;
            padding: 10px;
            border: 1px dashed rgba(30, 133, 255, 1);
            // min-width: 40%;
            // max-width: 55%;
            // margin: auto;
            div {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: rgba(30, 133, 255, 1);
            }
            p {
              font-size: 14px;
              font-weight: 400;
              color: #222;
            }
          }
        }
      }
      .message-info {
        border: 1px solid rgba(79, 195, 247, 0.4);
        padding: 16px;
        border-radius: 4px;
        margin-top: 16px;
        background-color: rgba(79, 195, 247, 0.04);
        p {
          color: rgba(37, 173, 234, 1);
          font-size: 16px;
          font-weight: 600;
          line-height: 1.8;
        }
      }
      .passanger {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 20px;
          font-weight: 700;
          color: #050001;
        }
        .buttons {
          display: flex;
          gap: 25px;
          margin-top: 16px;
          button {
            border: 1px solid rgba(30, 133, 255, 1);
            color: rgba(30, 133, 255, 1);
            padding: 10px;
            border-radius: 4px;
            background-color: #fff;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .adult-info {
        margin-top: 16px;
        border: 1px solid rgba(247, 246, 246, 1);
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);
        h3 {
          font-size: 20px;
          font-weight: 700;
          color: #050001;
          padding-bottom: 16px;
          margin-bottom: 8px;
          border-bottom: 1px solid rgba(219, 218, 222, 1);
        }
        .control-field__label,
        label {
          font-size: 14px;
          font-weight: 400;
          color: #707170;
        }
        .date-of-birth {
          .control-field {
            margin-top: 6px !important;
          }
          .start {
            .control-field {
              border-radius: 5px 0 0 5px !important;
            }
          }
          .end {
            .control-field {
              border-radius: 0 5px 5px 0 !important;
            }
          }
        }
      }
      .flight-checkout-btn {
        background-color: #1e85ff !important;
      }
    }
    .price-details {
      border: 1px solid rgba(247, 246, 246, 1);
      padding: 16px;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
      margin-bottom: 8px;
      h3 {
        font-size: 18px;
        font-weight: 700;
        color: #050001;
        margin-bottom: 8px;
      }
      .item {
        margin-bottom: 16px;
        .adults-price-summary {
          background-color: rgba(251, 251, 250, 1);
          padding: 10px;
          .n-adult {
            font-size: 16px;
            font-weight: 400;
            color: rgba(112, 113, 112, 1);
          }
        }
        .price-color {
          .bold {
            color: #2d2d2d;
            font-size: 14px;
          }
          p {
            color: #979797;
            font-size: small;
          }
        }
      }
    }
    .total-summary {
      background-color: rgba(251, 251, 250, 1);
      padding: 10px;
      text-align: center;
      h3 {
        font-size: 22px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
        font-weight: 400;
        color: rgba(112, 113, 112, 1);
      }
    }
  }
}
.free-rules-builder-modal {
  .modal-content {
    padding: 24px;
  }
  .modal-header {
    border-bottom: 1px solid #dddcdc;
    padding: 16px;
    h3 {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  .table-container {
    margin-top: 16px;
    border: 1px solid #dddcdc;
    border-radius: 8px;
    overflow: hidden;

    thead {
      background-color: #dddcdc;
      th {
        font-size: 14px;
        font-weight: 700;
        color: #707170;
      }
    }
    td {
      background-color: #fff !important;
    }
    .two-row {
      font-size: 15px;
      font-weight: 700;
      color: rgba(5, 0, 1, 1);
    }
  }
  ul {
    margin-top: 16px;
    li {
      font-size: 16px;
      font-weight: 400;
      color: #000;
    }
    .sub-list {
      margin-left: 20px;
    }
  }
}
.no-data-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 82vh;
  h6 {
    color: rgba(0, 0, 0, 1);
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 7px;
  }
  p {
    color: #707170;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 22px;
  }
  button {
    font-size: 16px;
    font-weight: 700;
    width: 30%;
  }
}
// Start Hotel Details
.hotel-details {
  .details-head {
    background-color: rgba(245, 248, 252, 1);
    text-align: center;
    padding-block: 25px;
    margin-bottom: 24px;
  }
  .details-info {
    margin-inline: 30px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .text {
        h6 {
          color: #050001;
          font-size: 28px;
          font-weight: 700;
          .rating {
            margin-inline: 8px;
            display: inline-flex;
            gap: 4px;
            font-size: 18px;
          }
        }
        p {
          color: #050001;
          font-size: 18px;
          font-weight: 400;
          a {
            margin-inline: 40px;
            color: rgba(30, 133, 255, 1);
            text-decoration: underline;
          }
        }
      }
      .room-action {
        display: flex;
        gap: 15px;
        button {
          padding-inline: 25px;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
    .slider-content {
    }
  }
  .details-overview {
    margin-inline: 30px;
    margin-top: 16px;
    h6 {
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      line-height: 1.5;
    }
  }
  .details-facilities {
    margin-inline: 30px;
    margin-top: 16px;
    h6 {
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
    }
    li {
      background-color: transparent;
      border: none;
      padding: 5px 7px;
      display: inline-block;
      margin: 2px 0;
      width: 33%;
      font-size: 18px;
      font-weight: 400;
    }
  }
  .details-rooms {
    margin-inline: 30px;
    margin-top: 16px;
    h6 {
      font-size: 28px;
      font-weight: 700;
      color: rgba(0, 0, 0, 1);
      margin-bottom: 8px;
    }
    .rooms-info {
      background-color: rgba(245, 248, 252, 1);
      border: 1px solid rgba(226, 232, 240, 1);
      padding: 20px;

      h3 {
        color: rgba(35, 80, 133, 1);
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .room-item {
        background-color: #fff;
        padding: 15px 10px;
        border-radius: 6px;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.04);
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .room-info {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 50%;
          .name {
            color: rgba(34, 34, 34, 1);
            font-size: 18px;
            font-weight: 600;
          }
          .info {
            display: flex;
            justify-content: space-between;
            p {
              color: rgba(112, 113, 112, 1);
              font-size: 16px;
              font-weight: 400;
            }
            .refund {
              color: rgba(1, 157, 76, 1);
              font-size: 16px;
              font-weight: 400;
            }
            .non-refund {
              color: rgba(234, 84, 85, 1);
              font-size: 16px;
              font-weight: 400;
            }
            .adult,
            .child {
              display: inline-flex;
              gap: 3px;
            }
          }
        }
        .room-price {
          width: 40%;
          padding-left: 48px;
          border-left: 1px solid rgba(172, 208, 255, 1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          .price {
            text-align: center;
            .label {
              color: #707170;
              font-size: 18px;
              font-weight: 400;
              line-height: 0.5 !important;
            }
            p {
              font-size: 28px;
              font-weight: 600;
              color: #050001;
              .currency {
                font-size: 16px;
                font-weight: 400;
              }
              .night {
                color: #707170;
                font-size: 16px;
                font-weight: 400;
              }
            }
          }
          .availability {
            width: 160px;
          }
        }
      }
    }
  }
  .book-btn {
    margin-inline: 30px;
    margin-top: 8px;
    margin-bottom: 24px;
    button {
      padding: 6px 25px;
      font-size: 18px;
      font-weight: 400;
    }
  }
}
.hotel-summary {
  margin-inline: 30px;
  margin-top: 30px;
}
.boxs-payment {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.item-payment {
		display: flex;
		flex-direction: column;
		border: 1px solid #ebebeb;
		border-radius: 6px;
		padding: 10px 10px;
		.raido-label {
			display: flex;
			align-items: center;
			input {
				accent-color: rgba(var(--web-page-primary-color));
				width: 20px;
				margin-inline-end: 15px;
			}
			label {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 0px;
			}
		}
    // .visa-card-label{
    //   svg{
    //     stroke: rgba(var(--web-page-primary-color));
    //     g{
    //     stroke: rgba(var(--web-page-primary-color));
    //     }
    //     path{
    //       fill: rgba(var(--web-page-primary-color));
    //       stroke: transparent;
    //     }
    //   }
    // }
	}
	.pay-active {
		border: 1px solid rgba(var(--web-page-primary-color));
		background-color: rgba(var(--web-page-primary-color),.1);
	}
  .due-amount{
    svg{
      stroke: rgba(var(--web-page-primary-color));
      fill: rgba(var(--web-page-primary-color),.2);
      path{
      stroke: rgba(var(--web-page-primary-color));

      }
    }
  }
	.newpay-btn {
		background-color: rgba(var(--web-page-primary-color));
		border-radius: 8px;
		color: #fff;
		padding: 10px 5px;
		font-size: large;
	}
}
     .stops {
          border: 1px solid rgba(247, 246, 246, 1);
          padding: 16px;
          border-radius: 8px;
          box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.02);
          margin-bottom: 16px;
          .title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            h6 {
              font-size: 18px;
              font-weight: 700;
              color: #050001;
            }
          }
          .airlines-checkbox {
            padding: 10px;
            background: rgba(251, 251, 250, 1);
            margin-bottom: 16px;
            label {
              color: rgba(112, 113, 112, 1);
              font-size: 16px;
              font-weight: 400;
            }
            .custom-control-input:checked ~ .custom-control-label::before {
              border-color: #1e85ff !important;
              background-color: #1e85ff !important;
            }
          }
        }
        .airline {
          .collapse {
            max-height: 350px;
            overflow-y: auto;
            /* Width and height of the scrollbar track */
            &::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }

            /* Background color of the scrollbar track */
            &::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }

            /* Color of the scrollbar thumb */
            &::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 1px;
            }

            /* Color of the scrollbar thumb on hover */
            &::-webkit-scrollbar-thumb:hover {
              background-color: #555;
            }
            
          }
        }

.contact-info {
  margin-top: 16px;
  border: 1px solid rgba(247, 246, 246, 1);
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.05);

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #050001;
    padding-bottom: 16px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(219, 218, 222, 1);
  }

  .phone {
    .phone_code {
      
      .control-field {
        border: 1px solid #ebebeb;
      }
    }

    .control-field__label {
      display: none;
    }

    .phone_number_textfield {
      padding-inline-start: 0 !important;
    }
  }
  .phone_number {
    width: calc(100% - var(--phoneCodeWidth));
  }

  .control-field__label,
  label {
    font-size: 14px;
    font-weight: 400;
    color: #707170;
  }
  .alert-danger {
    margin: 1rem 0;
    padding: 16px;
    background-color: #FF0C0D0A;
    border-radius: 4px;
    border: 1px solid #FF0C0D66;
    color: #EA5455;
    font-weight: 600;
    text-align: center;
  }

}