@media screen and (max-width: 810px) {
  .main-web-builder {
    .col-12 {
      padding: 0;
    }
  }

  .web-builder-preview {
    // overflow: hidden;

    &.theme-2 .web-builder-hero {
      height: 450px;

      // .content-layout-theme-2 {
      //   top: 55%;
      // }
    }

    .web-builder-hero-header {
      z-index: 3;

      header {
        justify-content: initial;
        align-items: center;
        padding: 8px;

        .logo img {
          height: 40px;
          max-width: 120px;
          object-fit: contain;
        }
      }

      .d-flex-language-contact {
        margin-left: auto;

        [dir="rtl"] & {
          margin-right: auto;
        }
      }

      .nav-container {
        gap: 0;
        margin-inline-start: auto;

        [dir="rtl"] .nav-container {
          margin-right: auto;
        }
      }

      .header-nav {
        position: fixed;
        justify-content: center;
        align-items: self-start;
        width: 100%;
        height: 100vh;
        padding: 1rem;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.64),
            rgba(0, 0, 0, 0.64)
          ),
          linear-gradient(
            0deg,
            rgba(var(--web-page-primary-color)),
            rgba(var(--web-page-primary-color))
          );
        z-index: 100;
        transition: transform 0.35s ease;

        ul {
          flex-direction: column;
          padding-top: 4rem;
          gap: 32px;

          li,
          .service-dropdown {
            color: #fff !important;
          }
        }

        &.nav-opened {
          transform: translateY(0);
          transition: transform 0.35s ease;
        }
      }

      .nav-mobile-icon,
      .close-nav {
        border: 0;
        background-color: transparent;
        display: block !important;
      }

      .close-nav {
        position: absolute;
        top: 16px;
        left: 16px;
      }

      .contact-button {
        padding: 3px 18px;
      }

      .call-us {
        display: none !important;
      }
    }

    .web-builder-hero-content {
      width: 100%;
      padding: 0.8rem 1rem !important;
      grid-template-columns: repeat(1, 1fr) !important;
      .header {
        font-size: 1.4rem;
      }

      .search-form-web-builder {
        flex-wrap: wrap;
        padding: 8px 7px;
        gap: 4px;

        .search-form-select {
          min-width: auto;
          width: calc(50% - 4px);
        }
      }

      .search-form-button {
        width: 100%;
      }
    }

    /** ****************************************** about us ******************************************/
    .wb-our-service {
      padding: 30px 10px;

      .services-content {
        max-width: 100%;
        padding: 0;
      }

      .web-builder-content-our-service {
        padding: 0;
        grid-template-columns: repeat(2, 1fr) !important;

        .web-builder-content-our-service_card {
          padding: 0;
        }
      }
    }

    /** ****************************************** about us ******************************************/
    .web-builder-content-about-us {
      display: flex;
      flex-direction: column-reverse;

      .about-us-details {
        text-align: center !important;
      }

      .web-builder-filled-btn {
        margin: 0 auto;
      }
    }
    &.theme-3 .web-builder-content-about-us {
      display: flex;
      flex-direction: column;

      .about-us-details {
        text-align: center !important;
      }

      .web-builder-filled-btn {
        margin: 0 auto;
      }
    }

    /** ****************************************** our packages ******************************************/
    .web-builder-content-our-packages-with-slider {
      .our-packages-header {
        text-align: center;
        justify-content: center;

        .btn {
          display: none;
        }
      }

      .btn-mobile {
        // display: block !important;
        text-align: center;
        margin: 24px auto;
        font-size: 1rem;
        font-weight: 500 !important;
        border-radius: 4px !important;
      }
    }

    .all-web-builder-our-service-images {
      .our-service-item {
        flex-basis: 100% !important;
      }
    }

    /** ****************************************** hotels ******************************************/
    .web-builder-content-hotels {
      background-color: #f5f8fc;

      .hotels-grid-view {
        padding: 0;
        grid-template-columns: repeat(auto-fit, 100%) !important;
      }
    }

    /** ****************************************** FAQ ******************************************/
    .faq-detials {
      grid-template-columns: repeat(auto-fill, 100%) !important;
    }

    .faq-with-image {
      .service-header {
        text-align: center;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(var(--web-page-primary-color), 0.08);
        margin-bottom: 1rem;
        font-size: 24px;
      }

      .faq-questions-container {
        order: 1;
      }

      .faq-image {
        height: auto !important;
      }
    }

    .faq-column-view {
      .services-content {
        text-align: center;
      }

      .faq-questions-container {
        grid-template-columns: repeat(auto-fill, 100%) !important;
        gap: 6px !important;
      }
    }

    /** ****************************************** Contact us ******************************************/
    .web-builder-content-contact-us-with-image {
      .container {
        padding: 0;
      }

      .contact-us-layout {
        padding: 32px 12px;
        flex-direction: column-reverse;
        gap: 36px;
      }

      .contact-us-data,
      .contact-us-image {
        width: 100% !important;
      }
    }

    .web-builder-content-contact-us {
      padding: 0;
      margin-top: 1rem;

      .contact-us-layout {
        padding: 32px 12px;
        border-radius: 0;
      }
    }

    /** ****************************************** Footer ******************************************/
    .footer-copyrights {
      text-align: center;
    }

    /****************** generics *************************/
    .service-top-section {
      // padding: 24px 0;
      .services-content {
        max-width: 100%;
      }
    }

    .web-builder-content-our-packages-with-slider,
    .web-builder-content-hotels,
    .web-builder-content-tours {
      padding: 24px;
      margin-top: 1rem;
    }

    /** ****************************************** result page ******************************************/
    .search-bread-crumb {
      padding: 16px;
    }

    .all-search-result {
      position: relative;
      padding: 24px 16px;

      .search-filter {
        position: fixed;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100vh;
        padding: 12px;
        background-color: #fff;
        z-index: 9;
        transform: translateY(100%);
        transition: transform 0.35s ease-in;

        .reset {
          margin: 1rem 0;
        }

        &.opened {
          transform: translateY(0);
        }

        .close-filter-btn {
          display: block;

          svg {
            width: 15px;
            height: 15px;

            path {
              stroke: rgba(var(--web-page-primary-color));
            }
          }
        }
      }

      .header {
        flex-wrap: wrap;
        gap: 10px;
      }

      .result-item, .flight-service-card{
        width: 100% !important;
      }

      .filter-mobile-btn {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        bottom: 16px;
        inset-inline-start: 20px;
        background-color: rgba(var(--web-page-primary-color));
        z-index: 9;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .flights-cards-container {
    .content {
      flex-wrap: wrap;
      gap: 1rem;
      .addition-destinations-list {
        width: fit-content;
        left: unset !important;
        right: 0;
        [dir="rtl"] & {
          left: 0 !important;
          right: unset;
        }
      }
      .price {
        border: none;
      }
      .destinations-list,
      .d-flex {
        flex-grow: 1;
        justify-content: space-between;
      }
    }
  }
  
  .has-api-integration {
    .hero-content-container {
      .tabs-item span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .web-builder-preview {
    .web-builder-hero-header {
      z-index: 9;

      header {
        justify-content: initial;
        align-items: center;
        padding: 8px;
      }

      .d-flex-language-contact {
        margin-left: auto;
        margin-right: unset;

        [dir="rtl"] & {
          margin-right: auto;
          margin-left: unset;
        }
      }

      .nav-container {
        gap: 0;
        margin-inline-start: auto;

        [dir="rtl"] .nav-container {
          margin-right: auto;
        }
      }

      .header-nav {
        position: fixed;
        justify-content: center;
        align-items: self-start;
        width: 100%;
        height: 100vh;
        padding: 1rem;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.64),
            rgba(0, 0, 0, 0.64)
          ),
          linear-gradient(
            0deg,
            rgba(var(--web-page-primary-color)),
            rgba(var(--web-page-primary-color))
          );
        z-index: 100;
        transition: transform 0.35s ease;

        ul {
          flex-direction: column;
          padding-top: 4rem;
          gap: 32px;

          li,
          .service-dropdown {
            color: #fff !important;
          }
        }

        &.nav-opened {
          transform: translateY(0);
          transition: transform 0.35s ease;
        }
      }

      .nav-mobile-icon,
      .close-nav {
        border: 0;
        background-color: transparent;
        display: block !important;
      }

      .close-nav {
        position: absolute;
        top: 16px;
        left: 16px;
      }

      .contact-button {
        padding: 3px 18px;
      }

      .call-us {
        display: none !important;
      }
    }

    .flights-cards-container {
      grid-template-columns: 1fr !important;

      .content {
      }
    }
  }
}

// Transfer resonsive
@media screen and (max-width: 760px) {
  .transfer-list {
    .sort-filter {
      button {
        background: #e2dcdc !important;
        // width: 40%;
        border-radius: 5px;
      }
    }

    .builder-transfer-item {
      padding: 5px !important;
    }

    .transfer-img {
      width: 100% !important;
      height: 90% !important;
    }

    .builder-transfer-view-detail {
      align-items: start !important;

      .transfer-wallet {
        inset-inline-end: 0 !important;
        bottom: 0 !important;

        div {
          text-align: center;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 4px;
        }
      }
    }
  }

  .transfer-cart-model {
    .transfer-img {
      width: 100% !important;
      height: 90% !important;
    }
  }

  .transfer-reservations {
    margin: 0 !important;
    padding: 4px 7px !important;

    .transfer-passenger-details {
      label {
        font-size: 14px !important;
      }
    }
  }
}

// Tour resonsive
@media screen and (max-width: 760px) {
  .tours-list {
    .filter-mobile-btn {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      bottom: 16px;
      inset-inline-start: 20px;
      background-color: rgba(var(--web-page-primary-color));
    }

    .tour-filter {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 12px;
      background-color: #fff;
      z-index: 9;
      transform: translateX(-100%);
      transition: transform 0.35s ease-in;
      overflow-y: auto;

      .reset {
        margin: 1rem 0;
      }

      &.opened {
        transform: translateX(0);
        transition: transform 0.35s ease-in;
      }

      .close-filter-btn {
        display: block;

        svg {
          width: 15px;
          height: 15px;

          path {
            stroke: rgba(var(--web-page-primary-color));
          }
        }
      }
    }

    .filter-mobile-btn {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      bottom: 95px;
      inset-inline-start: 20px;
      background-color: rgba(var(--web-page-primary-color));
    }

    .tour-card {
      padding: 6px;

      .tour-card-img {
        width: 100% !important;
      }
    }
  }

  .tours-cart-container {
    .tour-card-img {
      width: 100% !important;
    }
  }

  .tour-details-summary {
    width: 100% !important;
    margin-inline: 0 !important;
  }

  .tours-summary-passngers-type {
    flex-wrap: wrap !important;
    gap: 7px !important;

    div {
      margin-inline: 0 !important;

      label {
        font-size: 14px;
      }
    }
  }
}

// @media screen and (max-width: 992px) {
//   .all-web-builder-our-service-images {
//       grid-template-columns: repeat(1, 1fr);
//       grid-gap: 16px !important;
//       gap: 16px !important;
//   }
// }