.notifaction-all {
	height: 100vh;
	.notifaction-container {
		width: 60%;
		h4 {
			color: #656565;
			background: #e4e4e4;
		}
	}
	.bg-notify {
		background-color: #f4f8ff !important;
	}
	.read {
		background-color: #f2f2f2;
	}
	.not-read {
		background-color: rgba(23, 119, 246, 0.1);
	}
	.notify-content {
		color: #414040;
	}
	.noty {
		.read-noty {
			border: 1px solid #e4e4e4ba;
		}
		i {
			font-size: 18px;
		}
	}
}
