.theme-7 {
  .web-builder-content-other {
    .services-content {
      max-width: 50% !important;
      text-align: center !important;
      margin-inline: auto !important;
    }

    .otherservice-cards-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      
      > a {
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .home-page-other-card {
      max-width: 350px;
      height: 100%;

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 100%;
      }

      filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.05));
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: .8rem;
      border-radius: 14px;


      &__image {
        img {
          border-radius: 8px;
          width: 100%;
          aspect-ratio: 4/3;
          object-fit: cover;
          max-height: 250px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-bottom: 0;
        height: 100%;
      }

      &__divider {
        width: 100%;
        background-color: rgba(226, 232, 240, 1);
        margin-block: 1rem;
      }

      &__title {
        font-size: 1.125rem;
        color: black;
        font-weight: 700;
      }

      &__description {
        font-size: 1rem;
        color: rgba(112, 113, 112, 1);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__footer {
        margin-top: auto;
        display: flex;
      }

      &__cta {
        margin-top: 0.5rem;
        margin-inline-start: auto;
        button {
          padding: 0.7rem 1.2rem;
          font-weight: bold;
          height: 100%;
          background-color: rgba(0, 183, 172, 1) !important;
          color: white !important;
          font-size: 1rem;
          border: none;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          display: grid;
          place-content: center;
          pointer-events: all;
        }
      }
    }

    .home-page-other-card-link{
      pointer-events: none;
    }

    @media screen and (max-width: 767px) {
      .otherservice-cards-container {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: calc(100vw - 16px);
        margin-inline-start: -25px;
        padding-inline: 24px;

        .home-page-other-card-link {
          flex-grow: 1;
          flex-shrink: 0;
          max-width: 300px;
          height: 100%;
          pointer-events: all;
        }

        .home-page-other-card {
          &__cta {
            display: none;
          }
        }
      }
    }
  }
}
