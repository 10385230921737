.all-room-inventory {
  padding: 0.5rem;
  background: #f2f2f2;
  h6 {
    color: #656565;
  }
  .room-inv {
    display: flex;
    justify-content: space-around;
    background: #fff;
    padding: 0.5rem;
    border: 1px solid #d6d6d6;
    border-radius: 3px;
    margin-bottom: 0.5rem;
    div {
      > h6 {
        margin-bottom: 0.3rem;
        font-weight: 700;
        color: #000;
      }
      a {
        > p {
          text-decoration: underline;
          color: #0070af;
        }
      }
    }
  }
}
// .btn-link {
// 	border: none;
// 	background: none;
// }
.hotel-details {
  .hotel-info {
    margin: 0 6px;
    .main-tabs {
      padding: 0;
    }

    .hotel-info-tab {
      .nav-link {
        padding: 7px 25px;
        background: #acacac !important;
        color: #fff;
        margin-right: 15px;
        border: 1px solid rgb(231, 231, 231);
        border-bottom: none;
      }
      .nav-link.active {
        background: white !important;
        color: #324356 !important;
        min-height: auto;
        &::after {
          display: none;
        }
      }
    }
  }

  .hotel-info-content {
    padding: 10px;
    background: #fff;
    border: 1px solid rgb(231, 231, 231);
    border-top: none;
    border-radius: 0 6px 6px 6px;
    margin-bottom: 10px;
    .hotel-info-en {
      h6 {
        font-size: 13px;
        color: #324356;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        color: #324356;
      }
    }
    .hotel-info-ar {
      h6 {
        font-size: 13px;
        color: #324356;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        color: #324356;
      }
    }
  }

  .hotel-info-addition {
    // div[class^="col-"] {
    // 	padding: 0 !important;
    // }
    h6 {
      font-size: 13px;
      color: #324356;
      font-weight: 500;
    }
    p {
      font-size: 12px;
      color: #324356;
    }
  }
  .rooms {
    margin: 0 6px;
    .rooms-content {
      h6 {
        font-size: 12px;
        color: #324356;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        color: #324356;
      }
    }
    div[class^="col-"] {
      height: 42px;
    }
  }

  .meals {
    margin: 0 6px;
    .meals-content {
      h6 {
        font-size: 12px;
        color: #324356;
        font-weight: 500;
      }
      p {
        font-size: 12px;
        color: #324356;
      }
    }
    div[class^="col-"] {
      height: 42px;
    }
  }
  .photos {
    margin: 0 6px;
    .photos-content {
      .photo-box {
        height: 200px;
        width: 200px;
      }
    }
  }
  .maps {
    margin: 0 6px;
    .maps-content {
      .map-box {
        height: 200px;
        width: 400px;
      }
      .map-info {
        margin: 0 15px;
        h6 {
          font-size: 14px;
          color: #324356;
          font-weight: 500;
          span {
            font-size: 15px;
            font-weight: 100;
          }
        }
        button {
          color: #0184fe;
          border: 1px solid #0184fe !important;
          padding: 7px 50px;
          margin: 7px 0;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  /********* Start Progressbar***************/
  .progressbar-info {
    .item-name-active {
      color: #28a745 !important;
    }
    .item-name-inactive {
      color: #979797 !important;
    }
    .bg-yellow-progressbar {
      background-color: #f7f1e9 !important;
      i {
        color: #ce7c0d;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 21px !important;
        margin-top: 9px !important;
      }
    }
    .active-status-bg {
      background-color: #c3e6cb !important;
      span {
        color: #28a745 !important;
      }
    }
    .box-container {
      box-shadow: none !important;
    }
    .bar-header {
      background-color: $gray-200;
    }
    .bar-title {
      color: $dark-blue;
    }
    .step {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid $gray-200;
      z-index: 1;
      background-color: $gray-200;
    }
    .progressbarItem {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
    }
    .progressbar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon-step {
      margin: 11px;
      color: #c1c3c5;
    }
    .active-status {
      border: 2px solid #00b437;
      background-color: white;
    }

    .progressbarItem-name {
      font-size: 10px;
    }
    .active-item:after {
      content: "";
      font-weight: 800;
      color: #00b437 !important;
      top: 6px;
    }
    .step-holder {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-names {
      font-size: 16px;
      font-weight: 600;
      margin: 0 -21px;
    }

    .step-holder:after {
      content: "- - - - - - - - - - - - - - - - - - - - - -  - - - - - - - ";
      font-weight: 800;
      color: $gray-200;
      top: 6px;
    }
    .remove-after:after {
      content: "";
    }
    .adjust-step-holder {
      justify-content: flex-start !important;
    }
    .second-title {
      color: #c8c7c7;
    }
    .bg-card {
      background-color: #686d71;
      color: white;
    }
    .bg-card-info {
      background-color: #f4f4f4;
      color: #324356;
    }
    .bg-card-error {
      background-color: #ffdedd;
      color: #ff5f59;
    }
    .bg-card-btn {
      background-color: #ff5f59;
      color: white;
      border: none;
      font-size: 12px;
    }
    .btn-pay {
      line-height: 2px;
      width: auto !important;
      text-align: start;
      color: #00b437 !important;
      text-decoration: underline !important;
      font-size: 14px;
      font-weight: 600;
      box-shadow: none !important;
      border: none !important;
    }
    .btn-pay:active {
      color: rgb(89, 18, 202) !important;
    }
    .progressbarItem:first-of-type {
      .item-names {
        font-size: 16px;
        font-weight: 600;
        margin: 0 -35px;
      }
    }
    .progressbarItem:last-of-type {
      .item-names {
        font-size: 16px;
        font-weight: 600;
        margin: 0 -45px;
      }
    }
  }
  /********************************************************************************/

  /********* End Progressbar***************/
}
.facilities {
  margin: 0 6px;
}
.photos {
  //margin: 0 6px;
  .photo-box {
    width: 400px !important;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
//Pay Model
.payModal {
  .wallet {
    margin: 0 9px !important	;
    .wallet-box {
      padding: 5px 0;
      background-color: #deebf3 !important;
    }
  }
  .details {
    .details-box-header {
      p {
        font-size: 14px;
      }
    }
    .details-box {
      p {
        font-size: 13px;
      }
    }
    .notes {
      padding: 0 8px;
      background-color: #fff3e6;
      h6 {
        color: #686d71;
        font-size: 16px;
      }
      p {
        color: #686d71;
        font-size: 14px;
      }
      i {
        font-size: 35px;
        color: #ce6700;
        margin: 0 12px;
      }
    }
  }
  .amount {
    margin: 12px 0;
    h5 {
      font-size: 21px;
      font-weight: 600;
      color: #3dc666;
    }
    h6 {
      color: #39495b;
      font-size: 23px;
      font-weight: 700;
    }
  }
  .check-box-content {
    input[type="radio"],
    input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
      width: 20px;
      height: 20px;
    }
    .form-check-input {
      position: absolute;
      margin-top: 0.1rem;
      margin-left: -1.55rem;
    }
  }
}
.product-build__product-no-data1 {
  background-color: red;
}
.font-gray-200 {
  color: #eae8e9;
}
.font-gray-300 {
  color: #acacac;
}
.font-dark-gray {
  color: #575757;
}
.bg-gray-400 {
  background-color: #858585 !important;

  .btn-link {
    border: none;
    background: none;
  }
}
.font-size-18 {
  font-size: 18px;
}
.font-size-14 {
  font-size: 14px;
}
.text-underline {
  text-decoration: underline !important;
}
.no-data {
  width: 400%;
  h4 {
    margin: auto;
    text-align: center;
    color: #c6cbd0;
    margin: 20px 0;
  }
}
.hotel-details .progressbar-info .item-name {
  font-size: 14px;
  font-weight: 600;
  //margin: 0 -26px !important;
  margin: 0px -37px !important;
}
/*****************************Edit Request******************************/
.form-group {
  // margin-bottom: 0px !important;
  position: relative;

  .form-control-custom-input {
    height: 60px !important;
    padding: 15px 10px 0px 40px;
    border-radius: 0px;
    border: 0px;
    font-weight: bold;
    font-size: $text-caption;
    cursor: pointer;
  }

  .booking-label {
    position: absolute;
    top: 10px;
    // #{$left}: 45px;
    left: 45px;
    font-size: 11px;
    color: #acacac;
    [dir="rtl"] & {
      left: auto;
      right: 45px;
    }
  }

  .fa-calendar {
    @extend %fa-icons-style;
  }

  .fa-suitcase {
    @extend %fa-icons-style;
  }

  .fa-id-card {
    @extend %fa-icons-style;
  }

  i {
    @extend %fa-icons-style;
  }
}
.product-build__product-collpase .button-collapse {
  height: auto !important;
}
.global-input {
  height: auto !important;
  padding: 3px 0px 5px 8px ;
  color: #354f5c;
  border-radius: 0px;
  border: 0px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #dee2e6;
}

.note-edit-request {
  color: #324356;
  font-size: 14px;
  line-height: 0;
}
.box-edit-request {
  p {
    color: #324356;
    font-size: 15px;
  }
}
.box-edit-request + small {
  color: #324356;
}
.box-container {
  margin-bottom: 15px;
  background-color: #ffffff;
  padding: 12px 12px !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.11);
}
.facilities-content {
  ul {
    //column-count: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 250px;
    flex-wrap: wrap;
    li {
      //width: 25%;
      background-color: #e4e4e4;
      border: 1px grey;
      padding: 5px;
      border-radius: 5px;
      margin: 2px 0;
    }
  }
}
.span-text {
  visibility: hidden !important;
}
.text-ltr {
  direction: ltr !important;
}
.visa-requests {
  .header-visa {
    h3 {
      color: #2d2d2d;
      font-weight: 800;
    }
    span {
      color: #464646;
      font-size: 13px;
      cursor: default !important;
    }
    .btn.btn-light:hover {
      background-color: #f8f9fa;
    }
  }
  .control-field__body .date-icon {
    top: 0 !important;
  }
  .table {
    .badge {
      color: #fff;
      font-size: 12px;
      padding: 5px 8px;
    }
    .badge-orange {
      background-color: #ce7c0d !important;
    }
    .badge-issued {
      background-color: #28a745;
    }
    .badge-waiting {
      background-color: #bb6020;
    }
    .badge-pending {
      background-color: #daa249;
    }
  }
}
.inventory-filter {
  .control-field__body .date-icon {
    top: 0 !important;
  }
}
.voucher {
  background-color: red;
  .container-wrapper {
    border: 4px solid #0c3b5c;
  }

  .voucher-header {
    gap: 85px;

    .header-img {
      border: 1px solid #ebebeb;
      width: 310px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-name {
      h4 {
        color: #0c3b5c;
        font-size: 18px;
        font-weight: 700;
        margin: 7px 0;
      }
    }
    .header-data {
      width: 60%;
      div {
        margin: 3px 0;
      }
      h5 {
        color: #2d2d2d;
        font-size: 20px;
      }
      p {
        color: #2d2d2d;
        font-weight: 800;
        font-size: 17px;
      }
    }
  }
  hr {
    border-top: 1px solid #0c3b5c !important;
  }
  .services {
    // margin: 8px 0;
    h3 {
      color: #0c3b5c;
      font-size: 18px;
      font-weight: 700;
    }
    .service-data {
      div {
        margin: 3px 0;
      }
      h5 {
        color: #2d2d2d;
        font-size: 20px;
      }
      p {
        color: #2d2d2d;
        font-weight: 800;
        font-size: 17px;
      }
    }
  }
  .passenger {
    // margin: 8px 0;
    h3 {
      color: #0c3b5c;
      font-size: 18px;
      font-weight: 700;
    }
    .passenger-data {
      div {
        margin: 3px 0;
      }
      h5 {
        color: #2d2d2d;
        font-size: 20px;
      }
      p {
        color: #2d2d2d;
        font-weight: 800;
        font-size: 18px;
      }
      .passenger-info {
        background: #f7f7f7;
        border: 4px solid #f4f4f4;
        padding: 25px;
        h6 {
          color: #2d2d2d;
        }
        p {
          color: #0c3b5c;
        }
      }
    }
  }
}
.price-commission {
  .price-commission-text {
    width: 88%;
    border-radius: 5px;
    border: 1px solid rgb(118, 118, 118);
  }
  .price-total {
    color: #3b4b5d;
    font-size: 19px;
    font-weight: 600;

    span {
      color: #00b437;
      font-weight: 700 !important;
      bold {
        font-weight: 800;
      }
    }
  }
}
.marketplace-details {
  .header-hotel-info {
    position: absolute;
    top: -10px;
    background-color: #fff;
    margin: 0px 30px;
    font-size: 19px;
    padding: 0 10px;
    color: #ff6868;
    font-weight: 700;
    text-transform: uppercase;
  }
  .hotel-name {
    //border-bottom: 1px solid #cfcfcf;
    .btn-white-border {
      border: 1px solid #cb9a51;
      a {
        color: #cb9a51;
        text-decoration: none !important;
        font-size: 19px;
        padding: 5px 35px;
      }
    }
  }
  .hotel-info {
    border: 1px solid #cfcfcf;
    position: relative;

    .btns-booking {
      .btn-yellow {
        font-size: 16px;
      }
      .btn-link {
        color: #ce7c0d !important;
        text-decoration: underline !important;
        font-size: 16px;
      }
    }
    .booking-data-container {
      .row-inner {
        border-right: 1px solid #dfdfdf;
        [dir="rtl"] & {
          border-left: 1px solid #dfdfdf;
        }
      }
    }
    .booking-data {
      .col-4 {
        background-color: #ebebeb;
        padding: 10px 17px 10px 7px;
        h5 {
          color: #0c3b5c;
          font-size: 14px;
          font-weight: bold;
          // padding: 3px 0px;
          line-height: 1.5 !important;
        }
      }
      .col-8 {
        //background-color: #ebebeb !important;
        border-bottom: 1px solid #dfdfdf;
        padding: 3px 8px;
        display: flex;
        align-items: center;
        p {
          color: #2d2d2d;
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
          font-family: "Cairo";
        }
      }
      .row-inner:first-child {
        .col-8 {
          border-top: 1px solid #dfdfdf !important;
        }
      }
      .bg-light-warning {
        background-color: #bd652224 !important;
      }
      .bg-light-danger {
        background-color: #dc354542 !important;
      }
      .bg-light-success {
        background-color: #00b43660 !important;
      }
      .text-light-warning {
        color: #bd6522 !important;
      }
    }
  }
  .passenger {
    border: 1px solid #cfcfcf;
    position: relative;
    .invetory-table {
      .tools {
        a {
          padding: 1px;
          border-radius: 2px;
        }
      }
      .control-field__body .date-icon {
        top: 0 !important;
      }
    }
  }
  .hr {
    border-top: 1px solid #cfcfcf;
  }
  .bg-yellow {
    background-color: #cb9a51;
  }
  .room-details {
    .room-total-reservation {
      .total {
        color: #757575;
        font-size: 13px;
        line-height: 0.5;
      }
      .price {
        color: #00b545;
        font-size: 18px;
        font-weight: 700;
      }
    }
    thead {
      border-top: 2px solid #fff !important;
    }
    .light-danger {
      background-color: #ee25482e !important;
    }
  }
  .terms-details {
    p {
      color: #2d2d2d;
      font-size: 15px;
      line-height: 1.1;
      padding: 15px 35px;
    }
  }
}
.text-decor-none {
  text-decoration: none;
}
.btn-white-border-gray {
  border: 1px solid #b1b1b1;
  color: #b1b1b1;
  margin-top: 5px;
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 600;
}
.btn-white-border-gray:hover {
  color: #b1b1b1;
}
.btn-pay-disabled:disabled {
  background: #cb9a51 !important;
  color: #fff !important;
}
.btn-pay-prim-disabled:disabled {
  background: #0069d9 !important;
  color: #fff !important;
}

.modal-cancel-reservation {
  h5,
  button {
    font-family: sans-serif !important;
  }
  h5 {
    font-weight: 500 !important;
    font-size: 17px;
  }
}
.modal.fade.show {
  background-color: #ffffff96 !important;
}
.modal-edit-reservation {
  h5,
  button {
    font-family: sans-serif !important;
  }
  h5 {
    font-weight: 500 !important;
    font-size: 17px;
  }
  .special-request-containt {
    column-count: 2;
  }
}

.text-issued {
  color: #28a745;
}
.text-waiting {
  color: #bb6020;
}
.text-pending {
  color: #daa249 !important;
}

.transfer-actions {
  position: relative;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  text-decoration: underline !important;
}

.transfer-actions img {
  width: 16px;
}

.transfer-actions:hover {
  text-decoration: underline !important;
}

.transfer-actions.pay-now,
.transfer-actions.pay-now:hover {
  color: #cb9a51;
}

.transfer-actions.cancel-reservation,
.transfer-actions.cancel-reservation:hover {
  color: #ff6868;
}

[dir="rtl"] .transfer-arrow img {
  transform: rotate(180deg);
}
.transfer-booking-container {
  position: relative;
}
.transfer-booking-container .nav {
  width: 100%;
}
.transfer-booking-container .nav .nav-item {
  width: fit-content !important;
}
.transfer-booking-container .nav-link {
  color: #cb9a51 !important;
}

// transfer booking details
.transfer-booking-details-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  border-left: 3px solid #cb9a51;
}
[dir="rtl"] .transfer-booking-details-header {
  border-left: 0;
  border-right: 3px solid #cb9a51;
}
.transfer-booking-details-header h2 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0c3b5c;
  padding-left: 1rem;
  text-transform: uppercase;
}
[dir="rtl"] .transfer-booking-details-header h2 {
  padding-left: 0;
  padding-right: 1rem;
}

.transfer-booking-details-header span {
  padding: 5px 7px;
  border-radius: 5px;
  color: #cb9a51;
  border: 1px solid #cb9a51;
  // font-size: .9rem;
  font-weight: normal;
}

.transfer-booking-item {
  position: relative;
  margin: 1rem 0;
  padding: 0.8rem;
  padding-bottom: 0;
  border: 1px solid #ebebeb;
}
.transfer-booking-item i {
  font-size: 13px;
  color: #cb9a51;
}
.transfer-booking-item-info {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 2px 0;
  i {
    color: #cb9a51;
  }
}
.transfer-delete-icon {
  position: absolute;
  top: 8px;
  inset-inline-end: 8px;
}
.transfer-booking-item-warning {
  font-size: 14px;
  color: #e39633;
}
.transfer-booking-item-blue-dark {
  font-size: 14px;
  color: #202020;
  line-height: 1.2;
}
.transfer-booking-item-cancelation {
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ebebeb;
  font-size: 14px !important;
}

.transfer-booking-item-cancelation::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #ebebeb;
}

.transfer-booking-item-cancelation [class*="col-"]:first-child {
  padding-right: 15px;
  padding-left: 0;
}

.transfer-booking-item-cancelation [class*="col-"] {
  padding-left: 15px;
}

[dir="rtl"] .transfer-booking-item-cancelation [class*="col-"]:first-child {
  padding-left: 15px;
  padding-right: 0;
}
[dir="rtl"] .transfer-booking-item-cancelation [class*="col-"] {
  padding-left: 0;
  padding-right: 15px;
}

.transfer-booking-summary-item .text-muted {
  font-size: 12px;
  line-height: 18px;
  color: #2d2d2d !important;
}
.transfer-booking-totalprice {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transfer-booking-totalprice .btn {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.9rem;
  background-color: #027b30;
  color: #fff;
  border-radius: 5px;
}
.package-booking-totalprice .btn {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 40px;
  text-transform: uppercase;
  font-size: 0.9rem;
  background-color: #027b30;
  color: #fff;
  border-radius: 5px;
}
.tour-booking-item-action {
  border-top: 1px solid #ebebeb;
}
.tour-booking-item-action span,
.tour-booking-action span {
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}
.text-light-warning {
  color: #d29d4d;
}
.text-light-danger {
  color: #c30101 !important;
}
.border-1-gray {
  border: 1px solid #ebebeb;
}
.text-14 {
  font-size: 14px;
}
.text-15 {
  font-size: 15px;
}
.border-top-gray {
  border-top: 1px solid #ebebeb;
}

.flight-inventory-table {
  th {
    border-top: none !important;
  }
}

.packages-travelers {
  .table {
    border: 1px solid #dbdade;
    thead {
      border: 1px solid #dbdade;
      background-color: #f7f6f6;
      font-size: 14px;
      font-weight: 700;
      color: #707170;
      padding: 12px 0;
      text-transform: uppercase;
    }
    tbody {
      background-color: #fff;
      td {
        color: #050001;
        font-size: 16px;
        font-weight: 400;
        vertical-align: middle;
      }
    }
    .text-blue {
      color: #2190f5;
    }
    .fw-600 {
      font-weight: 600;
    }
    .edit-btn svg path {
      stroke: #707170;
    }
  }
}
.package-traveler-modal {
  border-radius: 12px;
  border: 1px solid #dddcdc;
  background-color: #fff;

  .control-field__label:not(.control-field__label--danger) {
    font-size: 14px;
    font-weight: 400;
    color: #707170;
  }
  .modal-title {
    width: 100%;
    padding: 10px 0px;
    color: #050001;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  [class*="col-"] {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 992px) {
  .transfer-booking-container .bg-gray {
    flex-wrap: wrap;
  }
  .transfer-booking-totalprice {
    flex-direction: column;
    align-items: flex-start;
  }
  .tansfer-details-actions {
    margin: 0.4rem 0;
  }
}

@media screen and (max-width: 767px) {
  .transfer-booking-item-img {
    width: 50%;
    margin-bottom: 10px;
  }
  .transfer-booking-item-cancelation [class*="col-"] {
    margin: 5px 0;
    padding: 0;
  }
  .transfer-booking-item-cancelation::after {
    display: none;
  }
}

@media print {
  html,
  .bg-gray-100 {
    background-color: #fff !important;
  }
  .voucher {
    height: 100vh !important;
    background-color: red;
  }
  .container-wrapper {
    height: 97vh !important;
  }
  .voucher h3 {
    background-color: #fff;
  }
  .TechnicalSupport-width {
    display: none !important;
  }
  @page {
    background-color: red;
  }
}
