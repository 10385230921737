.theme-2 {
  .whatsapp-btn {
    z-index: 2 !important;
  }

  .web-builder-hero {
    &.has-api-integration {
      .hero-content-container {
        position: static;
        justify-content: center;
        align-items: flex-start;
      }
      .web-builder-hero-content {
        max-width: 900px;
      }
      .search-form-web-builder {
        background-color: transparent;
      }
    }
  }

  .header-nav {
    &.nav-opened {
      @media screen and (max-width: 991px) {
        padding-bottom: 100px;
      }
    }

    .header-nav__call {
      position: absolute;
      bottom: 4rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 60px;
      height: 60px;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }

  .footer-temp-two {
    .footerTempTwo-links {
      ul {
        text-align: start;
      }
    }
  }

  .wb-our-service {
    .web-builder-content-our-service {
      grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));
    }
    @media screen and (max-width: 400px) {
      .web-builder-content-our-service {
        grid-template-columns: repeat(1, 1fr) !important;
      }
    }
  }

  .flight-service-card {
    .header {
      .destination-count {
        padding: 8px 15px;
        font-size: 14px;
        background-color: #f3f3f3;
        color: rgb(var(--web-page-primary-color));
        border-color: rgb(var(--web-page-primary-color)) !important;
      }
    }

    .cta-btn {
      background-color: rgba(var(--web-page-primary-color));
      color: #fff !important;

      &:hover {
        background-color: #fff !important;
        color: rgba(var(--web-page-primary-color)) !important;
      }
    }

    @media screen and (max-width: 767px) {
      .destinations-list {
        flex-direction: column;
      }

      .destination-port {
        justify-content: space-between;
        width: 100%;
      }

      .price {
        border-inline-end: none !important;

        .amount {
          font-size: 18px !important;
        }
      }

      .cta-btn {
        font-size: 14px !important;
        padding: 6px 12px !important;
      }
    }
  }

  .tours-details,
  .hotels-detials {
    .react-multiple-carousel__arrow {
      bottom: -50px;
    }
  }

  .home-page-transfer-section {
    padding-block: var(--sections-block-padding);
    padding-bottom: calc(var(--sections-block-padding) + 1rem);
    position: relative;
    background-color: rgba(245, 248, 252, 1);

    a:has(> div) {
      user-select: none;

      &:active {
        cursor: grabbing !important;
      }
    }

    &__title {
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 2;
      margin-bottom: 0.4rem;
      text-align: center;
    }

    &__description {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: rgba(112, 113, 112, 1);
      text-align: center;
      max-width: 350px;
      margin-inline: auto;
    }

    .home-page-transfer-card {
      &__image {
        img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          width: 100%;
          aspect-ratio: 3/2;
          object-fit: cover;
        }
      }

      &__content {
        border: 1px solid rgba(30, 133, 255, 0.16);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 1rem;
        background-color: white;
      }

      &__header {
       display: flex;
       justify-content: space-between;
       align-items: center;
       gap: 1rem; 
      }

      &__title {
        font-size: 1.25rem;
        font-weight: 700;
        color: black;
      }

      &__tag {
        padding: 0rem .5rem;
        background-color: rgba(30, 133, 255, 1);
        color: white;
        width: fit-content;
        border-radius: 90px;
        text-transform: capitalize;

        @media screen and (min-width: 767px) {
          display: none;
        }
      }

      &__body {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        p {
          font-weight: 500;
          color: rgba(112, 113, 112, 1);
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        svg {
          --size: 21px;
          width: var(--size);
          height: var(--size);

          path {
            stroke: rgba(112, 113, 112, 1);
          }
        }
      }
    }

    .carousel-button-group {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
      justify-content: space-between;
      gap: 370px;

      button {
        background-color: rgba(30, 133, 255, 1);

        svg {
          path {
            stroke: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  .web-builder-content-other {
    .otherservice-cards-container {
      grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    }

    .home-page-other-card {
      display: flex;
      gap: 1rem;

      &__image {
        flex-shrink: 0;
        max-width: 250px;
        min-width: 300px;
        img {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          width: 100%;
          aspect-ratio: 3/2;
          object-fit: cover;
          height: 100%;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        flex: 2;
        padding-block: 1rem;
        background-color: white;
      }

      &__divider {
        width: 100%;
        background-color: rgba(226, 232, 240, 1);
        margin-block: 1rem;
      }

      &__title {
        font-size: 1.125rem;
        color: black;
        font-weight: 700;
      }

      &__description {
        font-size: 1rem;
        color: rgba(112, 113, 112, 1);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
      }

      &__price-start-from {
        color: rgba(112, 113, 112, 1);
        font-size: 0.875rem;
      }

      &__price-value-container {
        display: flex;
        gap: .3rem;
        align-items: baseline;
      }

      &__price-value {
        font-size: 1.5rem;
        color: black;
        font-weight: 600;
        line-height: 1;
      }

      &__price-currency {
        font-size: 0.875rem;
        color: black;
        font-weight: 400;
      }

      &__cta {
        button {
          padding: 0.5rem 1rem;
          font-weight: bold;
          height: 100%;
          font-size: .8rem;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .otherservice-cards-container {
        grid-template-columns: 1fr;
      }

      .home-page-other-card {
        flex-direction: column;
        gap: 0;

        &__image {
          max-width: 100%;
          min-width: 100%;
          img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 272px;
          }
        }

        &__content {
          padding: 0.5rem;
          border-left: 1px solid rgba(226, 232, 240, 1);
          border-right: 1px solid rgba(226, 232, 240, 1);
          border-bottom: 1px solid rgba(226, 232, 240, 1);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        &__footer {
          flex-direction: column;
          gap: 0.5rem;
        }

        &__price-value-container {
          gap: 0.1rem;
        }

        &__divider {
          display: none;
        }

        &__cta {
          button {
            padding: 0.5rem 1rem;
            font-weight: bold;
            height: 100%;
            font-size: .8rem;
            width: 100%;
          }
        }
      }
      
    }
  }
}

@media screen and (max-width: 770px) {
  .theme-2 {
    .web-builder-page-contianer.about-page {
      .web-builder-hero {
        max-height: 200px !important;
      }
    }
    &.web-builder-page-contianer {
      .web-builder-hero {
        max-height: 200px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  html {
    &:dir(rtl) {
      .theme-2 {
        &.web-builder-preview {
          .web-builder-hero-header {
            .nav-container {
              margin-right: auto;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .theme-2 {
    .header-nav {
      .header-nav__call {
        display: flex;
      }
    }
  }
}
