.theme-1 {
  .whatsapp-btn {
    z-index: 2 !important;
  }

  .header-nav {
    &.nav-opened {
      @media screen and (max-width: 991px) {
        padding-bottom: 100px;
      }
    }

    .header-nav__call {
      position: absolute;
      bottom: 4rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 60px;
      height: 60px;
      display: none;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border-radius: 50%;
    }
  }

  .web-builder-content-transfer {
    align-items: center;

    a:has(> div) {
      user-select: none;

      &:active {
        cursor: grabbing !important;
      }
    }

    .carousel-button-group {
      pointer-events: none;

      button {
        pointer-events: all;
      }
    }

    .transfer-card {
      .card-img {
        height: 100% !important;
        img {
          user-select: none;
        }
      }

      .card-details {
        .tag {
          padding: 0.25rem 1rem;
          background-color: rgba(30, 133, 255, 1);
          color: white;
          width: fit-content;
          border-radius: 90px;
          text-transform: capitalize;
        }
      }
    }
  }

  .flight-section {
    .service-top-section {
      .service-header {
        margin-bottom: 1.8rem;
      }
    }

    .flight-service-carousel {
      .carousel-button-group {
        position: absolute;
        width: 36%;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
        pointer-events: none;
      }
  
      .react-multiple-carousel__arrow {
        position: unset;
        pointer-events: all;
      }
  
      .react-multiple-carousel__arrow--right {
        bottom: unset !important;
        top: unset !important;
        transform: unset !important;
        inset-inline-end: unset !important;
        [dir="rtl"] & {
          inset-inline-end: unset !important;
          inset-inline-start: unset !important;
          transform: scale(-1) !important;
        }
      }
      .react-multiple-carousel__arrow--left {
        bottom: unset !important;
        top: unset !important;
        transform: unset !important;
        inset-inline-start: unset !important;
  
        [dir="rtl"] & {
          inset-inline-start: unset !important;
          inset-inline-end: unset !important;
          transform: scale(-1) !important;
        }
      }
    }
  }
  

  .our-service {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 200px;
    padding: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    .service-type,
    .service-header {
      line-height: 24px;
      color: #fff;
      z-index: 2;
    }

    .service-type {
      font-weight: 500;
    }

    .service-header {
      font-weight: 700;
      font-size: 20px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 41.38%,
        rgba(0, 0, 0, 0.9) 100%
      );
      z-index: 0;
    }
  }
  .tours-details {
    position: relative;

    .react-multiple-carousel__arrow--left {
      left: calc(0% - 30px);
      top: 38%;
      z-index: 2 !important;
    }
    .react-multiple-carousel__arrow--right {
      right: calc(0% - 30px);
      top: 38%;
      z-index: 2 !important;
    }
  }
  .web-builder-our-service-carousel {
    padding-bottom: 4px;
  }

  .tours-details.responsive {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .otherservice-cards-container {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: calc(100vw - 16px);
      margin-inline-start: -25px;
      padding-inline: 24px;

      .otherservice-card {
        flex-grow: 1;
        flex-shrink: 0;

        img {
          border-radius: 7px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .tours-details.services-carousel-container {
      display: none;
    }
  }

  @media screen and (min-width: 767px) {
    .tours-details.responsive {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    .carousel-button-group {
      display: none;
    }
    .web-builder-hero-header header {
      padding: 16px 1em;
    }
  }
}

@media screen and (max-width: 991px) {
  .theme-1 {
    .header-nav {
      .header-nav__call {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .web-builder-content {
    .all-web-builder-our-service-carousel {
      .react-multiple-carousel__arrow--left {
        left: calc(0% - 15px);
      }

      .react-multiple-carousel__arrow--right {
        right: calc(0% - 12px);
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .theme-1 {
    .web-builder-page-contianer.about-page {
      .web-builder-hero {
        max-height: 200px !important;
      }
    }
    &.web-builder-page-contianer {
      .web-builder-hero {
        max-height: 200px;
      }
    }
  }
}
